import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI} from '../../../shared/Constants'
import {DealerContext} from '../../../shared/DealerContext'

export default function RangeFilter(pageNumber, pageSize, order, orderBy, forceReload, searchString, makeId) {

  const { selectedMotorGroup } = useContext(DealerContext);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [ranges, setRanges] = useState([])
  const [total, setTotal] = useState(0)

  const dealerId = selectedMotorGroup?.id;

  useEffect(() => {
    setError(false)
    const params = {
      dealerId,
      pageNumber: pageNumber + 1,
      pageSize,
      isDash: true,
      makeId: makeId || null,
      searchString: searchString?.trim()?.length > 0 ? searchString.trim() : null
    }
    if (order) {params.order = order.toUpperCase()}
    if (orderBy) {params.orderBy = orderBy}
    if(dealerId !== undefined){
      setLoading(true)
      axios({
        method: 'GET',
        url: `${BASE_URI}/dealermodels`,
        params,
      }).then((response) => {
        setRanges(response.data?.list)
        setTotal(response.data?.total)
      }).catch( error => {
        if(axios.isCancel(error)) return
        if (error.response) {
          setError(true)
        }
      }).then(()=>{
        setLoading(false)
    })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, order, orderBy, dealerId, forceReload, searchString, makeId])

  return {loading, error, ranges, total}
}