import React, {useEffect, useState, useRef, useContext} from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Toolbar
} from '@material-ui/core'
import { Formik, Form, Field  } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import { Switch } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {BASE_URI} from '../../shared/Constants'
import axios from 'axios'
import Tooltip from '@material-ui/core/Tooltip';
import {useParams, useHistory} from 'react-router-dom'
import { useSnackbar } from 'notistack';
import {LoadingContext} from '../../shared/context/loadingContext'


const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(3),
  }
}));


export default function SMTPSettingsForm() {
  const classes = useStyles();
  let history = useHistory();
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { id } = useParams();
  const [isAddMode, setIsAddMode] = useState(true)
  const mountedRef = useRef(true)
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    userId: parseInt(id, 10),
    signature: '',
    smtpUsername: '',
    smtpPassword: '',
    smtpTls: false,
    smtpServer: '',
    smtpPort: '',
  }
  const [formFields, setFormFields] = useState(initialValues)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getMailSettings = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/SmtpConfig/${id}`, {
          cancelToken : source.token
        })
        let formData = result.data
        if (result.data) {
          setIsAddMode(false)
          setFormFields(formData)
        }

      } catch (error) {
      }
    }

      getMailSettings().then(() => {
        if (!mountedRef.current) return null
      })

    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const editMailSettings = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.put(`${BASE_URI}/SmtpConfig`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully edited SMTP settings', {variant: 'success'});
      return result
    } catch (error) {
      if (error.response && error.response.data.errors) {
        let errorObj= error.response.data.errors
        enqueueSnackbar(`${errorObj[Object.keys(errorObj)[0]]}`, {variant: 'error'});
      } else {
        enqueueSnackbar(`Unable to edit SMTP Settings`, {variant: 'error'});
      }
    } finally {
      hideLoading()
      setSubmitting(false)
    }
  }

  const createMailSettings = async (fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.post(`${BASE_URI}/SmtpConfig`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully added SMTP settings', {variant: 'success'});
      return result
    } catch (error) {
      if (error.response && error.response.data.errors) {
        let errorObj= error.response.data.errors
        enqueueSnackbar(`${errorObj[Object.keys(errorObj)[0]]}`, {variant: 'error'});
      } else {
        enqueueSnackbar(`Unable to create SMTP Settings`, {variant: 'error'});
      }
    } finally {
      hideLoading()
      setSubmitting(false)
    }
  }

  function onSubmit(fields, { setStatus, setSubmitting }) {
    showLoading()
    setStatus();
    if (isAddMode) {
      createMailSettings(fields, setSubmitting);
    } else {
      editMailSettings(id, fields, setSubmitting);
    }
  }


  return(
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction="column">
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              smtpServer: Yup.string().required('SMTP Server is required'),
              smtpUsername: Yup.string().required('SMTP Username is required'),
              smtpPassword: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  'Password requires at least one lowercase, one uppercase letter and one non-alphanumeric letter'),
              smtpPort: Yup.string().required('SMTP Port is required'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                handleReset
              } = props

              return (
                <Form>
                  <Toolbar disableGutters>
                    <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                      Edit Mail Settings
                    </Typography>
                    <FormControlLabel
                      control={
                        <Field component={Switch}
                               color="primary"
                               type="checkbox"
                               name="smtpTls" />
                      }
                      label="SMTP TLS"
                    />
                  </Toolbar>
                  <Grid spacing={4} container direction="row">
                    <Grid item xs={12} md={6} lg={4} >
                      <TextField
                        fullWidth
                        name="smtpUsername"
                        id="smtpUsername"
                        required
                        label="SMTP Username"
                        value={values.smtpUsername}
                        type="text"
                        helperText={errors.smtpUsername && touched.smtpUsername ? errors.smtpUsername: ''}
                        error={errors.smtpUsername && touched.smtpUsername}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        name="smtpServer"
                        id="smtpServer"
                        required
                        label="SMTP Server"
                        value={values.smtpServer}
                        type="text"
                        helperText={errors.smtpServer && touched.smtpServer ? errors.smtpServer: ''}
                        error={errors.smtpServer && touched.smtpServer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <TextField
                        fullWidth
                        name="smtpPassword"
                        id="smtpPassword"
                        required
                        label="SMTP Password"
                        value={values.smtpPassword}
                        type="password"
                        helperText={errors.smtpPassword && touched.smtpPassword ? errors.smtpPassword: ''}
                        error={errors.smtpPassword && touched.smtpPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <TextField
                        fullWidth
                        name="smtpPort"
                        id="smtpPort"
                        required
                        label="SMTP Port"
                        value={values.smtpPort}
                        type="number"
                        helperText={errors.smtpPort && touched.smtpPort ? errors.smtpPort: ''}
                        error={errors.smtpPort && touched.smtpPort}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="signature"
                        id="signature"
                        multiline
                        minRows={4}
                        label="Signature"
                        value={values.signature}
                        helperText={errors.signature && touched.signature ? errors.signature: ''}
                        error={errors.signature && touched.signature}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item container
                          justify='flex-end' xs={12}>
                      <Box mr={3}>
                        <Button
                          onClick={() => {
                            handleReset()
                            history.push('/admin/users')
                          }}>
                          Cancel
                        </Button>
                      </Box>
                      <Box mr={3}>
                        <Tooltip title="Test SMTP mail send to support@mdhdigital.com" placement="top">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              handleReset()
                              history.push('/admin/users')
                            }}>
                            Test
                          </Button>
                        </Tooltip>
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  )
}