import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiTab: {
      labelIcon: {
        minHeight: 0
      },
    },
  },
});

export const StyledTabs = withStyles({
  root: {
    borderBottom: '1.5px solid darkgrey',
    [theme.breakpoints.down("md")]: {
      background: 'rgba(255,255,255, 0.8)',
      borderRadius: '5px 5px 0 0',
      paddingLeft: '2px',
      paddingRight: '2px'
    }
  },
  indicator: {
    height: 2,
    '& > div': {
      width: '100%',
      backgroundColor: '#1976d2',
    },
  },
})((props) => <Tabs {...props}  TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
    '& $.MuiTab-labelIcon': {
      minHeight: 0
    }
  }
}))((props) =>
  <ThemeProvider theme={theme}>
    <Tab disableRipple {...props} classes={{
      wrapper: 'flex-row-reverse'
    }} icon= {
      <div className={`${props.count ? 'mb-0 ml-2': ''}`}>
        <div style={{backgroundColor: 'white', borderRadius: '3px'}} className="px-1">
          {props.count}
        </div>
      </div>
    }
    />
  </ThemeProvider>);