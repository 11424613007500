import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Divider, CircularProgress, TextField, MenuItem } from '@material-ui/core'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography'
import { CurrencyConverter, CurrencyValue } from '../../../shared/TextMask'
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { useParams, useHistory } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Zoom from '@material-ui/core/Zoom';
import AccessoryForm from './AccessoryForm'
import ConfirmationDialog from '../../../shared/ConfirmationDialog'
import ImageCarousel from '../../../shared/ImageCarousel'
import { BASE_URI } from '../../../shared/Constants'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    gridList: {
        paddingTop: theme.spacing(4)
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

export default function AccessoryListContainer({ navigate, modelId, dealerModelId, dealerVariantId }) {

    const classes = useStyles();

    const [loadingSourceVariants, setLoadingSourceVariants] = useState(true);
    const [sourceVariants, setSourceVariants] = useState([]);
    const [selectedSourceVariant, setSelectedSourceVariant] = useState(null);

    const [listView, setListView] = useState(true)
    const [formView, setFormView] = useState(false)
    const [activeAccessory, setActiveAccessory] = useState(null)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [accessoryList, setAccessoryList] = useState([])
    const [loading, setLoading] = useState(true)
    const mountedRef = useRef(true)
    //const {stepId:variantId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [forceReload, setForceReload] = useState(false);

    const [formType,setFormType]=useState('');

    useEffect(() => {

        axios({
            method: "GET",
            url: `${BASE_URI}/dealerVariants/AllVariantsDetails/${dealerModelId}`,
            cancelToken: axios.CancelToken.source().token
        }).then(response => {
            setLoadingSourceVariants(false);
            setSourceVariants(response?.data?.list?.filter(dv => dv.id != dealerVariantId) || []);

        }).catch(error => {

            enqueueSnackbar("Unable to load the source variants", { variant: 'error' });

        });

    }, []);

    useEffect(() => {
        if (!dealerVariantId) {
            enqueueSnackbar("Please re-select a vehicle model", { variant: 'warning' });
            return history.push(`/DASH/range`)
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)
        const getAccessoryList = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/accessories`, {
                    params: {
                        dealerVariantId: dealerVariantId
                    },
                    cancelToken: source.token
                })
                return result.data.list
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get accessories list for this variant", { variant: 'error' });
            }
        }

        getAccessoryList().then((res) => {
            setAccessoryList(res)
            setLoading(false)
            if (!mountedRef.current) return null
        })
        return () => {
            mountedRef.current = false
            source.cancel();
        };
    }, [dealerVariantId, forceReload])

    const handleFormAction = async (data) => {
        if (!data) {
          return enqueueSnackbar("No Accessory form data", { variant: 'error' });
        }

        setLoading(true);

        try {
          const { id } = data;
          id !== undefined ? await editAccessoryCall(data, id) : await createAccessoryCall(data);

          setFormType(id !== undefined ? 'editAccessory' : 'addAccessory');
          setFormView((prev) => !prev);
          setForceReload((prev) => !prev);
          enqueueSnackbar(`Successfully ${id !== undefined ? 'edited' : 'added'} Accessory Pack`, { variant: 'success' });
        } catch (err) {
          enqueueSnackbar("Unable to perform the action", { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };

    const editAccessory = (accessory) => {
        setFormType('editAccessory')
        setActiveAccessory(accessory)
        setListView((prev) => !prev)
    }

    const formCancel = () => {
        setActiveAccessory(null)
        setFormView((prev) => !prev)
    }

    const addAccessory = () => {
        setFormType('addAccessory')
        setActiveAccessory(null)
        setListView((prev) => !prev)
    }

    const handleDeleteAccessory = () => {
        setOpenConfirm((prev) => !prev)
        if (activeAccessory) {
            setLoading(true)
            deleteAccessoryCall(activeAccessory.id).then((res) => {
                setLoading(false)
                setForceReload(prev => !prev)
                enqueueSnackbar("Successfully deleted Accessory Pack", { variant: 'success' });
            }).catch((err) => {
                setLoading(false)
                enqueueSnackbar("Unable to delete Accessory Pack", { variant: 'error' });
            })
        }
    }

    const createAccessoryCall = (formData) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let data = { ...formData }
        data.dealerVariantId = parseInt(dealerVariantId, 10)
        data.price = CurrencyConverter(formData.price)
        return axios.post(`${BASE_URI}/accessories`, data, {
            cancelToken: source.token
        })
    }

    const editAccessoryCall = (formData, id) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let data = {
            ...formData,
            dealerVariantId: parseInt(dealerVariantId, 10),
            price: CurrencyConverter(formData.price)
        }

        return axios.put(`${BASE_URI}/accessories/${id}`, data, {
            cancelToken: source.token
        })
    }

    const deleteAccessoryCall = (id) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        return axios.delete(`${BASE_URI}/accessories/${id}`, {
            cancelToken: source.token
        })
    }

    const selectSourceVariant = (e, c) => {

        let item = c?.props?.value;

        if (!item) return;

        setSelectedSourceVariant(item);

    };

    const hasSourceVariantAccessories = () => {

        if (!selectedSourceVariant?.id) return false;

        return selectedSourceVariant.accessories?.length > 0;

    };

    const importVariantAccessories = () => {

        let bodyData = {
            sourceVariantId: selectedSourceVariant.id,
            targetVariantId: parseInt(dealerVariantId)
        };

        setLoading(true);
        setLoadingSourceVariants(true);

        axios({
            method: "POST",
            url: `${BASE_URI}/accessories/CopyVariantAccessories`,
            data: bodyData,
            cancelToken: axios.CancelToken.source().token
        }).then(response => {

            if (response?.data?.addedAccessories?.length > 0) {

                let newAccessoryList = response.data.addedAccessories;

                if (accessoryList?.length > 0) {
                    newAccessoryList.push(...accessoryList);
                }

                setAccessoryList(newAccessoryList);

            }

            if (response?.data?.skippedAccessories?.length > 0) {
                enqueueSnackbar(`Skipped to import ${response.data.skippedAccessories.length} accessories. Some either contain invalid fields or would result in duplicated accessories.`, { variant: 'warning' });
            } else {
                enqueueSnackbar(`Successfully imported all the accessories`, { variant: 'success' });
            }

        }).catch(error => {

            enqueueSnackbar("Unable to import the variant accessories", { variant: 'error' });

        }).finally(() => {

            setLoading(false);
            setLoadingSourceVariants(false);
            setSelectedSourceVariant(null);

        });

    };

    return (
        <React.Fragment>

            <ConfirmationDialog secondaryInfo={activeAccessory ? activeAccessory.title : ''}
                action='delete'
                open={openConfirm}
                confirm={handleDeleteAccessory}
                closeDialog={() => setOpenConfirm(false)} />

            <Zoom in={listView} mountOnEnter unmountOnExit
                onExited={() => setFormView((prev) => !prev)}>
                <div>
                    <Toolbar disableGutters>
                        <Typography style={{ flex: '1 1' }} variant="h4">
                            Accessory Packs
                        </Typography>
                        <Button onClick={addAccessory}
                            variant="contained"
                            startIcon={<AddIcon />}
                            color="primary">
                            Accessory Package
                        </Button>
                    </Toolbar>

                    <Grid container direction="column">
                        <Grid item xs={12}>

                            <Typography variant="h5">
                                Import Accessory Packs
                            </Typography>

                            {
                                loadingSourceVariants === true &&
                                <Box mt={3}>
                                    <Grid container justify='center'>
                                        <CircularProgress />
                                    </Grid>
                                </Box>
                            }
                            {
                                !loadingSourceVariants &&
                                <>
                                    <Box mt={3}>
                                        <Typography variant="body">
                                            {
                                                sourceVariants?.length > 0
                                                    ? `Import the accessories from another variant`
                                                    : "There are no accessories available to import from existing dealer variants"
                                            }
                                        </Typography>
                                    </Box>

                                    <Box mt={3}>
                                        {
                                            sourceVariants?.length > 0 &&
                                            <Grid container spacing={4} justify="space-between">

                                                <Grid item xs={12}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        id="selectedSourceVariant"
                                                        name="selectedSourceVariant"
                                                        variant="outlined"
                                                        label="Source Variant"
                                                        style={{ margin: "0" }}
                                                        value={selectedSourceVariant}
                                                        onChange={selectSourceVariant}
                                                    >
                                                        {sourceVariants.map((option) => (
                                                            <MenuItem key={option.id} id={option.id} value={option}>
                                                                {option.title} ({option.accessories?.length || 0})
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>

                                                <Grid item xs={12} container spacing={4} alignItems="center">
                                                    {
                                                        selectedSourceVariant?.id > 0 && (
                                                            !hasSourceVariantAccessories()
                                                                ?
                                                                <Grid item xs={12} container justify="center">
                                                                    <Typography variant="body" align="center">
                                                                        This dealer variant has no accessory packs to import
                                                                    </Typography>
                                                                </Grid>
                                                                :
                                                                <>
                                                                    {
                                                                        selectedSourceVariant.accessories.map((accessory, index) => (
                                                                            <Grid key={index} item xs={6} md={3} container alignItems="center" justify="space-between">
                                                                                <Grid item>
                                                                                    <Typography variant="caption" gutterBottom>{accessory.title}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                    <Grid item xs={12} container justify="center">
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            style={{ padding: "10px 50px" }}
                                                                            disabled={!(selectedSourceVariant?.id > 0)}
                                                                            onClick={importVariantAccessories}
                                                                        >
                                                                            Import
                                                                        </Button>
                                                                    </Grid>
                                                                </>
                                                        )
                                                    }
                                                </Grid>

                                            </Grid>
                                        }
                                    </Box>
                                </>
                            }

                            <Box mt={3} mb={3}>
                                <Divider />
                            </Box>

                        </Grid>
                    </Grid>
                    {
                        accessoryList.length > 0 && accessoryList.map((tile, index) => {
                            return (
                                <React.Fragment key={tile.id}>
                                    <Box mb={3}>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <GridList cols={1} cellHeight={180} className={classes.gridList}>
                                                    <GridListTile>
                                                        <ImageCarousel imgArray={tile.features} vehicle={tile} />
                                                        <GridListTileBar
                                                            title={tile.title}
                                                            subtitle={
                                                                <Grid container direction="column">
                                                                    <Grid item>
                                                                        <CurrencyValue value={tile.price} />
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </GridListTile>
                                                </GridList>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box pt={3} pl={3}>
                                                    <Typography variant="subtitle1">
                                                        Items:
                                                    </Typography>
                                                    <List dense>
                                                        {
                                                            tile?.features.map((feature, index) => {
                                                                return (
                                                                    <ListItem key={index}>
                                                                        <ListItemIcon>
                                                                            <CheckIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={feature.name} />
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Box>
                                            </Grid>
                                            <Grid item container
                                                alignItems='center'
                                                justifyContent='flex-end'
                                                xs={12} md={2}>
                                                <div>
                                                    <IconButton
                                                        onClick={() => editAccessory(tile)}
                                                        aria-label={`Edit ${tile.title}`} >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenConfirm((prev) => !prev)
                                                            setActiveAccessory(tile)
                                                        }}
                                                        aria-label={`Delete ${tile.title}`}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {
                                        (index !== (accessoryList?.length - 1)) && <Divider />
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                    {
                        (!accessoryList.length && !loading) &&
                        <Grid container justifyContent="center" className="p-5">
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" color="textSecondary">
                                    No Accessory packs available
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                        loading &&
                        <Grid container justifyContent="center">
                            <Grid item xs={1}>
                                <Box mt={3}>
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </Grid>
                    }

                </div>
            </Zoom>

            <Zoom in={formView} mountOnEnter unmountOnExit
                onExited={() => setListView((prev) => !prev)}>
                <div>
                    <AccessoryForm data={activeAccessory}
                        onSubmit={handleFormAction}
                        actionType={formType}
                        cancel={formCancel} />
                </div>
            </Zoom>

        </React.Fragment>
    )
}