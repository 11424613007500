import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import ImageUploadInput from '../../../shared/ImageUploadFormInput';
import { LoadingContext } from '../../../shared/context/loadingContext';
import { DealerContext } from '../../../shared/DealerContext';
import { useHistory } from 'react-router-dom';

const initialValues = {
  banner: false,
  welcomeHeadline: false,
  welcomeIntro: false,
  filter:false,
  vehicleListing: false,
  comingSoonImg: false,
  headLineType:'',
  filterType:'',
  vehicleListingType:'',
  mileageType:'',
  yearType:'',
  transmissionType:'',
  fuelTypeSelected:'',
  includePage: false,
  pageTitle: '',
  pageDescription: '',
  url: '',
  htmlModule1:false,
  htmlModule2:false,

  // Additional fields for multiline text areas
  bannerText: '',
  welcomeHeadlineText: '',
  welcomeIntroText: '',
  filterTypeText: '',
  vehicleListingTypeText: '',

  title: '',
  description: '',
  urlText: '',
  htmlModule1Text:'',
  htmlModule2Text:'',

  //preview
  price: false,
  priceText: '',

  year: false,
  yearText: '',

  make: false,
  makeText: '',

  model: false,
  modelText: '',

  variant: false,
  variantText: '',

  mileage: false,
  mileageText: '',

  transmission: false,
  transmissionText: '',

  fuelType: false,
  fuelTypeText: ''
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  dynamicComponentCell: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const UsedVLP = (props) => {
  const { page } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedMotorGroup } = useContext(DealerContext);
  const parsedObj = page?.templateConfig ? JSON.parse(page.templateConfig) : initialValues;
  const { showLoading, hideLoading } = useContext(LoadingContext);
  let history = useHistory();

  const headLineTypes = [
    { id: 1, name: 'Section' },
    { id: 2, name: 'Overlay' }
  ];

  const filterTypes = [
    { id: 1, name: 'Standard Dropdown' },
    { id: 2, name: 'Standard Slider' },
    { id: 3, name: 'Advanced Dropdown' },
    { id: 4, name: 'Advanced Slider' }
  ]

  const vehicleListingTypes = [
    { id: 1, name: 'Table' },
    { id: 2, name: 'List' }
  ];

  const previewOptions = [
    { id: 1, name: 'Text' },
    { id: 2, name: 'Icon' }
  ];

  const handleSelectChange = (e, setFieldValue, fieldName) => {
    setFieldValue(fieldName, e.target.value);
  };

  const isBase64 = (str) => {
    const base64Pattern = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/i;
    return base64Pattern.test(str);
  };

  const processImages = async (fields) => {
    try {
      const imageKeys = [
        'comingSoonImage',
        'mileageImg',
        'yearImg',
        'fuelImg',
        'transmissionImg'
      ];

      const imageInput = [];

      // Collect base64 images
      imageKeys.forEach(key => {
        const image = fields[key];
        if (isBase64(image?.image)) {
          imageInput.push(image);
        } else {
          console.log(`The image ${key} is not base64 encoded or is missing.`);
        }
      });

      if (imageInput.length > 0) {
        const res = await axios.put(`${BASE_URI}/Bob/uploadImage`, imageInput);
        const imgUrls = res.data;

        // Map URLs back to images
        imgUrls.forEach((url) => {
          imageKeys.forEach(key => {
            const image = fields[key];
            if (url.toLowerCase().includes(image?.fileName.toLowerCase())) {
              image.image = url;
            }
          });
        });
      }

    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
      console.error('Error in processImages:', error);
    }
  };

  const onSubmitUpdatePage = async (fields) => {
    try {
      showLoading();
      await processImages(fields);
      fields.bobPayload = transformToBobPayload(fields);
      if (selectedMotorGroup.id) {
        if (page != undefined || page != null) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;
          axios
            .put(`${BASE_URI}/WebsitePages/${pageId}`, {
              ...page,
              id: pageId
            })
            .then((result) => {
              enqueueSnackbar('Updated Used VLP page', { variant: 'success' });
              history.push('/templates');
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            pageCategoryId: 3
          };
          axios
            .post(`${BASE_URI}/WebsitePages`, {
              ...newPage
            })
            .then((result) => {
              enqueueSnackbar('created used VLP page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
        hideLoading();
      }
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
      console.error('Error in onSubmitUpdatePage:', error);
      hideLoading();
    }
  };

  const transformToBobPayload = (values) => {
    const layoutMap = new Map();
  
    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params = [...new Map([...existing.params, ...params].map(item => [item.key, item])).values()];
        }
      }
    };
  
    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }
  
      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];
  
      if (!field.endsWith('Text') && !field.endsWith('Order')) {
        if (field.endsWith('Img') || field.toLowerCase().includes('image')) {
          params.push({ key: field, value: values[field] });
        } else if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }
    });
  
    const layout = Array.from(layoutMap.values());
  
    return {
      layout,
      page: values.page?.title || ''
    };
  };
  
  
  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant='h6' component='div'>
          Used Vehicle Listing (VLP)
        </Typography>
      </Toolbar>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Formik
            initialValues={parsedObj}
            onSubmit={(values) => {
              onSubmitUpdatePage(values);
            }}
          >
            {({ touched, values, errors, handleBlur, handleChange, setFieldValue }) => {
              const handleChangeCustom = (fieldName, checked) => {
                setFieldValue(fieldName, checked);
              };
              return (
                <Form>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Include</TableCell>
                          <TableCell>Content</TableCell>
                          <TableCell>Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                          <TableCell>Include Page</TableCell>
                          <TableCell>Boolean</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.includePage}
                                  onChange={(e) => handleChangeCustom('includePage', e.target.checked)}
                                  name='includePage'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='title'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='description'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>URL Link</TableCell>
                          <TableCell>Link</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='urlText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Banner</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.banner}
                                  onChange={(e)=>handleChangeCustom('banner',  e.target.checked)}
                                  name='banner'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.banner && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='bannerText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='bannerOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Welcome Headline</TableCell>
                          <TableCell>
                            <TextField
                              id='headLineType'
                              name='headLineType'
                              label='Headline'
                              value={values.headLineType}
                              select
                              fullWidth
                              helperText={errors.headLineType && touched.headLineType ? errors.headLineType : ''}
                              error={errors.headLineType && touched.headLineType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'headLineType')}
                            >
                              {headLineTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.welcomeHeadline}
                                  onChange={(e)=>handleChangeCustom('welcomeHeadline',  e.target.checked)}
                                  name='welcomeHeadline'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.welcomeHeadline && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='welcomeHeadlineText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='welcomeHeadlineOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Welcome Intro</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.welcomeIntro}
                                  onChange={(e)=>handleChangeCustom('welcomeIntro',  e.target.checked)}
                                  name='welcomeIntro'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.welcomeIntro && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='welcomeIntroText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='welcomeIntroOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Filter</TableCell>
                          <TableCell>
                            <TextField
                              id='filterType'
                              name='filterType'
                              label='Filter'
                              value={values.filterType}
                              select
                              fullWidth
                              helperText={errors.filterType && touched.filterType ? errors.filterType : ''}
                              error={errors.filterType && touched.filterType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'filterType')}
                            >
                              {filterTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.filter}
                                  onChange={(e)=>handleChangeCustom('filter',  e.target.checked)}
                                  name='filter'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.filter && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='filterTypeText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='filterTypeOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Vehicle Listing</TableCell>
                          <TableCell>
                            <TextField
                              id='vehicleListingType'
                              name='vehicleListingType'
                              label='Vehicle Listing'
                              value={values.vehicleListingType}
                              select
                              fullWidth
                              helperText={errors.vehicleListingType && touched.vehicleListingType ? errors.vehicleListingType : ''}
                              error={errors.vehicleListingType && touched.vehicleListingType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'vehicleListingType')}
                            >
                              {vehicleListingTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.vehicleListing}
                                  onChange={(e)=>handleChangeCustom('vehicleListing',  e.target.checked)}
                                  name='vehicleListing'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.vehicleListing && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='vehicleListingTypeText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='vehicleListingTypeOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Coming soon Image</TableCell>
                          <TableCell>Image</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.comingSoonImg}
                                  onChange={(e)=>handleChangeCustom('comingSoonImg',  e.target.checked)}
                                  name='comingSoonImg'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.comingSoonImg && (
                              <ImageUploadInput title='Coming soon Image' required fieldName={`comingSoonImage`} />
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='comingSoonOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Price</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.price}
                                  onChange={(e)=>handleChangeCustom('price',  e.target.checked)}
                                  name='price'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.price && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='priceText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='priceOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Make</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.make}
                                  onChange={(e)=>handleChangeCustom('make',  e.target.checked)}
                                  name='make'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.make && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='makeText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='makeOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Model</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.model}
                                  onChange={(e)=>handleChangeCustom('model',  e.target.checked)}
                                  name='model'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.model && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='modelText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='modelOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Variant</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.variant}
                                  onChange={(e)=>handleChangeCustom('variant',  e.target.checked)}
                                  name='variant'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.variant && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='variantText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='variantOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell>
                            <TextField
                              id='yearType'
                              name='yearType'
                              label='Year'
                              value={values.yearType}
                              select
                              fullWidth
                              helperText={errors.yearType && touched.yearType ? errors.yearType : ''}
                              error={errors.yearType && touched.yearType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'yearType')}
                            >
                              {previewOptions.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.year}
                                  onChange={(e)=>handleChangeCustom('year',  e.target.checked)}
                                  name='year'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.year && (
                              <div className={classes.dynamicComponentCell}>
                                {values.yearType=='Text'?
                                <Field
                                name='yearText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                              :
                              <ImageUploadInput title='year icon' required fieldName={`yearImg`} />
                            }
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='yearOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Mileage</TableCell>
                          <TableCell>
                            <TextField
                              id='mileageType'
                              name='mileageType'
                              label='Mileage'
                              value={values.mileageType}
                              select
                              fullWidth
                              helperText={errors.mileageType && touched.mileageType ? errors.mileageType : ''}
                              error={errors.mileageType && touched.mileageType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'mileageType')}
                            >
                              {previewOptions.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.mileage}
                                  onChange={(e)=>handleChangeCustom('mileage',  e.target.checked)}
                                  name='mileage'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.mileage && (
                              <div className={classes.dynamicComponentCell}>
                                {values.mileageType=='Text'?
                                <Field
                                name='mileageText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                              :
                              <ImageUploadInput title='mileage icon' required fieldName={`mileageImg`} />
                            }
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='mileageOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Transmission</TableCell>
                          <TableCell>
                            <TextField
                              id='transmissionType'
                              name='transmissionType'
                              label='Transmission'
                              value={values.transmissionType}
                              select
                              fullWidth
                              helperText={errors.transmissionType && touched.transmissionType ? errors.transmissionType : ''}
                              error={errors.transmissionType && touched.transmissionType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'transmissionType')}
                            >
                              {previewOptions.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.transmission}
                                  onChange={(e)=>handleChangeCustom('transmission',  e.target.checked)}
                                  name='transmission'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.transmission && (
                              <div className={classes.dynamicComponentCell}>
                                {values.transmissionType=='Text'?
                                <Field
                                name='transmissionText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                              :
                              <ImageUploadInput title='transmission icon' required fieldName={`transmissionImg`} />
                            }
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='transmissionOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>

                        </TableRow>

                        <TableRow>
                          <TableCell>Fuel Type</TableCell>
                          <TableCell>
                            <TextField
                              id='fuelTypeSelected'
                              name='fuelTypeSelected'
                              label='Fuel Type'
                              value={values.fuelTypeSelected}
                              select
                              fullWidth
                              helperText={errors.fuelTypeSelected && touched.fuelTypeSelected ? errors.fuelTypeSelected : ''}
                              error={errors.fuelTypeSelected && touched.fuelTypeSelected}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'fuelTypeSelected')}
                            >
                              {previewOptions.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.fuelType}
                                  onChange={(e)=>handleChangeCustom('fuelType',  e.target.checked)}
                                  name='fuelType'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.fuelType && (
                              <div className={classes.dynamicComponentCell}>
                                {values.fuelTypeSelected=='Text'?
                                <Field
                                name='fuelTypeText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                              :
                              <ImageUploadInput title='fuel Type icon' required fieldName={`fuelImg`} />
                            }
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='fuelTypeOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 1</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule1}
                                  onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                  name='htmlModule1'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule1 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule1Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule1Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 2</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule2}
                                  onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                  name='htmlModule2'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule2 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule2Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule2Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                  <Box mt={2}>
                    <Button variant='contained' color='primary' type='submit'>
                      Save Changes
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UsedVLP;
