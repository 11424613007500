import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import { DEFAULT_PAGE_SIZE } from "../../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import ExportContainer from "../../../../shared/ExportContainer";
import Divider from "@material-ui/core/Divider";
import {Link} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
// import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import UsedStockFilter from "./UsedStockFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "45ch",
    },
  },
  export: {
    width: "45ch",
  },
}));

export default function UsedStockContainer() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [id, setID] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("title");
  const [elementRef, setElementRef] = useState("");
  const { loading, ranges, total } = UsedStockFilter(
    id,
    page,
    pageSize,
    order,
    orderBy
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: "stockNumber", numeric: false, label: "Stock #", sort: true },
    { id: "selling", numeric: false, label: "Selling", sort: true },
    { id: "year", numeric: false, label: "Year", sort: true },
    { id: "trim", numeric: false, label: "Trim", sort: true },
    { id: "milleage", numeric: false, label: "Mileage", sort: true },
    { id: "type", numeric: false, label: "Type", sort: true },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Toolbar disableGutters>
            <div style={{flex: '1 1'}}>
              <ExportContainer values={ranges}
                               fileName='LeadsList'
                               headers={headCells} />
            </div>
          </Toolbar>
        </Grid>
      </Grid>
      <Divider
        variant="fullWidth"
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      />
      <TableContainer ref={(el) => setElementRef(el)}>
        <Table aria-label="ranges table">
          <TableHeadersSort
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {ranges.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.stockNumber}</StyledTableCell>
                <StyledTableCell>{row.selling}</StyledTableCell>
                <StyledTableCell>{row.year}</StyledTableCell>
                <StyledTableCell>{row.trim}</StyledTableCell>
                <StyledTableCell>{row.mileage}</StyledTableCell>
                <StyledTableCell>{row.type}</StyledTableCell>
              </StyledTableRow>
            ))}
            {(!ranges.length || ranges.length === 0) && !loading && (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={headCells.length}
                  align="center"
                  style={{ borderBottom: 0 }}
                  className="py-3"
                >
                  <Typography variant="h6" color="textSecondary">
                    No Used Stock
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            )}
            {loading && (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={headCells.length}
                  align="center"
                  style={{ borderBottom: 0 }}
                >
                  <CircularProgress />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}
