import React, { useState, useContext, useEffect } from 'react';
import { Box, Stepper, Step, StepButton, Button, Typography, Toolbar, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CoreStyling from './CoreStyling';
import Configuration from './Configuration';
import ColourStyling from './ColourStyling';
import Menu from './Menu';
import ButtonStyling from './ButtonStyling';
import Preview from './Preview';
import { LoadingContext } from '../../shared/context/loadingContext';
import { DealerContext } from '../../shared/DealerContext';
import Fonts from './Fonts';

const TemplateInfo = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddMode, setIsAddMode] = useState(!id);
  const { dealerId } = useContext(DealerContext);
  const [formValues, setFormValues] = useState(null);
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    stepPadding: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4)
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column'
    },

    tr: {
      padding: '5px 30px'
    },
    th: {
      padding: '5px 10px',
      textAlign: 'center'
    },
    td: {
      padding: '2px 10px'
    },
    tdCenter: {
      padding: '2px 10px',
      textAlign: 'center'
    },
    textArea: {
      fontFamily: 'monospace',
      padding: '10px',
      width: '100%',
      height: '200px !important',
      fontSize: '12px',
      overflowY: 'scroll',
      whiteSpace: 'pre-wrap',
      backgroundColor: '#f4f4f4',
      border: '1px solid #ccc',
      borderRadius: '5px'
    }
  }));

  const classes = useStyles();

  const handleNext = (values) => {
    const validateStep = () => !dealerId ? "Cannot proceed to this step: no dealer selected" : "";
    const invalidMessage = validateStep(dealerId);

    if (invalidMessage !== "") {
      enqueueSnackbar(invalidMessage, { variant: 'warning', vertical: 'top', horizontal: 'center' });
      return;
    }

    setFormValues({ ...formValues, ...values });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async (values) => {
    const processLogo = (logo, altTag) => {
      if (!logo) return { imageUrl: '', altTag: '' };
      const imageUrl = logo.imageUrl?.image || logo.imageUrl || '';
      return {
        imageUrl,
        altTag: altTag || logo.altTag || ''
      };
    };

    values.desktopLogo = processLogo(values.desktopLogo, values.desktopAltTag);
    values.mobileLogo = processLogo(values.mobileLogo, values.mobileLogoAltTag);
    values.groupLogo = processLogo(values.groupLogo, values.groupLogoAltTag);
    values.favicon = processLogo(values.favicon, values.faviconAltTag);
    values.siteImage = processLogo(values.siteImage, values.siteImageAltTag);
    values.dealerId = dealerId;

    if (isAddMode) {
      await createTemplate(values);
    } else {
      await editTemplate(id, values);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const editTemplate = async (id, fields) => {
    try {
      showLoading();
      const result = await axios.put(`${BASE_URI}/WebsiteTemplates/${id}`, fields);
      enqueueSnackbar('Successfully saved the template changes.', { variant: 'success' });
      saveToRelatedDealers(id, fields);
      history.push('/templates');
      return result;
    } catch (error) {
      enqueueSnackbar(error.data?.message, { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  const getTemplate = async () => {
    try {
      showLoading();
      const result = await axios.get(`${BASE_URI}/WebsiteTemplates/${id}`);
      let formData = result.data;
      formData.isAddMode = isAddMode;
      setFormValues({
        ...formData
      });
    } catch (error) {
      enqueueSnackbar('Unable to get template details', { variant: 'error' });
      hideLoading();
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      getTemplate().then(() => {
        hideLoading();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTemplate = async (fields) => {
    try {
      showLoading();
      const result = await axios.post(` ${BASE_URI}/WebsiteTemplates`, {
        ...fields
      });
      enqueueSnackbar('Successfully added the template.', { variant: 'success' });
      saveToRelatedDealers(result.data.id, result.data);
      setIsAddMode(false);
      history.push('/templates');
      return result;
    } catch (error) {
      enqueueSnackbar(`Failed to add template: ${error.message}`, { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  async function saveToRelatedDealers(id, template) {
    try {
      const result = await axios.post(`${BASE_URI}/WebsiteTemplates/UpdateRelatedDealers/${id}`, template);
      enqueueSnackbar('Template saved to linked dealers', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to save the template to the linked dealers', { variant: 'error' });
    }
  }

  const steps = isAddMode
    ? ['Configuration', 'Core Styling', 'Colour Styling', 'Menu', 'Button Styling', 'Preview']
    : ['Configuration', 'Core Styling', 'Fonts', 'Colour Styling', 'Menu', 'Button Styling', 'Preview'];

  const stepsContent = isAddMode
    ? [
        <Configuration initialValues={formValues} onSubmit={handleNext} isAddMode={isAddMode} />,
        <CoreStyling initialValues={formValues} onSubmit={handleNext} />,
        <ColourStyling initialValues={formValues} onSubmit={handleNext} />,
        <Menu initialValues={formValues} onSubmit={handleNext} />,
        <ButtonStyling initialValues={formValues} onSubmit={handleNext} />,
        <Preview initialValues={formValues} onSubmit={handleSubmit} />
      ]
    : [
        <Configuration initialValues={formValues} onSubmit={handleNext} isAddMode={isAddMode} />,
        <CoreStyling initialValues={formValues} onSubmit={handleNext} />,
        <Fonts />,
        <ColourStyling initialValues={formValues} onSubmit={handleNext} />,
        <Menu initialValues={formValues} onSubmit={handleNext} />,
        <ButtonStyling initialValues={formValues} onSubmit={handleNext} />,
        <Preview initialValues={formValues} onSubmit={handleSubmit} />
      ];

  const handleStep = (step) => () => {
    handleNext(formValues);
    if (dealerId !== '') {
      setActiveStep(step);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar disableGutters>
        <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
          {isAddMode ? 'Add' : 'Edit'} {'Template'}
        </Typography>
      </Toolbar>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you're done</Typography>
        ) : (
          <div>
            <Typography sx={{ mt: 2, mb: 1 }}>{stepsContent[activeStep]}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {activeStep !== 0 && (
                <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
              )}
            </Box>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default TemplateInfo;
