import React, {useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Formik, Form, Field} from "formik";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import EmailController from "./EmailController";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import {DEFAULT_PAGE_SIZE} from "../../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
    },
    inputPadding: {
        paddingLeft: "2rem",
        paddingTop: "2rem",
    },
    card: {
        // background: "lightblue",
    },
    text: {
        verticalAlign: "text-top",
    },
}));

export default function AddLeadForm() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const { state, tab, id } = useParams();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("title");
    const [elementRef, setElementRef] = useState("");
    const {loading, ranges, total} = EmailController(
        id,
        page,
        pageSize,
        order,
        orderBy
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getValues = () => {
        return ranges;
    };

    const headCells = [
        {id: "leadState", numeric: false, label: "LeadState", sort: true},
        {id: "sentBy", numeric: false, label: "Sent By", sort: true},
        {
            id: "subject",
            numeric: false,
            label: "Subject",
            sort: true,
        },
        {id: "message", numeric: false, label: "Message", sort: true},
        {id: "to", numeric: false, label: "To", sort: true},
        {id: "cc", numeric: false, label: "CC", sort: true},
        {id: "created", numeric: false, label: "Created", sort: true},
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    const values = {
        value: "",
    };

    return (
        <div className={classes.stepPadding}>
            <Formik
                initialValues={values}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        console.log(values);
                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Card className={classes.card}>
                                <CardContent>
                                    <TableContainer ref={(el) => setElementRef(el)}>
                                        <Table aria-label="ranges table">
                                            <TableHeadersSort
                                                order={order}
                                                orderBy={orderBy}
                                                headCells={headCells}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {ranges.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.leadStatus}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.sentByUser}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.subject}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.body}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.toEmail}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.cc}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={classes.text}>
                                                            {row.createDate}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                                {(!ranges.length || ranges.length === 0) && !loading && (
                                                    <StyledTableRow>
                                                        <StyledTableCell
                                                            colSpan={headCells.length}
                                                            align="center"
                                                            style={{borderBottom: 0}}
                                                            className="py-3"
                                                        >
                                                            <Typography variant="h6" color="textSecondary">
                                                                No emails sent
                                                            </Typography>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                                {loading && (
                                                    <StyledTableRow>
                                                        <StyledTableCell
                                                            colSpan={headCells.length}
                                                            align="center"
                                                            style={{borderBottom: 0}}
                                                        >
                                                            <CircularProgress/>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            count={total}
                                            page={page}
                                            rowsPerPageOptions={[5, 10, 25]}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={pageSize}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </CardContent>
                                <Divider
                                    variant="fullWidth"
                                    style={{marginTop: "1rem", marginBottom: "1rem"}}
                                />

                                <Grid container direction="row">
                                    <Grid item className={classes.stepPaddingButton}>
                                        <Button
                                            variant="contained"
                                            style={{margin: "1rem"}}
                                            color="primary"
                                            type="submit"
                                            disabled={!props.isValid}
                                        >
                                            Resend emails for current state
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>

        </div>
    );
}
