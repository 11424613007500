import React, { useContext, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DealerContext } from './DealerContext';
import DealerListFilter from '../components/dealerAdmin/DealerListFilter';
import { AUTH_USER, DEFAULT_PAGE_SIZE } from './Constants';

const useStyles = makeStyles((theme) => ({
  white: {
    color: 'white !important'
  },
  cssOutlinedInput: {
    '&$notchedOutline': {
      borderColor: `white !important`
    }
  },
  icon: {
    fill: 'white !important'
  },
  notchedOutline: {
    borderColor: 'white !important'
  },
  select: {
    minWidth: '300px',
    maxWidth: '850px',
    width: '40%'
  }
}));

export const DealerSelect = ({ label, fullWidth, className, secondary, disabled }) => {
  const classes = useStyles();
  const { selectedMotorGroup, updateSelectedMotorGroup } = useContext(DealerContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [clickedOutside, setClickedOutside] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { dealers, total } = DealerListFilter(searchString, page, pageSize);

  const userDealers = JSON.parse(localStorage.getItem(AUTH_USER))?.userDealerIDs;

  const filteredDealers = dealers.filter((o) => userDealers.find((u) => u === o.id));

  const secondaryInputProps = {
    classes: {
      root: classes.cssOutlinedInput,
      notchedOutline: classes.notchedOutline,
      input: classes.white
    }
  };

  const secondaryLabelProps = {
    classes: {
      root: classes.white
    }
  };

  function handleDealerSelection(motorgroup) {
    if (!motorgroup) return;
    updateSelectedMotorGroup(motorgroup, motorgroup.id);
  }

  function getOptionLabel(option) {
    let optionLabel = '';
    if (Object.entries(option).length !== 0) {
      optionLabel += !option.motorGroupName ? `No group - ${option.name}` : `${option.motorGroupName} - ${option.name}`;
      localStorage.setItem('selectedOption', optionLabel);
    }

    return optionLabel;
  }

  useEffect(() => {
    const handleDocumentClick = (e) => {
      const textField = e.target;

      if (textField.contains(e.target)) {
        setPage(0);
        setSearchString('');
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <React.Fragment>
      <Autocomplete
        id='dealerSelect'
        name='dealerSelect'
        className={classes.select}
        fullWidth={fullWidth}
        value={selectedMotorGroup}
        options={filteredDealers}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) => option.id === value?.id}
        onChange={(e, child) => handleDealerSelection(child)}
        renderInput={(params) => (
          <TextField
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            {...params}
            className={className}
            label='Dealers'
            variant={secondary ? 'outlined' : 'standard'}
            InputLabelProps={secondary ? secondaryLabelProps : ''}
            InputProps={{
              ...params.InputProps,
              classes: secondary ? secondaryInputProps.classes : '',
              endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            }}
          />
        )}
      />
    </React.Fragment>
  );
};
