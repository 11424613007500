import React, { useState, useContext } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {
  Table,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  CircularProgress,
  TablePagination,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import { useParams } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { LeadMainContext } from '../../shared/contexts/LeadMainContext';
import { DealerContext } from '../../shared/DealerContext';
import TableHeadersSort from '../../shared/TableHeadersSort';
import ExportContainer from '../../shared/ExportContainer';
import Leads from '../LeadsContainer/LeadsFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '45ch'
    }
  },
  export: {
    width: '45ch'
  },
  addLeadButton: {
    marginLeft: '60%',
    textDecoration: 'none'
  },
  stepPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttonFlagged: {
    backgroundColor: 'red'
  },
  buttonDisabled: {
    cursor: 'not-allowed'
  },
  buttonAllowed: {
    cursor: 'pointer',
    backgroundColor: 'light-green'
  }
}));

export default function WebsiteLeads() {
  const classes = useStyles();
  const theme = useTheme();

  let { ...context } = useContext(LeadMainContext);
  const { isMasterDealer, selectedMotorGroup, dealerArr } = useContext(DealerContext);

  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('DateUpdated');

  const [selectedDate, setSelectedDate] = React.useState(context.dates);
  const [names, setNames] = useState('');
  const [source, setSource] = useState('');
  const [leadName, setLeadName] = useState('');

  const { id } = useParams();

  const allLeadState = -1;

  let { loading, ranges, total } = Leads(
    page,
    pageSize,
    order,
    orderBy,
    allLeadState,
    selectedDate,
    source,
    names,
    leadName,
    selectedMotorGroup.motorgroupID,
    id,
    searchString
  );

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(0);
    setSearchString(searchInput);
  };

  const headCellsWithMasterDealer = [
    { id: 'Id', numeric: false, label: 'Id', sort: true },
    { id: 'Name', numeric: false, label: 'Name', sort: true },
    { id: 'PhoneNumber', numeric: false, label: 'Phone', sort: true },
    { id: 'Email Address', numeric: false, label: 'EmailAddress', sort: true },
    { id: 'Type', numeric: false, label: 'Type', sort: true },
    { id: 'LeadOrigin', numeric: false, label: 'Lead Origin', sort: true },
    { id: 'LeadSubmissionStatus', numeric: false, label: 'Submission Status', sort: true },
    { id: 'SubmissionError', numeric: false, label: 'Submission Error (if failed)', sort: true },
    { id: 'Dealer', numeric: false, label: 'Dealer', sort: true },
    { id: 'DateUpdated', numeric: false, label: 'Updated', sort: true }
  ];

  const headCellsWithoutMasterDealer = [
    { id: 'Id', numeric: false, label: 'Id', sort: true },
    { id: 'Name', numeric: false, label: 'Name', sort: true },
    { id: 'PhoneNumber', numeric: false, label: 'Phone', sort: true },
    { id: 'Email Address', numeric: false, label: 'EmailAddress', sort: true },
    { id: 'Type', numeric: false, label: 'Type', sort: true },
    { id: 'LeadOrigin', numeric: false, label: 'Lead Origin', sort: true },
    { id: 'LeadSubmissionStatus', numeric: false, label: 'Submission Status', sort: true },
    { id: 'SubmissionError', numeric: false, label: 'Submission Error (if failed)', sort: true },
    { id: 'DateUpdated', numeric: false, label: 'Updated', sort: true }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }))(TableRow);

  const LeadTypeLookUp = (data) => {
    switch (data.leadTypeId) {
      case 1:
        data.leadTypeName = 'New Vehicle';
        break;
      case 2:
        data.leadTypeName = 'Used Vehicle';
        break;
      case 3:
        data.leadTypeName = 'Contact Us';
        break;
      case 4:
        data.leadTypeName = 'Staff Contact';
        break;
      case 5:
        data.leadTypeName = 'Service';
        break;
      case 6:
        data.leadTypeName = 'Part';
        break;
      case 7:
        data.leadTypeName = 'Personal Loan';
        break;
      case 8:
        data.leadTypeName = 'Test Drive';
        break;
      case 11:
        data.leadTypeName = 'Trade In';
        break;
      case 12:
        data.leadTypeName = 'Finance';
        break;

      default:
        break;
    }
  };

  let filterSearchLeads = ranges.filter(
    (a) =>
      searchString === '' ||
      a.phone?.toLowerCase().includes(searchString.toLowerCase()) ||
      a.id?.toString().includes(searchString.toLowerCase())
  );
  return (
    <React.Fragment>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker
              label='Only show Leads (updated) since'
              clearable
              fullWidth
              disableFuture
              value={selectedDate}
              onChange={handleDateChange}
              animateYearScrolling
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <ExportContainer values={ranges} fileName='LeadsList' headers={ isMasterDealer ? headCellsWithMasterDealer: headCellsWithoutMasterDealer } />
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <form onSubmit={handleSubmit}>
            <TextField
              name='leadSearch'
              id='leadSearch'
              label='Search Leads'
              value={searchInput}
              type='text'
              InputProps={{
                endAdornment: searchInput ? (
                  <InputAdornment position='end'>
                    <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  ''
                )
              }}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </form>
        </Grid>
      </Grid>

      <Divider variant='fullWidth' style={{ marginTop: '1rem', marginBottom: '1rem' }} />

      <Grid container className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label='Leads Table'>
              <TableHeadersSort order={order} orderBy={orderBy} headCells={isMasterDealer ? headCellsWithMasterDealer: headCellsWithoutMasterDealer} onRequestSort={handleRequestSort} />
              <TableBody>
                {filterSearchLeads.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.id}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.phone}</StyledTableCell>
                    <StyledTableCell>{row.emailAddress}</StyledTableCell>
                    <StyledTableCell>
                      {LeadTypeLookUp(row)}
                      {row?.leadTypeName}
                    </StyledTableCell>
                    <StyledTableCell>{row.source}</StyledTableCell>
                    <StyledTableCell>{row.statusInformation}</StyledTableCell>
                    <StyledTableCell>{row.integrationErrorMessage}</StyledTableCell>
                    {isMasterDealer && <StyledTableCell>{dealerArr.find((d) => d.id == row.dealerId).name}</StyledTableCell> }
                    <StyledTableCell>{row.dateUpdated}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {(!ranges.length || ranges.length === 0) && !loading && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={isMasterDealer ? headCellsWithMasterDealer.length: headCellsWithoutMasterDealer.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                      <Typography variant='h6' color='textSecondary'>
                        No Leads
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {loading && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={isMasterDealer ? headCellsWithMasterDealer.length: headCellsWithoutMasterDealer.length} align='center' style={{ borderBottom: 0 }}>
                      <CircularProgress />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              count={total}
              page={page}
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
