import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { Table, TableContainer, TableBody, TablePagination } from "@material-ui/core";
import { Clear, Edit, OpenInBrowser } from '@material-ui/icons';
import axios from "axios";
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../shared/Constants";
import { useStyles, StyledTableRow, StyledTableCell } from '../../shared/CommonStyles';
import { GetErrorMessage } from '../../shared/CommonFunctions';
import TableHeadersSort from "../../shared/TableHeadersSort";

export default function VehicleLibraryMakes() {

    const classes = useStyles({ stickyHeaderTableHeight: window.innerHeight });
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(true);
    const [ makes, setMakes ] = useState([]);
    const [ searchString, setSearchString ] = useState("");

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const headCells = [
        { id: "name", label: "Make Name", sort: true },
        { id: "actions", label: "Actions", sort: false, align: "center", width: "250px" }
    ];

    useEffect(() => {

        Promise.allSettled([
            LoadMakes()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the master makes"), { variant: 'error' });

        }).finally(() => {

            setLoading(false);

        });

    }, []);

    function LoadMakes() {

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterMake`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {

                let data = [
                    ...response?.data
                ];

                setMakes(data);
                res(data);

            }).catch(error => {

                if (axios.isCancel(error)) {
                    return;
                }

                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master makes"), { variant: 'error' });
                rej(null);

            });

        });

    }

    function SelectMake(id) {

        history.push(`/VehicleLibrary/Makes/${id}/Models`);

    }

    function EditMake(id) {

        history.push(`/VehicleLibrary/Make/${id}/edit`);

    }

    function GetDisplayMakes() {

        try {

            // Filter, slice and sort the makes list
            let filteredMakes = !searchString
                ? [ ...makes ]
                : makes.filter(m => m.name?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()));

            let sortedMakes = filteredMakes.sort(GetComparator());

            let startIndex = page * pageSize;
            let slicedMakes = sortedMakes.slice(startIndex, startIndex + pageSize);

            return slicedMakes;

        } catch (error) {

            enqueueSnackbar(GetErrorMessage(error, "Failed to filter and sort the list"), { variant: 'error' });
            return makes;

        }

    }

    function ClearSearchString() {

        setSearchString("");

    }

    function HandleChangeSearchString(event) {

        setSearchString(event?.target?.value);

    }

    function GetComparator() {

        return order === "asc"
            ? (a, b) => AscendingComparator(a, b)
            : (a, b) => -AscendingComparator(a, b);

    }

    function AscendingComparator(a, b) {

        return (a[orderBy] > b[orderBy]) ? 1 : -1;

    }

    function HandleRequestSort(event, property) {

        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

    }

    function HandleChangePage(event, newPage) {

        setPage(newPage);

    }

    function HandleChangeRowsPerPage(event) {

        setPageSize(parseInt(event?.target?.value || 25, 10));
        setPage(0);

    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                <Grid item xs={12} container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4">
                            Master Makes
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            id="makesFilter"
                            label="Search Makes"
                            disabled={loading}
                            value={searchString}
                            onChange={HandleChangeSearchString}
                            InputProps={{
                                endAdornment:
                                    searchString?.length > 0 &&
                                    <InputAdornment position="end">
                                        <IconButton size="small" disableFocusRipple disableRipple onClick={() => ClearSearchString()}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>

                {
                    loading &&
                    <Grid item xs={12} container direction="row" justify="center">
                        <CircularProgress/>
                    </Grid>
                }
                {
                    !loading &&
                    <Grid item xs={12}>
                        <TableContainer className={classes.stickyHeaderTable}>
                            <Table stickyHeader aria-label="Master Makes">
                                <TableHeadersSort
                                    order={order}
                                    orderBy={orderBy}
                                    headCells={headCells}
                                    onRequestSort={HandleRequestSort}
                                />
                                <TableBody>
                                    {
                                        (!makes?.length || makes.length === 0) &&
                                        <StyledTableRow hover>
                                            <StyledTableCell
                                                colSpan={headCells.length}
                                                align="center"
                                                style={{ borderBottom: 0 }}
                                                className="py-3"
                                            >
                                                <Typography variant="h6" color="textSecondary">
                                                    No Master Makes Available
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    {
                                        GetDisplayMakes().map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => SelectMake(row.id)}
                                                >
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Grid container spacing={2} direction="row" justify="center">
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                onClick={() => SelectMake(row.id)}
                                                            >
                                                                <OpenInBrowser />
                                                            </Button>
                                                        </Grid>
                                                        {/*
                                                        Removed for the time-being
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                onClick={() => EditMake(row.id)}
                                                            >
                                                                <Edit />
                                                            </Button>
                                                        </Grid>
                                                        */}
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                component="div"
                                count={makes?.length}
                                page={page}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageChange={HandleChangePage}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={HandleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Grid>
                }

            </Grid>
        </Paper>
    );
}