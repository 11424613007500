import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, Box, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns/build/index';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { ColorPicker } from 'material-ui-color';

const useStyles = makeStyles({
    root: {
        border: '1px solid red',
    },
    colorPicker: {
        marginBottom: '16px',
    },
});

export default function ButtonStyling({ initialValues, onSubmit }) {
    const history = useHistory();
    const [formFields, setFormFields] = useState({});

    useEffect(() => {
        setFormFields(initialValues);
    }, [initialValues]);

    const classes = useStyles();

    const buttonRadius = [
        { id: 1, name: 'Small', value: 10 },
        { id: 2, name: 'Medium', value: 15 },
        { id: 3, name: 'Large', value: 20 },
        { id: 4, name: 'X-Large', value: 25 },
        { id: 5, name: 'Full', value: 30 }
    ];

    return (
        <Grid container spacing={4} direction='column'>
            <Grid item xs={12}>
                <Formik initialValues={formFields} enableReinitialize={true} onSubmit={onSubmit}>
                    {(props) => {
                        const { values, touched, errors, handleBlur, isSubmitting, setFieldValue } = props;

                        const handleColorChange = (fieldName) => (color) => {
                            setFieldValue(fieldName, `#${color.hex}`);
                        };

                        return (
                            <Form>
                                <Grid container spacing={2} direction='column'>
                                    <Grid container direction='row'>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerPrimaryBg'>Primary Button BG:</label>
                                            <ColorPicker
                                                id='colorPickerPrimaryBg'
                                                className={classes.colorPicker}
                                                value={values.priButtonBg ?? formFields.priButtonBg}
                                                onChange={handleColorChange('priButtonBg')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerPrimaryBor'>Primary Button Border:</label>
                                            <ColorPicker
                                                id='colorPickerPrimaryBor'
                                                className={classes.colorPicker}
                                                value={values.priButtonBor ?? formFields.priButtonBor}
                                                onChange={handleColorChange('priButtonBor')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerPrimaryFont'>Primary Button Font:</label>
                                            <ColorPicker
                                                id='colorPickerPrimaryFont'
                                                className={classes.colorPicker}
                                                value={values.priButtonFont ?? formFields.priButtonFont}
                                                onChange={handleColorChange('priButtonFont')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerPrimaryOhBg'>Primary Button On-Hover BG:</label>
                                            <ColorPicker
                                                id='colorPickerPrimaryOhBg'
                                                className={classes.colorPicker}
                                                value={values.priButtonOhBg ?? formFields.priButtonOhBg}
                                                onChange={handleColorChange('priButtonOhBg')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerPrimaryOhBor'>Primary Button On-Hover Border:</label>
                                            <ColorPicker
                                                id='colorPickerPrimaryOhBor'
                                                className={classes.colorPicker}
                                                value={values.priButtonOhBor ?? formFields.priButtonOhBor}
                                                onChange={handleColorChange('priButtonOhBor')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerPrimaryOhFont'>Primary Button On-Hover Font:</label>
                                            <ColorPicker
                                                id='colorPickerPrimaryOhFont'
                                                className={classes.colorPicker}
                                                value={values.priButtonOhFont ?? formFields.priButtonOhFont}
                                                onChange={handleColorChange('priButtonOhFont')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerSecondaryBg'>Secondary Button BG:</label>
                                            <ColorPicker
                                                id='colorPickerSecondaryBg'
                                                className={classes.colorPicker}
                                                value={values.secButtonBg ?? formFields.secButtonBg}
                                                onChange={handleColorChange('secButtonBg')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerSecondaryBor'>Secondary Button Border:</label>
                                            <ColorPicker
                                                id='colorPickerSecondaryBor'
                                                className={classes.colorPicker}
                                                value={values.secButtonBor ?? formFields.secButtonBor}
                                                onChange={handleColorChange('secButtonBor')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerSecondaryFont'>Secondary Button Font:</label>
                                            <ColorPicker
                                                id='colorPickerSecondaryFont'
                                                className={classes.colorPicker}
                                                value={values.secButtonFont ?? formFields.secButtonFont}
                                                onChange={handleColorChange('secButtonFont')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerSecondaryOhBg'>Secondary Button On-Hover BG:</label>
                                            <ColorPicker
                                                id='colorPickerSecondaryOhBg'
                                                className={classes.colorPicker}
                                                value={values.secButtonOhBg ?? formFields.secButtonOhBg}
                                                onChange={handleColorChange('secButtonOhBg')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerSecondaryOhBor'>Secondary Button On-Hover Border:</label>
                                            <ColorPicker
                                                id='colorPickerSecondaryOhBor'
                                                className={classes.colorPicker}
                                                value={values.secButtonOhBor ?? formFields.secButtonOhBor}
                                                onChange={handleColorChange('secButtonOhBor')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerSecondaryOhFont'>Secondary Button On-Hover Font:</label>
                                            <ColorPicker
                                                id='colorPickerSecondaryOhFont'
                                                className={classes.colorPicker}
                                                value={values.secButtonOhFont ?? formFields.secButtonOhFont}
                                                onChange={handleColorChange('secButtonOhFont')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerMuteBg'>Mute Button BG:</label>
                                            <ColorPicker
                                                id='colorPickerMuteBg'
                                                className={classes.colorPicker}
                                                value={values.muButtonBg ?? formFields.muButtonBg}
                                                onChange={handleColorChange('muButtonBg')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerMuteBor'>Mute Button Border:</label>
                                            <ColorPicker
                                                id='colorPickerMuteBor'
                                                className={classes.colorPicker}
                                                value={values.muButtonBor ?? formFields.muButtonBor}
                                                onChange={handleColorChange('muButtonBor')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerMuteFont'>Mute Button Font:</label>
                                            <ColorPicker
                                                id='colorPickerMuteFont'
                                                className={classes.colorPicker}
                                                value={values.muButtonFont ?? formFields.muButtonFont}
                                                onChange={handleColorChange('muButtonFont')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerMuteOhBg'>Mute Button On-Hover BG:</label>
                                            <ColorPicker
                                                id='colorPickerMuteOhBg'
                                                className={classes.colorPicker}
                                                value={values.muButtonOhBg ?? formFields.muButtonOhBg}
                                                onChange={handleColorChange('muButtonOhBg')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerMuteOhBor'>Mute Button On-Hover Border:</label>
                                            <ColorPicker
                                                id='colorPickerMuteOhBor'
                                                className={classes.colorPicker}
                                                value={values.muButtonOhBor ?? formFields.muButtonOhBor}
                                                onChange={handleColorChange('muButtonOhBor')}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label htmlFor='colorPickerMuteOhFont'>Mute Button On-Hover Font:</label>
                                            <ColorPicker
                                                id='colorPickerMuteOhFont'
                                                className={classes.colorPicker}
                                                value={values.muButtonOhFont ?? formFields.muButtonOhFont}
                                                onChange={handleColorChange('muButtonOhFont')}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                id="buttonRad"
                                                label="Button Radius"
                                                value={
                                                    values.buttonRad || 
                                                    buttonRadius.find((o) => o.value === values.buttonRad)?.name || 
                                                    buttonRadius[0]?.value
                                                }
                                                select
                                                fullWidth
                                                helperText={errors.buttonRad && touched.buttonRad ? errors.buttonRad : ''}
                                                error={errors.buttonRad && touched.buttonRad}
                                                onBlur={handleBlur}
                                                onChange={(e) => setFieldValue('buttonRad', e.target.value)}
                                            >
                                                {buttonRadius.map((option) => (
                                                    <MenuItem key={option.id} value={option.value}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item container justify='flex-end' xs={12}>
                                    <Box mr={3}>
                                        <Button onClick={() => history.push('/templates')}>Cancel</Button>
                                    </Box>
                                    <Button type='submit' variant='contained' color='primary'>
                                        Next
                                    </Button>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </Grid>
    );
}
