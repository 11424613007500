import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Grid from '@material-ui/core/Grid';
import RangeListContainer from './range/RangeListContainer'
import Paper from '@material-ui/core/Paper';
import DerivativeListContainer from './derivative/DerivativeListContainer'
import ColourTabs from './colour/ColourTabs'
import AccessoryListContainer from './accessories/AccessoryListContainer'
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    completed: {
        display: 'inline-block',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

function getSteps() {
    return ['Models', 'Variants', 'Colours', 'Accessories'];
}

export default function DASHContainer() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    let history = useHistory();
    const { step, modelId, dealerModelId, dealerVariantId } = useParams();
    const [editingVariantId, setEditingVariantId] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const [skipped, setSkipped] = useState(new Set());
    const steps = getSteps();

    useEffect(() => {
        if (step) {
            switch (step) {
                case 'models':
                    return setActiveStep(0);
                case 'variants':
                    return setActiveStep(1);
                case 'colours':
                    return setActiveStep(2);
                case 'accessories':
                    return setActiveStep(3);
                default:
                    return setActiveStep(0);
            }
        } else {
            setActiveStep(0)
        }
    }, [step])

    const selectDealerVariant = (id) => {
      setEditingVariantId(id);
    };

    const validateStep = (route, modelId, dealerModelId, dealerVariantId) => {

        switch (route) {
            case 'models': {
                // No validation necessary
                return "";
            }
            case 'variants': {
                // Need the modelId & the dealerModelId
                if (!modelId || !dealerModelId) {
                    return "Cannot proceed to this step: no model selected";
                } else {
                    return "";
                }
            }
            case 'colours':
            case 'accessories': {
                // Need the modelId, dealerModelId and the dealerVariantId
                if (!modelId || !dealerModelId || !dealerVariantId) {
                    return "Cannot proceed to this step: no variant selected";
                } else {
                    return "";
                }
            }
        }
    };

    const redirectRoute = (route, modelId, dealerModelId, dealerVariantId) => {
        switch (route) {
            case 'models': return `/DASH/${route}`;
            case 'variants': return `/DASH/${route}/${modelId}/${dealerModelId}`;
            case 'colours': return `/DASH/${route}/${modelId}/${dealerModelId}/${dealerVariantId}`;
            case 'accessories': return `/DASH/${route}/${modelId}/${dealerModelId}/${dealerVariantId}`;
            default: return `/DASH`;
        }
    };

    const handleNext = (route, modelId, dealerModelId, dealerVariantId) => {

        var invalidMessage = validateStep(route, modelId, dealerModelId, dealerVariantId);

        if (invalidMessage.length > 0) {
            enqueueSnackbar(invalidMessage, { variant: 'warning', vertical: 'top', horizontal: 'center' });
            return;
        }

        setEditingVariantId(null);

        return history.push(redirectRoute(route, modelId, dealerModelId, dealerVariantId));

    };

    function getStepContent() {

        if (step && step.toLowerCase() != steps[activeStep].toLowerCase())
            return;

        switch (activeStep) {
            case 0:
                return <RangeListContainer navigate={handleNext} />;
            case 1:
                return <DerivativeListContainer navigate={handleNext} selectVariant={selectDealerVariant} modelId={modelId} dealerModelId={dealerModelId} />;
            case 2:
                return <ColourTabs navigate={handleNext} modelId={modelId} dealerModelId={dealerModelId} dealerVariantId={dealerVariantId} />;
            case 3:
                return <AccessoryListContainer navigate={handleNext} modelId={modelId} dealerModelId={dealerModelId} dealerVariantId={dealerVariantId} />;
            default:
                return 'Unknown step';
        }
    }

    const handleStep = (step) => () => {

        var route = steps[step].toLowerCase();

        handleNext(route, modelId, dealerModelId, dealerVariantId || editingVariantId);

    }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel nonLinear
                style={{ backgroundColor: "transparent" }}
                activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepButton
                                onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <Paper className={classes.paper}>
                <Grid container spacing={4} className={classes.stepPadding} direction="column">
                    <Grid item xs={12}>
                        {getStepContent()}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}