import React, { useState, useContext } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import {
  Grid,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Fab
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import ImageUploadInput from '../../../shared/ImageUploadFormInput';
import { LoadingContext } from '../../../shared/context/loadingContext';
import { DealerContext } from '../../../shared/DealerContext';
import { useHistory, useParams } from 'react-router-dom';

const initialValues = {
  banner: false,
  welcomeHeadline: false,
  welcomeParagraph: false,
  scrollText: false,
  ctas: false,
  latestUsedVehicles: false,
  whyBuyFromUs: false,
  newVehicleHighlight: false,
  includePage: false,
  pageTitle: '',
  pageDescription: '',
  url: '',
  reviews: false,
  htmlModule1:false,
  htmlModule2:false,
  ctaType: '',
  scrollType: '',
  // Additional fields for multiline text areas
  welcomeHeadlineText: '',
  welcomeParagraphText: '',
  title: '',
  description: '',
  urlText: '',
  scrollTextText: '',
  ctasText: '',
  whyBuyFromUsText: '',
  latestUsedVehiclesText: '',
  links: [],
  htmlModule1Text:'',
  htmlModule2Text:'',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  dynamicComponentCell: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const HomePageTemplate = (props) => {
  const { page } = props;
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedMotorGroup } = useContext(DealerContext);
  const parsedObj = page?.templateConfig ? JSON.parse(page.templateConfig) : initialValues;
  let history = useHistory();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const ctaTypesList = [
    { id: 1, name: 'Button' },
    { id: 2, name: 'Images' }
  ];

  const scrollTypesList = [
    { id: 1, name: 'Scroll' },
    { id: 2, name: 'List' },
    { id: 3, name: 'Category List' }
  ];

  const onSubmitHomePage = async (fields) => {
    try {
      showLoading();
      if (fields.ctaType && fields.ctaType.toLowerCase() === 'images') {
        await processLinkImages(fields);
      }

      fields.bobPayload = transformToBobPayload(fields);

      if (selectedMotorGroup.id) {
        if (page != undefined || page != null) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;
          axios
            .put(`${BASE_URI}/WebsitePages/${pageId}`, {
              ...page,
              id: pageId
            })
            .then((result) => {
              enqueueSnackbar('Updated home page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            pageCategoryId: 1
          };
          axios
            .post(`${BASE_URI}/WebsitePages`, {
              ...newPage
            })
            .then((result) => {
              enqueueSnackbar('created home page', { variant: 'success' });
              hideLoading();
              history.push('/templates');
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
        hideLoading();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Operation failed', { variant: 'error' });
      hideLoading();
    }
  };

  const isBase64 = (str) => {
    const base64Pattern = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/i;
    return base64Pattern.test(str);
  };

  const transformToBobPayload = (values) => {
    const layoutMap = new Map();
  
    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params = [...new Map([...existing.params, ...params].map(item => [item.key, item])).values()];
        }
      }
    };
  
    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }
  
      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];
  
      if (field === 'ctas' && values.ctas) {
        // Handle CTA images
        if (values.ctaType && values.ctaType.toLowerCase() === 'images' && values.links) {
          values.links.forEach((link, index) => {
            if (link.image && link.image.image) {
              params.push({ key: `ctaImage${index + 1}`, value: link.image.image });
            }
            params.push({ key: `ctaLink${index + 1}`, value: link.link });
            params.push({ key: `ctaName${index + 1}`, value: link.name });
          });
        }
        if (values.ctasText) {
          params.push({ key: 'ctasText', value: values.ctasText });
        }
        addToLayoutMap('ctas', order, includes, params);
      } else if (!field.endsWith('Text') && !field.endsWith('Order')) {
        if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }
    });
  
    const layout = Array.from(layoutMap.values());
  
    return {
      layout,
      page: page?.title || ''
    };
  };

  const processLinkImages = async (fields) => {
    try {
      let imageInput = [];
      // Iterate over each link object in fields.links
      fields.links.forEach((link) => {
        let linkImg = link.image;
        if (linkImg && isBase64(linkImg.image)) {
          imageInput.push(linkImg);
        }
      });

      if (imageInput.length > 0) {
        const res = await axios.put(`${BASE_URI}/Bob/uploadImage`, imageInput);
        let imgUrls = res.data;

        imgUrls.forEach((url) => {
          fields.links.forEach((link) => {
            if (link.image && url.toLowerCase().includes(link.image.fileName.toLowerCase())) {
              link.image.image = url;
            }
          });
        });
      }
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
    }
  };

  const handleSelectChange = (e, setFieldValue, fieldName) => {
    setFieldValue(fieldName, e.target.value);
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant='h6' component='div'>
          Home Page Display Options
        </Typography>
      </Toolbar>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Formik
            initialValues={parsedObj}
            onSubmit={(values) => {
              onSubmitHomePage(values);
            }}
          >
            {({ touched, values, errors, handleBlur, handleChange, setFieldValue, isSubmitting }) => {
              const handleChangeCustom = (fieldName, checked) => {
                setFieldValue(fieldName, checked);
              };
              return (
                <Form>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Include</TableCell>
                          <TableCell>Content</TableCell>
                          <TableCell>Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Include Page</TableCell>
                          <TableCell>Boolean</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.includePage}
                                  onChange={(e) => handleChangeCustom('includePage', e.target.checked)}
                                  name='includePage'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='title'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='description'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>URL Link</TableCell>
                          <TableCell>Link</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='urlText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Banner</TableCell>
                          <TableCell>Slider</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.banner}
                                  onChange={(e) => handleChangeCustom('banner', e.target.checked)}
                                  name='banner'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.banner && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='bannerText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='bannerOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Welcome Headline</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.welcomeHeadline}
                                  onChange={(e) => handleChangeCustom('welcomeHeadline', e.target.checked)}
                                  name='welcomeHeadline'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.welcomeHeadline && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='welcomeHeadlineText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='welcomeTextOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Welcome Paragraph</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.welcomeParagraph}
                                  onChange={(e) => handleChangeCustom('welcomeParagraph', e.target.checked)}
                                  name='welcomeParagraph'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.welcomeParagraph && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='welcomeParagraphText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='welcomeParagraphOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Scroll Text</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.scrollText}
                                  onChange={(e) => handleChangeCustom('scrollText', e.target.checked)}
                                  name='scrollText'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.scrollText && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='scrollTextText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='scrollTextOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>CTA's</TableCell>
                          <TableCell>
                            <TextField
                              id='ctaType'
                              name='ctaType'
                              label='CTA'
                              value={values.ctaType}
                              select
                              fullWidth
                              helperText={errors.ctaType && touched.ctaType ? errors.ctaType : ''}
                              error={errors.ctaType && touched.ctaType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'ctaType')}
                            >
                              {ctaTypesList.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch checked={values.ctas} onChange={(e) => handleChangeCustom('ctas', e.target.checked)} name='ctas' />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.ctas && (
                              <div className={classes.dynamicComponentCell}>
                                <Grid item xs={12}>
                                  {
                                    <FieldArray
                                      name='links'
                                      render={(arrayHelpers) => {
                                        return (
                                          <>
                                            <Grid item xs={12}>
                                              <Grid item style={{ padding: 0 }} xs={12}>
                                                <Typography variant='subtitle2'>Links:</Typography>
                                                {touched.links && errors.links && <p className={classes.errorText}>{errors.links}</p>}
                                              </Grid>
                                            </Grid>
                                            {values.links && values.links.length > 0 ? (
                                              values.links.map((link, index) => {
                                                return (
                                                  <div className={classes.featureInputContainer} key={index}>
                                                    <Field name={`links.${index}.name`} label='Name' component={TextFieldFormik} />
                                                    {values.ctaType && values.ctaType.toLowerCase() !== 'images' ? (
                                                      <Field
                                                        name={`links.${index}.link`}
                                                        label='Link'
                                                        style={{ marginLeft: '1rem' }}
                                                        component={TextFieldFormik}
                                                      />
                                                    ) : (
                                                      <>
                                                      <Field
                                                        name={`links.${index}.link`}
                                                        label='Link'
                                                        style={{ marginLeft: '1rem' }}
                                                        component={TextFieldFormik}
                                                      />
                                                      <ImageUploadInput title='CTA Image' required fieldName={`links.${index}.image`} />
                                                      </>
                                                    )}

                                                    <IconButton aria-label={`Delete link`} onClick={() => arrayHelpers.remove(index)}>
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </div>
                                                );
                                              })
                                            ) : (
                                              <Grid item xs={12}>
                                                <Typography variant='subtitle1'>No links added</Typography>
                                              </Grid>
                                            )}
                                            <Fab
                                              variant='extended'
                                              size='small'
                                              color='primary'
                                              aria-label='add'
                                              disabled={isSubmitting}
                                              onClick={() =>
                                                arrayHelpers.insert(0, {
                                                  name: '',
                                                  link: '',
                                                  image: ''
                                                })
                                              }
                                            >
                                              <AddIcon />
                                              Add Link
                                            </Fab>
                                          </>
                                        );
                                      }}
                                    />
                                  }
                                </Grid>
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='ctasOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>New Vehicle Highlight</TableCell>
                          <TableCell>
                            <TextField
                              id='scrollType'
                              label='Scroll Type'
                              value={values.scrollType}
                              select
                              fullWidth
                              helperText={errors.scrollType && touched.scrollType ? errors.scrollType : ''}
                              error={errors.scrollType && touched.scrollType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'scrollType')}
                            >
                              {scrollTypesList.map((option) => (
                                <MenuItem key={option.id} id={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.newVehicleHighlight}
                                  onChange={(e) => handleChangeCustom('newVehicleHighlight', e.target.checked)}
                                  name='newVehicleHighlight'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.newVehicleHighlight && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='newVehicleHighlightText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='newVehicleHighlightOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Latest Used Vehicles</TableCell>
                          <TableCell>Scroll</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.latestUsedVehicles}
                                  onChange={(e) => handleChangeCustom('latestUsedVehicles', e.target.checked)}
                                  name='latestUsedVehicles'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.latestUsedVehicles && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='latestUsedVehiclesText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='latestUsedVehiclesOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Why Buy From Us</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.whyBuyFromUs}
                                  onChange={(e) => handleChangeCustom('whyBuyFromUs', e.target.checked)}
                                  name='whyBuyFromUs'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.whyBuyFromUs && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='whyBuyFromUsText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='whyBuyFromUsOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 1</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule1}
                                  onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                  name='htmlModule1'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule1 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule1Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule1Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 2</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule2}
                                  onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                  name='htmlModule2'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule2 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule2Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule2Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Button variant='contained' color='primary' type='submit'>
                      Save Changes
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HomePageTemplate;
