import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { BASE_URI, DEFAULT_ERROR_MESSAGE } from '../../shared/Constants';
import { AuthContext } from '../../shared/context/AuthContext';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';
import DashLogo from '../../shared/assets/DashLogo.png';
import config from '../../config';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://reachengagedevelop.com/'>
        Reach Engage Develop
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const validationSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(10,88,202, 0.8)'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '8px',
    maxWidth: '400px',
    width: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    color: '#3f51b5 !important',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  }
}));

export default function LoginComponent() {
  const classes = useStyles();
  const { login, setSession } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [isLogin, setIsLogin] = React.useState(true);

  const loginUser = (values) => {
    showLoading();
    login(values.username, values.password)
      .then((response) => {
        hideLoading();
        let res = response.data;
        if (!res.isAuthenticated || !res.token) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        let dealerIDs = res.dealers.map((d) => d.dealerId);
        setSession(values.username, response.data.token, response.data.refreshToken, dealerIDs, response.data.permissions, response.data.name);
        enqueueSnackbar('Logged In', { variant: 'success' });
      })
      .catch(() => {
        hideLoading();
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
      });
  };

  const resetPasswordRequest = (values) => {
    showLoading();
    axios
      .get(`${BASE_URI}/User/ResetPassword/${values.username}`)
      .then(() => {
        hideLoading();
        enqueueSnackbar('Password request sent, please check email.', { variant: 'success' });
        setIsLogin(true);
      })
      .catch((error) => {
        hideLoading();
        if (error?.response?.data) {
          let errorMsg = (error.response.data.title ? `${error.response.data.title}. ` : '') + (error.response.data.detail ?? error);

          enqueueSnackbar(errorMsg, { variant: 'error' });
        } else {
          enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
        }
      });
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={DashLogo} alt='Logo' width={'300px'} height={'50px'} />
        <Formik
          initialValues={{ username: '', password: '' }}
          enableReinitialize={true}
          validationSchema={
            isLogin
              ? validationSchema
              : Yup.object().shape({ username: Yup.string().email('Invalid email address').required('Email is required') })
          }
          onSubmit={(values) => (isLogin ? loginUser(values) : resetPasswordRequest(values))}
        >
          {({ values, touched, errors, handleBlur, handleChange, handleReset }) => (
            <Form className={classes.form}>
              <TextField
                fullWidth
                margin='normal'
                id='username'
                name='username'
                label='Email Address'
                helperText={errors.username && touched.username ? errors.username : ''}
                error={errors.username && touched.username}
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{marginBottom:'4px', marginTop:'8px'}}
              />
              {!isLogin && (
                <Button fullWidth className={classes.submit} type='submit' variant='contained' color='primary'>
                  Reset Password
                </Button>
              )}
              {isLogin && (
                <>
                  <TextField
                    fullWidth
                    margin='normal'
                    type='password'
                    id='password'
                    name='password'
                    label='Password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.password && touched.password ? errors.password : ''}
                    error={errors.password && touched.password}
                    style={{marginBottom:'4px', marginTop:'8px'}}
                  />
                  <Button fullWidth className={classes.submit} type='submit' variant='contained' color='primary'>
                    Login
                  </Button>
                </>
              )}
              <Grid container>
                <Grid item xs={6}>
                  <Link
                    className={classes.link}
                    style={{ display: isLogin ? 'block' : 'none' }}
                    onClick={() => {
                      setIsLogin(false);
                      handleReset();
                    }}
                    variant='body2'
                  >
                    Forgot Password?
                  </Link>
                  <Link
                    className={classes.link}
                    style={{ display: !isLogin ? 'block' : 'none' }}
                    onClick={() => {
                      setIsLogin(true);
                      handleReset();
                    }}
                    variant='body2'
                  >
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <Box mt={2}>
          <Copyright />
          <p style={{ textAlign: 'center' }}> Version: v{config.buildNumber}</p>
        </Box>
      </div>
    </Grid>
  );
}
