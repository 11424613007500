import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Divider,
    Paper,
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI } from '../../shared/Constants'
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    title: {
        background: '#bdbfc1',
        color: 'white',
        padding: "5px 30px",

        width: "fit-content",
        borderBottom: '2px red solid',

    },
    tr: {
        padding: "5px 30px",
    },
    td: {
        padding: "5px 10px",
    },

    btn: {
        color: "#fff",
        background: '#3f51b5',
        padding: "8px 20px",
        border: "none",
        margin: '5px',
        borderRadius: '3px'
    }
}));

export default function DealerSocialMedia({ history, props }) {

    const initialValues = {
        twitter: '',
        facebook: '',
        instagram: '',
        linkedIn: '',
        facebookPixelCode: '',
        liveChatLicenseKey: '',
        googleSiteVerificationCode: '',
        googleUniversalAnalyticsCode: '',
        googleAdwordsCode: '',
        otherSnippets: '',
    }

    const { id } = useParams();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [formFields, setFormFields] = useState(initialValues);
    const classes = useStyles();

    function onSubmit(fields) {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            if (formFields.id) {
                axios.put(
                    `${BASE_URI}/dealerSocialMedia/${formFields.id}`,
                    {
                        ...fields,
                        id: formFields.id,
                        dealerId: parseInt(id)
                    },
                    {
                        cancelToken: source.token,
                    }
                ).then(result => {
                    enqueueSnackbar("Updated Social Media", { variant: 'success' });
                });

            } else {
                axios.post(
                    `${BASE_URI}/dealerSocialMedia`,
                    {
                        ...fields,
                        dealerId: parseInt(id)
                    },
                    {
                        cancelToken: source.token,
                    }
                ).then(result => {
                    enqueueSnackbar("Updated Social Media", { variant: 'success' });
                });
            }

        } catch (error) {
            enqueueSnackbar("Updated failed", { variant: 'error' });
        }
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getDealerSocialMedia = async () => {
            try {
                if(id){
                    const result = await axios.get(`${BASE_URI}/dealerSocialMedia/${id}`, {
                        cancelToken: source.token,
                    });

                    if (result.data) {
                        setFormFields(result.data);
                    }
                }
            } catch (error) {
                // addAlert('Unables to load vehicle categories')
                //history.push('..')
            }
        };

        getDealerSocialMedia();

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paper className={classes.paper}>
            <Formik
                initialValues={formFields}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(props) => {
                    const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;
                    return (
                        <Form>
                            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                                <Grid item xs={12} md={6}>
                                    <Grid item xs={12}>

                                        <Typography variant="h6" gutterBottom>
                                            SOCIAL MEDIA LINKS
                                        </Typography>
                                    </Grid>
                                    <TextField
                                        fullWidth
                                        name="twitter"
                                        id="twitter"
                                        label="Twitter"
                                        value={values.twitter}
                                        helperText={errors.twitter && touched.twitter ? errors.twitter : ""}
                                        error={errors.twitter && touched.twitter}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <TextField
                                        fullWidth
                                        name="facebook"
                                        id="facebook"
                                        label="Facebook"
                                        value={values.facebook}
                                        helperText={errors.facebook && touched.facebook ? errors.facebook : ""}
                                        error={errors.facebook && touched.facebook}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <TextField
                                        fullWidth
                                        name="instagram"
                                        id="instagram"
                                        label="Instagram"
                                        value={values.instagram}
                                        helperText={errors.instagram && touched.instagram ? errors.instagram : ""}
                                        error={errors.instagram && touched.instagram}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <TextField
                                        fullWidth
                                        name="linkedIn"
                                        id="linkedIn"
                                        label="Linked In"
                                        value={values.linkedIn}
                                        helperText={errors.linkedIn && touched.linkedIn ? errors.linkedIn : ""}
                                        error={errors.linkedIn && touched.linkedIn}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Typography variant="h6" gutterBottom>
                                        FACEBOOK
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        name="facebookPixelCode"
                                        id="facebookPixelCode"
                                        label="Facebook Pixel Code"
                                        value={values.facebookPixelCode}
                                        helperText={errors.facebookPixelCode && touched.facebookPixelCode ? errors.facebookPixelCode : ""}
                                        error={errors.facebookPixelCode && touched.facebookPixelCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Typography variant="h6" gutterBottom>
                                        GOOGLE
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="googleSiteVerificationCode"
                                        id="googleSiteVerificationCode"
                                        label="Site Verification"
                                        value={values.googleSiteVerificationCode}
                                        helperText={errors.googleSiteVerificationCode && touched.googleSiteVerificationCode ? errors.googleSiteVerificationCode : ""}
                                        error={errors.googleSiteVerificationCode && touched.googleSiteVerificationCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <TextField
                                        fullWidth
                                        name="googleUniversalAnalyticsCode"
                                        id="googleUniversalAnalyticsCode"
                                        label="Universal Analytics"
                                        value={values.googleUniversalAnalyticsCode}
                                        helperText={errors.googleUniversalAnalyticsCode && touched.googleUniversalAnalyticsCode ? errors.googleUniversalAnalyticsCode : ""}
                                        error={errors.googleUniversalAnalyticsCode && touched.googleUniversalAnalyticsCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <TextField
                                        fullWidth
                                        name="googleAdwordsCode"
                                        id="googleAdwordsCode"
                                        label="Adwords"
                                        value={values.googleAdwordsCode}
                                        helperText={errors.googleAdwordsCode && touched.googleAdwordsCode ? errors.googleAdwordsCode : ""}
                                        error={errors.googleAdwordsCode && touched.googleAdwordsCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Typography variant="h6" gutterBottom>
                                        OTHER SCRIPTS
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="otherSnippets"
                                        id="otherSnippets"
                                        label="Other Snippets"
                                        value={values.otherSnippets}
                                        helperText={errors.otherSnippets && touched.otherSnippets ? errors.otherSnippets : ""}
                                        error={errors.otherSnippets && touched.otherSnippets}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <br />
                                    <br />
                                </Grid>
                                <Grid item container
                                    justify='flex-end' xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }
                }
            </Formik>
        </Paper>
    )
}