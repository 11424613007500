import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { DealerContext } from '../../shared/DealerContext';
import { LoadingContext } from '../../shared/context/loadingContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Configuration({ initialValues, onSubmit, isAddMode }) {
  const history = useHistory();
  const [formFields, setFormFields] = useState({});
  const [relatedDealerIDs, setRelatedDealerIDs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading } = useContext(LoadingContext);
  const { dealerArr } = useContext(DealerContext);
  const classes = useStyles();

  useEffect(() => {
    setFormFields(initialValues);
    setRelatedDealerIDs(initialValues?.relatedDealerIDs || []);
  }, [initialValues]);

  return (
    <Grid container spacing={4} direction="column">
      <Grid item xs={12}>
        <Formik initialValues={formFields} onSubmit={onSubmit}>
          {(props) => {
            const { values, handleBlur, handleChange, isSubmitting } = props;
            values.relatedDealerIDs = relatedDealerIDs;

            return (
              <Form>
                <Grid container spacing={4} direction="row">
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="name"
                      id="name"
                      label="Template name"
                      value={values.name || formFields?.name}
                      type="text"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" gutterBottom>
                      Select the dealers to link to this template
                    </Typography>
                    <Autocomplete
                      multiple
                      id="relatedDealers"
                      options={dealerArr}
                      getOptionLabel={(option) => option.name}
                      value={dealerArr.filter((dealer) => relatedDealerIDs.includes(dealer.id))}
                      onChange={(event, newValue) => {
                        setRelatedDealerIDs(newValue.map((dealer) => dealer.id));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select Dealers"
                        />
                      )}
                      disabled={isSubmitting || isLoading}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container justify="flex-end" spacing={3}>
                  <Grid item>
                    <Button onClick={() => history.push('/templates')}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || isLoading}>
                      {isSubmitting || isLoading ? <CircularProgress size={24} /> : isAddMode ? 'Add' : 'Update'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
