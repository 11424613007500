import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import TemplateInfo from "./TemplateInfo";
import { BASE_URI } from "../../shared/Constants";
import { DealerContext } from "../../shared/DealerContext";
import HomePageTemplate from "./TemplatePages/HomePageTemplate";
import NewVLP from "./TemplatePages/NewVLP";
import UsedVLP from "./TemplatePages/UsedVLP";
import UsedVDP from "./TemplatePages/UsedVDP";
import ServicePage from "./TemplatePages/Service";
import PartsPage from "./TemplatePages/Parts";
import FinancePage from "./TemplatePages/Finance";
import ContactUsPage from "./TemplatePages/ContactUs";
import BasePage from "./TemplatePages/BasePage";
import WebsitePages from "../websites/WebsitePages";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3378af",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export default function TemplateContainer() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [pages, setPages] = useState([]);
    const { selectedMotorGroup } = useContext(DealerContext);

    useEffect(() => {
        const params = {
            dealerId: selectedMotorGroup?.id,
        };
        axios
            .get(`${BASE_URI}/WebsitePages`, { params })
            .then((response) => {
                setPages(response.data);
            })
            .catch((error) => {
                console.error("Error fetching tab names:", error);
            });
    }, [selectedMotorGroup.id]);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="relative" className={classes.root}>
                <Tabs
                    variant="scrollable"
                    value={selectedTab != null ? selectedTab : setSelectedTab(0)}
                    onChange={handleChange}
                    textColor="inherit"
                >
                    <Tab label="Template Info" />
                    <Tab label="Home Page" />
                    <Tab label="New Vehicles" />
                    <Tab label="Used Vehicles" />
                    <Tab label="Used Vehicle Details" />
                    <Tab label="Service" />
                    <Tab label="Parts" />
                    <Tab label="Finance" />
                    <Tab label="Contact Us" />
                    <Tab label="Base Page" />
                    <Tab label="Custom Pages" />
                </Tabs>
            </AppBar>

            {selectedTab === 0 && <TemplateInfo />}
            {selectedTab === 1 && <HomePageTemplate page={pages.find(page => page.pageCategoryId === 1)} />}
            {selectedTab === 2 && <NewVLP page={pages.find(page => page.pageCategoryId === 2)} />}
            {selectedTab === 3 && <UsedVLP page={pages.find(page => page.pageCategoryId === 3)} />}
            {selectedTab === 4 && <UsedVDP page={pages.find(page => page.pageCategoryId === 6)} />}
            {selectedTab === 5 && <ServicePage page={pages.find(page => page.pageCategoryId === 9)} />}
            {selectedTab === 6 && <PartsPage page={pages.find(page => page.pageCategoryId === 8)} />}
            {selectedTab === 7 && <FinancePage page={pages.find(page => page.pageCategoryId === 10)} />}
            {selectedTab === 8 && <ContactUsPage page={pages.find(page => page.pageCategoryId === 7)} />}
            {selectedTab === 9 && <BasePage page={pages.find(page => page.pageCategoryId === 0)} /> }
            {selectedTab === 10 && <WebsitePages/> }
        </>
    );
}
