import React, {useEffect, useState} from 'react';
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  Box
} from '@material-ui/core'
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup'
import {CurrencyInput} from '../../../shared/TextMask'
import Typography from '@material-ui/core/Typography';
import ImageUploadInput from '../../../shared/ImageUploadFormInput'
import { TextField as TextFieldFormik } from "formik-material-ui";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles({
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

export default function AccessoryForm({onSubmit, data, cancel, actionType}) {
  const classes = useStyles();
  const initialValues = {
    title: '',
    price: '',
    features: [],
    altTag:''
  };
  const [values, setValues] = useState(data ? data: initialValues)
  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers
  }

  useEffect(() => {
    if(data) {
      setValues(data)
    }
  },[data])

  return(
    <Formik
      initialValues={values}
      onSubmit={(values, actions) => {
        for (let i = 0; i < values.features.length; i++) {
          const feature = values.features[i]?.image;
          if ((actionType === 'addAccessory' || (actionType === 'editAccessory' && feature?.image))) {
            values.features[i].image = feature?.image;
            values.features[i].fileName = feature?.fileName;
          }
        }

        onSubmit(values, () => {
          actions.resetForm();
          actions.setSubmitting(false);
        });
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('Enter a title for the Accessory Pack'),
        price: Yup.string().required('Please enter a valid amount'),
        features: Yup.array().min(1, 'Atleast one feature is required'),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          handleReset
        } = props
        return (
          <Form>
            <Typography variant="h6" gutterBottom>
              { data ? 'Edit': 'Create'} Accessory Pack
            </Typography>
            <Grid
              spacing={4}
              container
            >
              <Grid item xs={12} md={4}>
                <TextField
                  name="title"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  value={values.title}
                  type="text"
                  helperText={errors.title && touched.title ? errors.title: ''}
                  error={errors.title && touched.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  id="price"
                  label="Price"
                  style={{marginLeft: '2rem'}}
                  value={values.price}
                  onChange={handleChange}
                  placeholder={'eg. 200 000'}
                  error={ errors.price && touched.price}
                  helperText={errors.price && touched.price ? errors.price: ''}
                  InputProps={{
                    inputComponent: CurrencyInput
                  }}
                />
              </Grid>
              <Grid item container justify="flex-end" md={4}>
                <Fab variant="extended" size="small"
                     color="primary" aria-label="add"
                     disabled={isSubmitting}
                     onClick={() => boundArrayHelpers.insert(0,{
                       name: '',
                       image: '',
                       altTag:''
                     })}>
                  <AddIcon />
                  Add Feature
                </Fab>
              </Grid>
              <Grid item xs={12} style={{paddingTop: 0}}>
                {
                  <FieldArray
                    name="features"
                    render={arrayHelpers =>
                    {
                      bindArrayHelpers(arrayHelpers)
                      return (
                        <Grid container spacing={2} direction="row">
                          <Grid item xs={12}>
                            <Grid item
                                  style={{padding: 0}}
                                  xs={12}>
                              <Typography variant="subtitle2">
                                Features:
                              </Typography>
                              {( touched.features && errors.features) && <p className={classes.errorText}>{errors.features}</p>}
                              {/*<IconButton*/}
                              {/*onClick={() => arrayHelpers.insert(0,'')}*/}
                              {/*aria-label={`Add feature`}>*/}
                              {/*<AddIcon />*/}
                              {/*</IconButton>*/}
                              {/*<Button variant="contained"*/}
                                      {/*color="primary"*/}
                                      {/*style={{marginLeft: '2rem'}}*/}
                                      {/*onClick={() => arrayHelpers.insert(0,{*/}
                                        {/*name: '',*/}
                                        {/*img: ''*/}
                                      {/*})}>*/}
                                {/*Add Feature*/}
                              {/*</Button>*/}
                            </Grid>
                          </Grid>
                          {values.features && values.features.length > 0 ? (
                            values.features.map((feature, index) => {
                              return (
                                <Grid item xs={12} key={index} md={4} container direction="row">
                                  <Grid item xs={12} >
                                    <div className={classes.featureInputContainer}>
                                      <Field name={`features.${index}.name`}
                                             label="New Feature"
                                             component={TextFieldFormik} />
                                      <IconButton aria-label={`Delete feature`}
                                                  onClick={() => arrayHelpers.remove(index)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                    <ImageUploadInput title='Feature preview Image'
                                          fieldName={`features.${index}.image`}
                                          error={errors.image && touched.image}/>
                                          <Field name={`features.${index}.altTag`}
                                             label="Alt Tag"
                                             component={TextFieldFormik} />
                                  </Grid>
                                </Grid>
                              )
                            })
                          ) : (
                            <Grid item xs={12}>
                              <Typography variant="subtitle1">
                                No features added
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )
                    }}
                  />
                }
              </Grid>
              <Grid item container
                    justify='flex-end' xs={12}>
                <Box mr={3}>
                  <Button
                    onClick={() => {
                      handleReset()
                      cancel()
                    }}>
                    Cancel
                  </Button>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}