import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { BASE_URI } from '../../shared/Constants';
import axios from 'axios';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    tableHeader: {
        borderBottom: '2px solid darkgray',
    },
}));

export default function StatePermissionsMatrix() {
    const classes = useStyles();
    let history = useHistory();
    const mountedRef = useRef(true);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [roleArr, setRoleArr] = useState([]);
    const [formFields, setFormFields] = useState({});

    useEffect(() => {
        setLoading(true);

        const getState = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/Admin/permissions`);
                return result.data;
            } catch (error) {
                setLoading(false);
                enqueueSnackbar("Unable to retrieve states and permissions", { variant: 'error' });
            }
        };

        const getRoles = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/Admin/roles`);
                setRoleArr(result.data);
                return result.data;
            } catch (error) {
                setLoading(false);
                enqueueSnackbar("Unable to retrieve roles", { variant: 'error' });
            }
        };

        getState().then((permissions) => {
            getRoles().then((roles) => {
                setLoading(false);
                setFormFields({ permissions: buildRequiredPermissionsArr(permissions, roles) });
                if (!mountedRef.current) return null;
            });
            if (!mountedRef.current) return null;
        });
        return () => {
            mountedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateMatrix = async (fields, setSubmitting) => {
        try {
            const result = await axios.put(`${BASE_URI}/Admin/permissions`, fields);
            enqueueSnackbar('Successfully updated State Permissions', { variant: 'success' });
            return result;
        } catch (error) {
            enqueueSnackbar('Unable to update State Permissions', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        let exportArr = exportPermissionFormArray(fields.permissions);
        updateMatrix(exportArr, setSubmitting);
    }

    const buildRequiredPermissionsArr = (stateFieldArr, roleFieldArr) => {
        let requiredPermissionsFormArray = stateFieldArr.map((state) => {
            let permission = {
                name: state.name,
                dashSectionId: state.dashSectionId,  
                requiredRoles: [],
            };

            roleFieldArr.forEach((item) => {
                permission.requiredRoles.push({
                    id: item.id,
                    required: state.requiredRoles.includes(item.id),
                });
            });

            return permission;
        });
        return requiredPermissionsFormArray;
    };

    const exportPermissionFormArray = (permissionArr) => {
        const exportArr = permissionArr.map((state) => {
            const tmpRequiredPermissionsArr = state.requiredRoles.filter((permission) => permission.required);
            const obj = {
                name: state.name,
                dashSectionId: state.dashSectionId,  
                requiredRoles: tmpRequiredPermissionsArr.map((item) => item.id),
            };
            return obj;
        });

        return exportArr;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={4} className={classes.stepPadding} direction="column">
                    <Grid item xs={12}>
                        <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
                            <Typography style={{ flex: '1 1' }} variant="h4" id="tableTitle" component="div">
                                State Permissions
                            </Typography>
                        </Toolbar>
                        {
                            loading &&
                            <Grid item container justify="center" alignItems="center">
                                <div>
                                    <CircularProgress />
                                </div>
                            </Grid>
                        }
                        {
                            (!loading && roleArr.length <= 0) &&
                            <Grid item container justify="center" alignItems="center">
                                <div>
                                    <Typography variant="h6" color="textSecondary">
                                        No state permissions available
                                    </Typography>
                                </div>
                            </Grid>
                        }
                        {
                            (!loading && roleArr.length > 0) &&
                            <Formik
                                initialValues={formFields}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                            >
                                {(props) => {
                                    const { values, isSubmitting } = props;

                                    return (
                                        <Form>
                                            <TableContainer style={{ marginBottom: '2rem' }}>
                                                <Table aria-label="states table">
                                                    <TableHead className={classes.tableHeader}>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            {roleArr && roleArr.map((headCell) => (
                                                                <TableCell style={{ width: '5%' }} key={headCell.id}>
                                                                    {headCell.name}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            values.permissions && values.permissions.map((state, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {state.name}
                                                                        </TableCell>
                                                                        <FieldArray
                                                                            name={`permissions.${index}.requiredRoles`}
                                                                            render={() => {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            state.requiredRoles && state.requiredRoles.map((permission, idx) => {
                                                                                                return (
                                                                                                    <TableCell style={{ width: '5%' }} key={`permissions[${index}].requiredRoles[${idx}]`} align="center">
                                                                                                        <Field component={Checkbox} type="checkbox"
                                                                                                            id={`permissions[${index}].requiredRoles[${idx}]`}
                                                                                                            name={`permissions[${index}].requiredRoles[${idx}].required`} />
                                                                                                    </TableCell>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </React.Fragment>
                                                                                );
                                                                            }}
                                                                        />
                                                                    </TableRow>
                                                                );
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Grid item container justify='flex-end' xs={12}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Save State Permissions
                                                </Button>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
