import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Formik, Form, Field} from "formik";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LeadsFilter from "../../LeadsFilter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import {DEFAULT_PAGE_SIZE} from "../../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import SelectInput from "../../../AutocompleteContainer/AutoCompleteComponent";
import FileUploadInput from "../../../../shared/FileUploadInput";
import Badge from "@material-ui/core/Badge";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import HomeIcon from "@material-ui/icons/Home";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DocumentTabController from "./DocumentsTabController"
import { useParams } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
    },
    inputPadding: {
        paddingLeft: "2rem",
        paddingTop: "2rem",
    },
    shape: {
        background: theme.palette.primary.main,
        width: 90,
        height: 90,
    },
    badgePadding: {
        paddingLeft: theme.spacing(4),
    },
    card: {
        textAlign: "center",
    },
}));

export default function DocumentsTabContainer() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const { state, tab, id } = useParams();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("title");
    const [elementRef, setElementRef] = useState("");
    const { loading, error, data, total, types, reloadData, uploadDocument } = DocumentTabController(id);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headCells = [
        {id: "open", numeric: false, label: "Open", sort: true},
        {id: "type", numeric: false, label: "Type", sort: true},
        {id: "note", numeric: false, label: "Note", sort: true},
        {id: "document", numeric: false, label: "Document", sort: true},
        {id: "created", numeric: false, label: "Created", sort: true},
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const values = {
        fileUpload: "",
        note: "",
        documentType: "",
        documentId: 0
    };

    return (
        <div className={classes.stepPadding}>
            <Card className={classes.card}>
                <CardContent>
                    <Badge
                        className={classes.badgePadding}
                        color="secondary"
                        badgeContent={data.filter(itm => itm.documentType === 'payslip').length}
                    >
                        Payslip
                        <AssignmentRoundedIcon/>
                    </Badge>
                    <Badge
                        className={classes.badgePadding}
                        color="secondary"
                        badgeContent={data.filter(itm => itm.documentType === 'id').length}
                    >
                        ID
                        <AssignmentIndIcon/>
                    </Badge>
                    <Badge
                        className={classes.badgePadding}
                        color="secondary"
                        badgeContent={data.filter(itm => itm.documentType === 'drivers').length}
                    >
                        Drivers License
                        <DriveEtaIcon/>
                    </Badge>
                    <Badge
                        className={classes.badgePadding}
                        color="secondary"
                        badgeContent={data.filter(itm => itm.documentType === 'proof Of Address').length}
                    >
                        Proof Of Residence
                        <HomeIcon/>
                    </Badge>
                    <Badge
                        className={classes.badgePadding}
                        color="secondary"
                        badgeContent={data.filter(itm => itm.documentType === 'bank Statement').length}
                    >
                        Bank Statement
                        <AccountBalanceIcon/>
                    </Badge>
                </CardContent>
            </Card>
            <Divider
                variant="fullWidth"
                style={{marginTop: "1rem", marginBottom: "1rem"}}
            />
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {

                    uploadDocument(values);

                    setTimeout(() => {
                        console.log(values);
                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Card >
                                <CardContent>
                                    <Typography variant="h6" style={{marginBottom: "1.5rem"}}>
                                        Add a document
                                    </Typography>
                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPadding} xs={12} md={4} lg={4}>
                                            <FileUploadInput
                                                title='Document'
                                                fieldName={"fileUpload"}
                                                error={
                                                    props.errors.fileUpload && props.touched.fileUpload
                                                }
                                            />

                                        </Grid>
                                        <Grid item className={classes.inputPadding} xs={12} md={3} lg={3}>
                                            <Field
                                                component={SelectInput}
                                                props={props}
                                                Label={"Type"}
                                                fieldName={"documentType"}
                                                fieldNameID={"documentId"}
                                                data={types}
                                                helperText={props.touched.documentType ? props.errors.documentType : ""}
                                                error={props.touched.documentType && Boolean(props.errors.documentType)}
                                                value={{
                                                    name: props.values.documentType.toString(),
                                                    id: props.values.documentId.toString(),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item className={classes.inputPadding} xs={12} md={4} lg={4}>
                                            <TextField
                                                id="note"
                                                name="note"
                                                label="Note"
                                                multiline
                                                minRows={1}
                                                rowsMax={5}
                                                value={props.values.note}
                                                helperText={props.touched.note ? props.errors.note : ""}
                                                error={props.touched.note && Boolean(props.errors.note)}
                                                onChange={(event) => {
                                                    props.handleChange(event);
                                                    props.setFieldTouched("note", true, false);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                            <Button
                                                variant="contained"
                                                style={{margin: "1rem"}}
                                                color="primary"
                                                type="submit"
                                                disabled={!props.isValid}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{margin: "1rem"}}
                                                color="primary"
                                                onClick={props.resetForm}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
            <Divider
                variant="fullWidth"
                style={{marginTop: "1rem", marginBottom: "1rem"}}
            />
            <Card className={classes.card}>
                <CardContent>
                    <TableContainer ref={(el) => setElementRef(el)}>
                        <Table aria-label="ranges table">
                            <TableHeadersSort
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell><a href={row.imageUrl}>Download</a></TableCell>
                                        <TableCell>{row.documentType}</TableCell>
                                        <TableCell>{row.note}</TableCell>
                                        <TableCell>{row.fileName}</TableCell>
                                        <TableCell>{moment(row.createDate).format('l')}</TableCell>
                                    </TableRow>
                                ))}
                                {(!data.length || data.length === 0) && !loading && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{borderBottom: 0}}
                                            className="py-3"
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No Documents uploaded
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {loading && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{borderBottom: 0}}
                                        >
                                            <CircularProgress/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={total}
                            page={page}
                            rowsPerPageOptions={[5, 10, 25]}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );
}
