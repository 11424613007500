import { Route } from 'react-router-dom';
import React, {  useContext } from 'react';
import { AuthContext } from './AuthContext';
import LoginComponent from '../../components/login/LoginComponent';
import DealerContextProvider from '../DealerContext';
import { AUTH_USER } from '../Constants';

//Auth guard to protect global functionality from unauthorized access
function AuthGuard({ component: Component, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  const user = localStorage.getItem(AUTH_USER);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated || user !== null ? (
          <DealerContextProvider>
          <Component {...props} />
          </DealerContextProvider>

        ) : (
          <LoginComponent/>
        )
      }
    />
  );
}

export default AuthGuard;