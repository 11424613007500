import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Button,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Switch,
  FormControlLabel,
  TableHead,
  TablePagination,
  Typography,
  Tooltip
} from '@material-ui/core';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { LoadingContext } from '../../shared/context/loadingContext';
import { Formik, Form, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { BASE_URI, DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { DealerContext } from '../../shared/DealerContext';
import ImageUploadInput from '../../shared/ImageUploadFormInput';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  btn: {
    color: '#fff',
    background: '#3f51b5',
    padding: '8px 20px',
    border: 'none',
    margin: '5px',
    borderRadius: '3px',
  },
  hoverTableCell: {
    cursor: 'pointer',
  },
}));

export default function WebsitePages() {
  const initialValues = {
    banner: false,
    headline: false,
    headlineText: '',
    bannerOrder: 0,
    headlineOrder: 0,
    includePage: false,
    url: '',
    htmlModule1: false,
    htmlModule2: false,
    title: '',
    description: '',
    htmlModule1Text: '',
    htmlModule2Text: '',
  };

  const { id } = useParams();
  const [sitePages, setSitePages] = useState([]);
  const [page, setPage] = useState(null);
  const [isAddPage, setIsAddPage] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [main, setMain] = useState(page);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const { selectedMotorGroup } = useContext(DealerContext);

  useEffect(() => {
    if (selectedMotorGroup.id) {
      getWebsitePageInfo();
    }
  }, [id, selectedMotorGroup.id]);

  const headCells = [
    { id: 'url', label: 'URL' },
    { id: 'title', label: 'Title' },
    { id: 'description', label: 'Description' },
  ];

  const isBase64 = (str) => {
    const base64Pattern = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/i;
    return base64Pattern.test(str);
  };

  const processImages = async (fields) => {
    try {
      let image = fields.bannerImage;  
      let imageInput = [];

      if (isBase64(image?.image)) {
        imageInput.push(fields.bannerImage);  

        const res = await axios.put(`${BASE_URI}/Bob/uploadImage`, imageInput);
        let imgUrls = res.data;

        imgUrls.forEach((url) => {
          if (url.toLowerCase().includes(image.fileName.toLowerCase())) {
            image.image = url;
          }
        });
      } else {
        console.log('The image is not base64 encoded.');
      }

      return image;
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
      console.error('Error in processImages:', error);
    }
  };

  const onSubmitUpdatePage = async (fields) => {
    try {
      showLoading();
      const imgUrl = await processImages(fields);
      fields.bannerImage = imgUrl;
      fields.bobPayload = transformToBobPayload(fields);
  
      if (selectedMotorGroup.id) {
        if (page !== undefined && page !== null) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;
  
          axios.put(`${BASE_URI}/WebsitePages/${pageId}`, { ...page, id: pageId })
            .then((result) => {
              enqueueSnackbar('Updated Used VLP page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              console.error('Error in axios PUT request:', error);
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            pageCategoryId: 14
          };
  
          axios.post(`${BASE_URI}/WebsitePages`, { ...newPage })
            .then((result) => {
              enqueueSnackbar('Created Used VLP page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              console.error('Error in axios POST request:', error);
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
        hideLoading();
      }
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
      console.error('Error in onSubmitUpdatePage:', error);
      hideLoading();
    }
  };
  
  const transformToBobPayload = (values) => {
    const layoutMap = new Map();
  
    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params = [...new Map([...existing.params, ...params].map(item => [item.key, item])).values()];
        }
      }
    };
  
    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }
  
      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];
  
      if (field === 'banner' && values.banner) {
        if (values.bannerImage) {
          params.push({ key: 'bannerImage', value: values.bannerImage });
        }
        addToLayoutMap('banner', order, includes, params);
      } else if (!['banner', 'bannerImage'].includes(field) && !field.endsWith('Text') && !field.endsWith('Order')) {
        if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }
    });
  
    const layout = Array.from(layoutMap.values());
  
    return {
      layout,
      page: page?.title || ''
    };
  };
  
  const getWebsitePageInfo = async () => {
    showLoading();
    try {
      const { data } = await axios.get(`${BASE_URI}/WebsitePages`, {
        params: { dealerId: selectedMotorGroup.id, id },
      });
      setSitePages(data.filter((p) => p.pageCategoryId === 14));
    } catch (error) {
      enqueueSnackbar('Failed to load pages', { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleEditPage = (selectedPage) => {
    setPage(selectedPage);
    setIsAddPage(true);
  };

  const handleAddPageToggle = () => {
    setPage(null);
    setIsAddPage(!isAddPage);
  };

  useEffect(() => {
    getWebsitePageInfo();
  }, [id]);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction="column">
        <Grid item xs={12}>
          <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
            <Typography style={{ flex: 1 }} variant="h4" id="tableTitle" component="div">
              Custom Pages
            </Typography>
            <Button
              onClick={handleAddPageToggle}
              variant="contained"
              color="primary"
              style={{ marginLeft: '2rem' }}
              startIcon={<AddIcon />}
            >
              {isAddPage ? 'Cancel' : 'Add Pages'}
            </Button>
          </Toolbar>
        </Grid>
        {!isAddPage && !page ? (
          <TableContainer>
            <Table aria-label="templates table" size="small">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sitePages.map((row) => (
                  <Tooltip title={`Edit ${row.id}`} key={row.id} placement="left">
                    <TableRow hover className={classes.hoverTableCell} onClick={() => handleEditPage(row)}>
                      <TableCell>
                        <Typography
                          style={{ color: row.status === 'inActive' ? '#ff5722' : '' }}
                          variant="subtitle2"
                        >
                          {row.url}
                        </Typography>
                      </TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Button
                          className={classes.btn}
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditPage(row)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
                {(!sitePages.length || sitePages.length === 0) && (
                  <TableRow>
                    <TableCell
                      colSpan={headCells.length + 1}
                      align="center"
                      style={{ borderBottom: 0 }}
                    >
                      <Typography variant="h6" color="textSecondary">
                        {selectedMotorGroup.id ? 'No custom pages Added' : 'Select a dealer!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              page={currentPage}
              rowsPerPage={pageSize}
              component="div"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageSize}
            />
          </TableContainer>
        ) : (
          <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
            <Toolbar>
              <Typography variant="h6" component="div">
                {page ? 'Edit Custom Page' : 'Add Custom Page'}
              </Typography>
            </Toolbar>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Formik
                  initialValues={page ? JSON.parse(page.templateConfig) : initialValues}
                  onSubmit={onSubmitUpdatePage}
                >
                  {({ values, handleChange, setFieldValue }) => {
                    const handleChangeCustom = (fieldName, checked) => {
                      setFieldValue(fieldName, checked);
                    };

                    return (
                      <Form>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>Include Page</TableCell>
                                <TableCell>Boolean</TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.includePage}
                                        onChange={(e) => handleChangeCustom('includePage', e.target.checked)}
                                        name="includePage"
                                      />
                                    }
                                    label=""
                                  />
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Text</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                  <Field
                                    name="title"
                                    component={TextFieldFormik}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter multiline text..."
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Text</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                  <Field
                                    name="description"
                                    component={TextFieldFormik}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter multiline text..."
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>URL Link</TableCell>
                                <TableCell>Link</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                  <Field
                                    name="url"
                                    component={TextFieldFormik}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter multiline text..."
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Banner</TableCell>
                                <TableCell>Section</TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.banner}
                                        onChange={(e) => handleChangeCustom('banner', e.target.checked)}
                                        name="banner"
                                      />
                                    }
                                    label=""
                                  />
                                </TableCell>
                                <TableCell>
                                  {values.banner && (
                                    <ImageUploadInput
                                      title="Banner Image"
                                      required
                                      fieldName="bannerImage"
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name="bannerOrder"
                                    type="number"
                                    component={TextFieldFormik}
                                    label="Order"
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Headline</TableCell>
                                <TableCell>Section</TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.headline}
                                        onChange={(e) => handleChangeCustom('headline', e.target.checked)}
                                        name="headline"
                                      />
                                    }
                                    label=""
                                  />
                                </TableCell>
                                <TableCell>
                                  {values.headline && (
                                    <Field
                                      name="headlineText"
                                      component={TextFieldFormik}
                                      multiline
                                      rows={4}
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Enter multiline text..."
                                      onChange={handleChange}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name="headlineOrder"
                                    type="number"
                                    component={TextFieldFormik}
                                    label="Order"
                                    fullWidth
                                    onChange={handleChange}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>HTML Module 1</TableCell>
                                <TableCell>HTML</TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.htmlModule1}
                                        onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                        name="htmlModule1"
                                      />
                                    }
                                    label=""
                                  />
                                </TableCell>
                                <TableCell>
                                  {values.htmlModule1 && (
                                    <Field
                                      name="htmlModule1Text"
                                      component={TextFieldFormik}
                                      multiline
                                      rows={4}
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Enter multiline text..."
                                      onChange={handleChange}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name="htmlModule1Order"
                                    type="number"
                                    component={TextFieldFormik}
                                    label="Order"
                                    fullWidth
                                    onChange={handleChange}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>HTML Module 2</TableCell>
                                <TableCell>HTML</TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.htmlModule2}
                                        onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                        name="htmlModule2"
                                      />
                                    }
                                    label=""
                                  />
                                </TableCell>
                                <TableCell>
                                  {values.htmlModule2 && (
                                    <Field
                                      name="htmlModule2Text"
                                      component={TextFieldFormik}
                                      multiline
                                      rows={4}
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Enter multiline text..."
                                      onChange={handleChange}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name="htmlModule2Order"
                                    type="number"
                                    component={TextFieldFormik}
                                    label="Order"
                                    fullWidth
                                    onChange={handleChange}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            {page?.id ? 'Update' : 'Add'}
                          </Button>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </Paper>
  );
}
