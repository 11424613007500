import React, { useEffect, useState, useRef } from "react";
import { Grid, TextField, Button, Box, Divider, Fab } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import { Switch } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { BASE_URI } from "../../shared/Constants";
import axios from "axios";
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from "notistack";
import _ from "lodash";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHeadersSort from '../../shared/TableHeadersSort';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    formControl: {
        margin: theme.spacing(3),
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
}));

function getStyles(arrayItem, selectArray, theme) {
    return {
        fontWeight: selectArray.indexOf(arrayItem) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

export default function DealerGetScore({ match }) {
    const classes = useStyles();
    const { id } = match.params;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [getScoreDetails, setGetScore] = useState(null);
    const [auditHistory, setAuditHistory] = useState([])
    console.log('getscore', getScoreDetails)
    console.log('history', auditHistory)

    const headCells = [
        { id: '1', label: 'Transaction Date ', sort: false },
        { id: '3', label: 'Type', sort: false },
        { id: '4', label: 'Actioned By', sort: false },
        { id: '5', label: 'Credits', sort: false },
        { id: '6', label: 'Lead', sort: false },
    ];
    const GetAction = (value) => {
        switch (value) {
            case 1:
                return 'New Subscription'
            case 2:
                return 'Activation'
            case 3:
                return 'Deactivation'
            case 4:
                return 'Credits Reset'
            case 5:
                return 'Buy Credits'
            case 6:
                return ' New Lead(Credit Deduction)'
            case 7:
                return 'Credits RollOver'
            default:
                return '';
        }
    }

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const fetchDealerGetSocre = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/GetScore/dealer/${id}`, {
                    params: {
                        id,
                    },
                    cancelToken: source.token,
                });

                setGetScore(result.data);
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        };
        fetchDealerGetSocre();

        const fetchDealerGetSocreHistory = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/GetScore/dealerAudit/${id}`, {
                    cancelToken: source.token,
                });

                setAuditHistory(result.data);
            } catch (error) {
                if (axios.isCancel(error)) return

            }
        };
        fetchDealerGetSocreHistory();
    }
    const handleSubscribe = () => {
        const SaveDealerCampaign = async () => {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                const result = await axios.post(`${BASE_URI}/GetScore`, {
                    cancelToken: source.token,
                    dealerId: parseInt(id)
                })

                fetchData();
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        }
        SaveDealerCampaign();
    }
    const handleBuyCredits = () => {
        const SaveDealerCampaign = async () => {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                const result = await axios.put(`${BASE_URI}/GetScore/buyCredits/${id}`, {
                    cancelToken: source.token,
                })

                fetchData();
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        }
        SaveDealerCampaign();
    }
    const handleActivation = () => {
        const SaveDealerCampaign = async () => {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                const result = await axios.put(`${BASE_URI}/GetScore/activate/${id}`, {
                    cancelToken: source.token,
                })

                fetchData();
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        }
        SaveDealerCampaign();
    }
    const handleDeactivation = () => {
        console.log('arived')
        const SaveDealerCampaign = async () => {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                const result = await axios.put(`${BASE_URI}/GetScore/deactivate/${id}`, {
                    cancelToken: source.token,
                })

                fetchData();
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        }
        SaveDealerCampaign();
    }







    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Dealer Get Score
                    </Typography>
                    <Grid
                        spacing={4}
                        container
                        justify="space-around"
                        direction="row"
                    >

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div">
                                Get Score Details:
                            </Typography>
                        </Grid>
                        {getScoreDetails && <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="total"
                                    id="total"
                                    label="Total Credits"
                                    value={`${getScoreDetails?.totalCredits}`}
                                    type="text"
                                />

                                <TextField
                                    fullWidth
                                    name="purchase"
                                    id="purchase"
                                    label="Purchased Credits"
                                    value={`${getScoreDetails?.purchasedCredits}`}
                                    type="text"
                                />

                                <TextField
                                    fullWidth
                                    name="leads"
                                    id="leads"
                                    label="Month Leads Count"
                                    value={`${getScoreDetails?.monthLeadsCount}`}
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <TextField
                                    fullWidth
                                    name="status"
                                    required
                                    id="status"
                                    label="Get Score Status"
                                    value={getScoreDetails?.active ? 'Active' : 'Inactive'}
                                    type="text"
                                />
                            </Grid></>}
                        <Grid item container
                            justify='space-around' xs={12}>
                            {!getScoreDetails && <Box mr={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubscribe()}
                                >
                                    Subscribe for GetScore
                                </Button>
                            </Box>}
                            {getScoreDetails && !getScoreDetails?.active && <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleActivation()}
                            >
                                Activate GetScore
                            </Button>}
                            {getScoreDetails?.active && <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDeactivation()}
                            >
                                Deactivate GetScore
                            </Button>}
                            {getScoreDetails?.totalCredits < 50 &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleBuyCredits()}
                                >
                                    Top Up Credits (100)
                                </Button>}
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div">
                                Transactions History:
                            </Typography>
                        </Grid>



                        {getScoreDetails && <>
                            <Grid item xs={12}>
                                <React.Fragment>
                                    <TableContainer>
                                        <Table aria-label="slides table" size="small">
                                            <TableHeadersSort
                                                headCells={headCells}
                                            />
                                            <TableBody>
                                                {auditHistory.map((c,index) => {
                                                    return (
                                                        <TableRow key={index} hover={'pointer'}>

                                                            <TableCell>{c?.date}</TableCell>
                                                            <TableCell>{GetAction(c?.action)}</TableCell>
                                                            <TableCell>{c?.user?.name ? c.user?.name : 'System'}</TableCell>
                                                            <TableCell>{c?.credits}</TableCell>
                                                            <TableCell>{c?.leadId ? c?.leadId : 'N/A'}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                {(!auditHistory.length || auditHistory.length === 0) && (
                                                    <TableRow>
                                                        <TableCell colSpan={headCells?.length} align="center" style={{ borderBottom: 0 }} className="py-3">
                                                            <Typography variant="h6" color="textSecondary">
                                                                No Data available
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </React.Fragment>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid></>}

                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
