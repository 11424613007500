import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  Divider,
  Paper,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  InputLabel
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingContext } from '../../shared/context/loadingContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    background: '#bdbfc1',
    color: 'white',
    padding: '10px'
  }
}));

export default function DealerImportersForm({ history, props }) {
  const initialValues = {
    importerMappingId: 0,
    importType: 0,
    foreignDealerId: '',
    selectedMappings: []
  };

  const { id } = useParams();
  const [formFields, setFormFields] = useState(initialValues);
  const [importerArr, setImporterArr] = useState([]);
  const [importerMappingArr, setImporterMappingArr] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dealerIds, setDealerIds] = useState([]);
  const classes = useStyles();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  const onSubmit = (foreignDealerId, importerId, setFieldValue) => {
    showLoading();
    axios
      .put(`${BASE_URI}/importer/${id}`, {
        dealerId: parseInt(id),
        selectedMappings: foreignDealerId === '' ? dealerIds : [],
        foreignDealerId: foreignDealerId || '',
        importerId: importerId
      })
      .then((result) => {
        enqueueSnackbar('Updated importer', { variant: 'success' });
        setFieldValue('foreignDealerId', '');
        getImporterMapping(importerId);
      })
      .catch((error) => {
        enqueueSnackbar('Updated failed:' + error.response.data , { variant: 'error' });
      }).finally(()=>{
        hideLoading();
      })
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getImporters = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/lookup/importers`);

        setImporterArr(result.data);
      } catch (error) {
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    getImporters();

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImporterSelect = (event, setFieldValue) => {
    setDealerIds([]);
    setFieldValue('importer', event.target.value);
    setDealerIds(event.target.value);
  };

  const getImporterMapping = async (importerId) => {
    try {
      const result = await axios.get(`${BASE_URI}/importer`, {
        params: {
          dealerId: id,
          importerId: importerId
        }
      });

      if (result.data) {
        setImporterMappingArr(result.data);

        setDealerIds([]);
        let dealerIdz = [];
        result.data.map((f_dealer) => {
          dealerIdz.push(f_dealer.importerMappingId);
        });
        setDealerIds(dealerIdz);
      }
    } catch (error) {
      enqueueSnackbar(`Unable to retrieve mappings`, { variant: 'error' });
    }
  };

  const handleAccordionClick = (importerId, setFieldValue) => {
    getImporterMapping(importerId);
    setExpandedAccordion(importerId === expandedAccordion ? null : importerId);
    setFieldValue('foreignDealerId', '');
  };

  return (
    <Paper className={classes.paper}>
      <Formik
        initialValues={formFields}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          dealerId: Yup.string().required('Dealership Name is required'),
          selectedMappings: Yup.array().required('Please select at least one mapping')
        })}
      >
        {(props) => {
          const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

          return (
            <Form>
              <Grid container spacing={4} className={classes.stepPadding} direction='column'>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>
                      IMPORTERS
                    </Typography>
                  </Grid>
                  <div>
                    {importerArr.map((importerOption, index) => (
                      <Accordion
                        key={index}
                        expanded={expandedAccordion === importerOption.importerId}
                        onChange={() => handleAccordionClick(importerOption.importerId, setFieldValue)}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel3-content' id='panel3-header'>
                          {importerOption.name}
                        </AccordionSummary>
                        <AccordionDetails>
                          <InputLabel htmlFor='mapping'>Configure mapping</InputLabel>
                          <Select
                            id={importerOption.importerId}
                            multiple
                            fullWidth
                            value={dealerIds}
                            required
                            error={!!(errors.selectedMappings && touched.selectedMappings)}
                            onChange={(e, child) => handleImporterSelect(e, setFieldValue)}
                            input={<OutlinedInput id='importer' label='Importer' />}
                            renderValue={(selected) => {
                              const selectedLabels = selected.map((value) => {
                                const label = importerMappingArr.find((imp) => imp.importerMappingId === value)?.foreignDealerId;
                                return label ? <Chip key={value} label={label} /> : null;
                              });
                              return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selectedLabels}</Box>;
                            }}
                            MenuProps={MenuProps}
                          >
                            {importerMappingArr.map((option) => (
                              <MenuItem key={option.foreignDealerId} value={option.importerMappingId}>
                                <Checkbox checked={dealerIds.indexOf(option.importerMappingId) > -1} />
                                <ListItemText primary={option.foreignDealerId} />
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            fullWidth
                            name='foreignDealerId'
                            id='foreignDealerId'
                            label='Foreign Dealer ID'
                            value={values.foreignDealerId}
                            helperText={errors.foreignDealerId && touched.foreignDealerId ? errors.foreignDealerId : ''}
                            error={errors.foreignDealerId && touched.foreignDealerId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </AccordionDetails>
                        <AccordionActions>
                          <Button
                            disabled={values.foreignDealerId.length === 0}
                            onClick={() => onSubmit(values.foreignDealerId, importerOption.importerId, setFieldValue)}
                          >
                            Save Foreign Dealer
                          </Button>
                          <Button
                            disabled={values.foreignDealerId.length !== 0 || dealerIds.length === 0}
                            onClick={() => onSubmit(values.foreignDealerId, importerOption.importerId, setFieldValue)}
                          >
                            Save Mappings
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    ))}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}
