import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Grid, TextField, Button, Divider, Box, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CurrencyInput, CurrencyValue } from '../../../shared/TextMask';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ImageUploadInput from '../../../shared/ImageUploadFormInput';
import FileUploadInput from '../../../shared/FileUploadInput';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import Link from '@mui/material/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  featuredImage: {
    width: 500,
    height: 250
  },
  headerNote: {
    fontWeight: 100,
    fontSize: '1rem'
  }
}));

export default function RangeForm({ onSubmitModelForm, onSubmitInformationForm, cancel, data }) {

  const initialModelValues = {
    ...data,
    id:data?.id,
    image: data?.image ?? '',
    make: data?.make ?? '',
    model: data?.model ?? '',
    price: data?.price ?? 0,
    title: data?.title ?? '',
    makeId: data?.makeId ?? 0,
    categoryId: data?.categoryId ?? 0,
    category:'',
    modelId: data?.modelId ?? 0,
    isPOA: data?.isPOA ?? false,
    isComingSoon: data?.isComingSoon ?? false,
    isAutomaticPrice: data?.isAutomaticPrice ?? false,
    altTag: data?.altTag ?? ''
  };

  const main = data?.ownedModelInformation?.contentImages?.find((i) => {
    return i.imageName === 'main';
  })?.image;
  const small = data?.ownedModelInformation?.contentImages?.find((i) => {
    return i.imageName === 'small';
  })?.image;
  const medium1 = data?.ownedModelInformation?.contentImages?.find((i) => {
    return i.imageName === 'medium1';
  })?.image;
  const medium2 = data?.ownedModelInformation?.contentImages?.find((i) => {
    return i.imageName === 'medium2';
  })?.image;
  const medium3 = data?.ownedModelInformation?.contentImages?.find((i) => {
    return i.imageName === 'medium3';
  })?.image;
  const medium4 = data?.ownedModelInformation?.contentImages?.find((i) => {
    return i.imageName === 'medium4';
  })?.image;
  const brochure1 =
    data?.ownedModelInformation?.brochures?.length >= 1
      ? new File(['No contents'], data?.ownedModelInformation?.brochures[0]?.document.fileName, { type: 'application/pdf' })
      : '';
  const brochure2 =
    data?.ownedModelInformation?.brochures?.length >= 2
      ? new File(['No contents'], data?.ownedModelInformation?.brochures[1]?.document.fileName, { type: 'application/pdf' })
      : '';



  const initialInformationValues = {
    ...data?.ownedModelInformation,
    ownedModelId: data?.id || 0,
    brochure1: brochure1,
    brochure2: brochure2,
    videoLink: data?.ownedModelInformation?.videoLink || '',
    introductionTitle: data?.ownedModelInformation?.introductionTitle || '',
    introductionDesc: data?.ownedModelInformation?.introductionDesc || '',
    contentTitle1: data?.ownedModelInformation?.contentTitle1 || '',
    contentDesc1: data?.ownedModelInformation?.contentDesc1 || '',
    contentTitle2: data?.ownedModelInformation?.contentTitle2 || '',
    contentDesc2: data?.ownedModelInformation?.contentDesc2 || '',
    contentTitle3: data?.ownedModelInformation?.contentTitle3 || '',
    contentDesc3: data?.ownedModelInformation?.contentDesc3 || '',
    contentTitle4: data?.ownedModelInformation?.contentTitle4 || '',
    contentDesc4: data?.ownedModelInformation?.contentDesc4 || '',
    contentTitle5: data?.ownedModelInformation?.contentTitle5 || '',
    contentDesc5: data?.ownedModelInformation?.contentDesc5 || '',
    headlineTitle: data?.ownedModelInformation?.headlineTitle || '',
    headlineDesc: data?.ownedModelInformation?.headlineDesc || '',
    main: main || {},
    small: small || {},
    medium1: medium1 || {},
    medium2: medium2 || {},
    medium3: medium3 || {},
    medium4: medium4 || {},
    // deal: data?.ownedModelInformation?.deal || {
    //     MonthlyInstallment: 0,
    //     Term: 0,
    //     Rate: 0,
    //     Deposit: 0,
    //     BalloonPayment: 0,
    //     TermsAndConditions: ''
    // },
    galleryImages: data?.ownedModelInformation?.galleryImages || [],
    features: data?.ownedModelInformation?.features || []
  };

  const mountedRef = useRef(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [modelFormValues, setFormValues] = useState(initialModelValues);
  const [informationFormValues, setInformationFormValues] = useState(initialInformationValues);
  const [makeArr, setMakeArr] = useState([]);
  const [modelArr, setModelArr] = useState([]);
  const [disableModel, setDisabledModel] = useState(true);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const [categories,setCategories]=useState([]);

  useEffect(() => {
    if (data?.makeId > 0) {
      setDisabledModel(false);

      if (modelArr.length == 0) {
        getModel(data.makeId, data);
      }
      if (categories.length == 0) {
        getMakeCategories(data.makeId, data);
      }
    }
  }, [data]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getMake = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/makes`, {
          cancelToken: source.token
        });
        setMakeArr(result.data.list);
      } catch (error) {
        enqueueSnackbar(`Unable to retrieve vehicle makes`, { variant: 'error' });
      }
    };
    getMake().then(() => {
      if (!mountedRef.current) return null;
    });
    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleMakeChange = async (e, value, setFieldValue) => {
    setFieldValue('make', e.target.value);
    setFieldValue('makeId', value.props.id);
    getMakeCategories(value.props.id);
    getModel(value.props.id);
  };

  const handleModelChange = (e, value, setFieldValue) => {
    setFieldValue('model', e.target.value);
    setFieldValue('modelId', value.props.id);
  };

  const getModel = async (makeId) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const result = await axios.get(`${BASE_URI}/models`, {
        params: {
          makeId: makeId
        },
        cancelToken: source.token
      });

      setDisabledModel(false);
      setModelArr(result.data.list);
    } catch (error) {
      setDisabledModel(true);
      enqueueSnackbar(`Unable to retrieve models`, { variant: 'error' });
    }
  };

  const getMakeCategories = async (makeId) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    try {
      const result = await axios.get(`${BASE_URI}/Categories/make/${makeId}`, {
        cancelToken: source.token
      });
      setCategories(result.data);
    } catch (error) {
      enqueueSnackbar(`Unable to retrieve categories`, { variant: 'error' });
    }
  };

  const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setFieldValue(fieldName, e.target.value);
    setFieldValue(fieldId, value.props.id);
  };

  const removeItem = (itemList, index) => {
    itemList.splice(index, 1);
  };

  const addItem = (itemList, item) => {
    itemList.push(item);
  };

  const onSubmitDetails = (values) => {
    onSubmitInformationForm(values);
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Formik
          initialValues={modelFormValues}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setIsFormSubmit(true);
            onSubmitModelForm(values);
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 500);
          }}
          validationSchema={Yup.object().shape({
            make: Yup.string().required('Select a Make for the vehicle'),
            model: Yup.string().when('isComingSoon', {
              is: false,
              then: Yup.string().required('Select a Model for the vehicle')
            }),
            title: Yup.string().required('Enter a title for the Range option'),
            image: Yup.string().required('Vehicle image is required')
          })}
        >
          {(props) => {
            const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

            let categoryName = categories.find((cat)=>(cat.id==values.categoryId && initialModelValues?.id==values.id))?.name
                  ?? values.category;

            let price = modelArr.find((ma)=>ma.id===values.modelId)?.price;

            return (
              <Form noValidate>
                <Typography variant='h6' gutterBottom>
                  {data ? 'Edit' : 'Create'} Model
                </Typography>
                <Grid spacing={4} container justify='space-around' direction='row'>
                  <Grid item xs={12} md={6}>
                    <ImageUploadInput title='Vehicle Image' required fieldName={'image'} error={errors.image && touched.image} />
                    <br></br>
                    <br></br>
                    <TextField
                      fullWidth
                      name='altTag'
                      id='altTag'
                      label='Alt Tag'
                      value={values.altTag}
                      type='text'
                      helperText={errors.altTag && touched.altTag ? errors.altTag : 'Alt tag for SEO'}
                      error={errors.altTag && touched.altTag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name='title'
                      required
                      id='title'
                      label='Title'
                      value={values.title}
                      type='text'
                      helperText={errors.title && touched.title ? 'Please enter a title for the model' : ''}
                      error={errors.title && touched.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      fullWidth
                      id='make'
                      select
                      required
                      placeholder='Please select make'
                      label='Make'
                      value={values.make}
                      helperText={errors.make && touched.make ? 'Please select make of the vehicle' : ''}
                      error={errors.make && touched.make}
                      onBlur={handleBlur('make')}
                      onChange={(e, child) => handleMakeChange(e, child, setFieldValue)}
                    >
                      {makeArr.map((option) => (
                        <MenuItem key={option.id} id={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      id='model'
                      select
                      required={!values.isComingSoon}
                      placeholder='Please select model'
                      label='Model'
                      disabled={disableModel || values.isComingSoon}
                      value={values.model}
                      helperText={errors.model && touched.model ? 'Please select model of the vehicle' : ''}
                      error={errors.model && touched.model}
                      onBlur={handleBlur('model')}
                      onChange={(e, child) => handleModelChange(e, child, setFieldValue)}
                    >
                      {modelArr.map((option) => (
                        <MenuItem key={option.id} id={option.id} value={option.model}>
                          {option.model}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      id='category'
                      select
                      required={!values.isService}
                      label='Category'
                      value={categoryName}
                      helperText={errors.category && touched.category ? errors.category : ''}
                      error={errors.category && touched.category}
                      onBlur={handleBlur('category')}
                      onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'category', 'categoryId')}
                    >
                      {categories.map((option) => (
                        <MenuItem key={option.id} id={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <FormControlLabel
                      label={'Is POA'}
                      control={<Checkbox id='isPOA' label='Is POA' color='primary' checked={values.isPOA} onChange={handleChange} />}
                    />

                    <FormControlLabel
                      label={'Coming Soon'}
                      control={
                        <Checkbox
                          id='isComingSoon'
                          label='Coming Soon'
                          color='primary'
                          checked={values.isComingSoon}
                          onChange={handleChange}
                        />
                      }
                    />
                    <FormControlLabel
                      id='isAutomaticPrice'
                      name='isAutomaticPrice'
                      checked={values.isAutomaticPrice}
                      control={<Checkbox name={values.isAutomaticPrice} color='primary' onChange={handleChange('isAutomaticPrice')} />}
                      label={'Automatic Price'}
                    />
                     <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        Duoporta Base Variant Price:  <CurrencyValue value={price} />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        Custom Price
                      </Typography>
                    </Grid>
                    <TextField
                      fullWidth
                      required
                      id='price'
                      disabled={values.isPOA || values.isComingSoon || values.isAutomaticPrice}
                      value={values.price}
                      helperText={errors.price && touched.price ? 'Please enter a valid amount' : ''}
                      onChange={handleChange}
                      error={errors.price && touched.price}
                      InputProps={{
                        inputComponent: CurrencyInput
                      }}
                    />
                  </Grid>
                  <Grid item container justify='flex-end' xs={12}>
                    <Box mr={3}>
                      <Button
                        onClick={() => {
                          handleReset();
                          cancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Button type='submit' variant='contained' color='primary' disabled={isSubmitting || isFormSubmit}>
                      Save Model
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>

      {initialInformationValues.ownedModelId > 0 && (
        <Grid item xs={12}>
          <Formik
            initialValues={informationFormValues}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              setIsFormSubmit(true);
              onSubmitDetails(values);
              // setTimeout(() => {
              //     actions.setSubmitting(false)
              // }, 500)
            }}
            validationSchema={Yup.object({
              brochure1: Yup.string().required('Brochure document is required'),
              introductionTitle: Yup.string().required('Introduction title is required'),
              introductionDesc: Yup.string().required('Introduction paragraph is required'),
              contentTitle1: Yup.string().required('First highlight title is required'),
              contentDesc1: Yup.string().required('First highlight paragraph is required'),
              contentTitle2: Yup.string().required('Second highlight title is required'),
              contentDesc2: Yup.string().required('Second highlight paragraph is required'),
              contentTitle3: Yup.string().required('Third highlight title is required'),
              contentDesc3: Yup.string().required('Third highlight paragraph is required'),
              main: Yup.string().required('Featured image is required'),
              small: Yup.string().required('First highlight image is required'),
              medium1: Yup.string().required('Second highlight image is required'),
              medium2: Yup.string().required('Third highlight image is required')
            })}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, setFieldValue, isSubmitting, handleReset } = props;

              //alt tag for content images
              values.main.altTag = values.mainAltTag ?? values.main.altTag;
              values.small.altTag = values.smallAltTag ?? values.small.altTag;
              values.medium1.altTag = values.medium1AltTag ?? values.medium1.altTag;
              values.medium2.altTag = values.medium2AltTag ?? values.medium2.altTag;
              values.medium3.altTag = values.medium3AltTag ?? values.medium3.altTag;
              values.medium4.altTag = values.medium4AltTag ?? values.medium4.altTag;

              return (
                <Form>
                  <Grid item xs={12}>
                    <Divider />
                    <br />
                  </Grid>
                  <Typography variant='h6' gutterBottom>
                    Brochures
                  </Typography>
                  <Grid spacing={4} container justify='space-around' direction='row'>
                    <Grid item xs={12} md={6}>
                      <div>
                        <Link href={brochure1?.filePath}>
                          <strong>{brochure1?.fileName}</strong>
                        </Link>
                      </div>
                      <FileUploadInput
                        title='PDF 1'
                        required
                        fieldName={'brochure1'}
                        value={values?.brochure1}
                        error={errors.brochure1 && touched.brochure1}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div>
                        <Link href={brochure2?.filePath}>
                          <strong>{brochure2?.fileName}</strong>
                        </Link>
                      </div>
                      <FileUploadInput
                        title='PDF 2'
                        fieldName={'brochure2'}
                        value={values?.brochure2}
                        error={errors.brochure2 && touched.brochure2}
                      />
                    </Grid>
                  </Grid>

                  <br />

                  <Typography variant='h6' gutterBottom>
                    Video Link
                  </Typography>
                  <Grid spacing={4} container justify='space-around' direction='row'>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name='videoLink'
                        id='videoLink'
                        label='Link'
                        value={values.videoLink}
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <br />

                  <Typography variant='h6' gutterBottom>
                    Model Information Content
                  </Typography>
                  <Grid spacing={4} container justify='space-around' direction='row'>
                    <Grid item xs={12} md={12} container justify='space-between'>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          required
                          title='Featured Image'
                          fieldName={`main.imageUrl`}
                          className={classes.featuredImage}
                          error={errors.main && touched.main}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          fullWidth
                          name='mainAltTag'
                          id='mainAltTag'
                          label='Alt Tag'
                          required={values.main?.imageUrl!==""}
                          value={values.main?.altTag}
                          type='text'
                          helperText={errors.mainAltTag && touched.mainAltTag ? errors.mainAltTag : 'Alt tag for SEO'}
                          error={errors.mainAltTag && touched.mainAltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          name='introductionTitle'
                          id='introductionTitle'
                          label='Introduction Title'
                          value={values.introductionTitle}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.introductionTitle && touched.introductionTitle}
                        />
                        <TextField
                          fullWidth
                          required
                          multiline
                          minRows={9}
                          variant='outlined'
                          name='introductionDesc'
                          id='introductionDesc'
                          label='Introduction Paragraph'
                          value={values.introductionDesc}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.introductionDesc && touched.introductionDesc}
                        />
                      </Grid>
                    </Grid>

                    <br />

                    <Grid item xs={12} md={12} container justify='space-between'>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          required
                          title='First Highlight Image'
                          fieldName={`small.imageUrl`}
                          className={classes.featuredImage}
                          error={errors.small && touched.small}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          fullWidth
                          name='smallAltTag'
                          id='smallAltTag'
                          required={values.small?.imageUrl!==""}
                          label='Alt Tag'
                          value={values.small?.altTag}
                          type='text'
                          helperText={errors.smallAltTag && touched.smallAltTag ? errors.smallAltTag : 'Alt tag for SEO'}
                          error={errors.smallAltTag && touched.smallAltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          name='contentTitle1'
                          id='contentTitle1'
                          label='First Highlight Title'
                          value={values.contentTitle1}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentTitle1 && touched.contentTitle1}
                        />
                        <TextField
                          fullWidth
                          required
                          multiline
                          minRows={9}
                          variant='outlined'
                          name='contentDesc1'
                          id='contentDesc1'
                          label='First Highlight Paragraph'
                          value={values.contentDesc1}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentDesc1 && touched.contentDesc1}
                        />
                      </Grid>
                    </Grid>

                    <br />

                    <Grid item xs={12} md={12} container justify='space-between'>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          required
                          title='Exterior Photo'
                          fieldName={`medium1.imageUrl`}
                          className={classes.featuredImage}
                          error={errors.medium1 && touched.medium1}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          fullWidth
                          name='medium1AltTag'
                          id='medium1AltTag'
                          required={values.medium1?.imageUrl!==""}
                          label='Alt Tag'
                          value={values.medium1?.altTag}
                          type='text'
                          helperText={errors.medium1AltTag && touched.medium1AltTag ? errors.medium1AltTag : 'Alt tag for SEO'}
                          error={errors.medium1AltTag && touched.medium1AltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          name='contentTitle2'
                          id='contentTitle2'
                          label='Second Highlight Title'
                          value={values.contentTitle2}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentTitle2 && touched.contentTitle2}
                        />
                        <TextField
                          fullWidth
                          required
                          multiline
                          minRows={9}
                          variant='outlined'
                          name='contentDesc2'
                          id='contentDesc2'
                          label='Second Highlight Paragraph'
                          value={values.contentDesc2}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentDesc2 && touched.contentDesc2}
                        />
                      </Grid>
                    </Grid>

                    <br />

                    <Grid item xs={12} md={12} container justify='space-between'>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          required
                          title='Interior Photo'
                          fieldName={`medium2.imageUrl`}
                          className={classes.featuredImage}
                          error={errors.medium2 && touched.medium2}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          fullWidth
                          name='medium2AltTag'
                          id='medium2AltTag'
                          label='Alt Tag'
                          required={values.medium2?.imageUrl!==""}
                          value={values.medium2?.altTag}
                          type='text'
                          helperText={errors.medium2AltTag && touched.medium2AltTag ? errors.medium2AltTag : 'Alt tag for SEO'}
                          error={errors.medium2AltTag && touched.medium2AltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          name='contentTitle3'
                          id='contentTitle3'
                          label='Third Highlight Title'
                          value={values.contentTitle3}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentTitle3 && touched.contentTitle3}
                        />
                        <TextField
                          fullWidth
                          required
                          multiline
                          minRows={9}
                          variant='outlined'
                          name='contentDesc3'
                          id='contentDesc3'
                          label='Third Highlight Paragraph'
                          value={values.contentDesc3}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentDesc3 && touched.contentDesc3}
                        />
                      </Grid>
                    </Grid>

                    <br />

                    <Grid item xs={12} md={12} container justify='space-between'>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          title='Additional Photo'
                          fieldName={`medium3.imageUrl`}
                          className={classes.featuredImage}
                          error={errors.medium3 && touched.medium3}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          fullWidth
                          name='medium3AltTag'
                          id='medium3AltTag'
                          label='Alt Tag'
                          required={values.medium3?.imageUrl!==""}
                          value={values.medium3?.altTag}
                          type='text'
                          helperText={errors.medium3AltTag && touched.medium3AltTag ? errors.medium3AltTag : 'Alt tag for SEO'}
                          error={errors.medium3AltTag && touched.medium3AltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          name='contentTitle4'
                          id='contentTitle4'
                          label='Fourth Highlight Title'
                          value={values.contentTitle4}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentTitle4 && touched.contentTitle4}
                        />
                        <TextField
                          fullWidth
                          multiline
                          minRows={9}
                          variant='outlined'
                          name='contentDesc4'
                          id='contentDesc4'
                          label='Fourth Highlight Paragraph'
                          value={values.contentDesc4}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentDesc4 && touched.contentDesc4}
                        />
                      </Grid>
                    </Grid>

                    <br />

                    <Grid item xs={12} md={12} container justify='space-between'>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          title='Additional Photo'
                          fieldName={`medium4.imageUrl`}
                          className={classes.featuredImage}
                          error={errors.medium4 && touched.medium4}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          fullWidth
                          name='medium4AltTag'
                          id='medium4AltTag'
                          label='Alt Tag'
                          required={values.medium4?.imageUrl!==""}
                          value={values.medium4?.altTag}
                          type='text'
                          helperText={errors.medium4AltTag && touched.medium4AltTag ? errors.medium4AltTag : 'Alt tag for SEO'}
                          error={errors.medium4AltTag && touched.medium4AltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          name='contentTitle5'
                          id='contentTitle5'
                          label='Fith Highlight Title'
                          value={values.contentTitle5}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentTitle5 && touched.contentTitle5}
                        />
                        <TextField
                          fullWidth
                          multiline
                          minRows={9}
                          variant='outlined'
                          name='contentDesc5'
                          id='contentDesc5'
                          label='Fith Highlight Paragraph'
                          value={values.contentDesc5}
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.contentDesc5 && touched.contentDesc5}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <br />

                  <Box mt={2} mb={2}>
                    <Grid item xs={12} container spacing={4} direction='row' alignItems='center'>
                      <Grid item>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            addItem(values.features, { image: { imageUrl: '' }, title: '', description: '' });
                            setFieldValue('features', values.features);
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant='h6'>Features</Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid spacing={4} container justify='space-around' direction='row'>
                    {values.features.map((feature, index) => (
                      <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Grid item xs={12} container direction='row' justify='space-between' alignContent='center'>
                          <Grid item>
                            <Typography variant='h8'>Feature {index + 1}</Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant='contained'
                              color='primary'
                              size='small'
                              onClick={() => {
                                removeItem(values.features, index);
                                setFieldValue('features', values.features);
                              }}
                            >
                              <RemoveIcon />
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container justify='space-between'>
                          <Grid item xs={12} md={6}>
                            <ImageUploadInput
                              title='Image'
                              fieldName={`features[${index}].image.imageUrl`}
                              className={classes.featuredImage}
                            />
                            <br></br>
                            <br></br>
                            <TextField
                              fullWidth
                              id='featureAltTag'
                              name={`features[${index}].image.altTag`}
                              label='Alt Tag'
                              required={values.features[index]?.image?.imageUrl!==""}
                              value={values.features[index].image?.altTag}
                              type='text'
                              onChange={handleChange}
                              helperText={errors.featureAltTag && touched.featureAltTag ? errors.featureAltTag : 'Alt tag for SEO'}
                              error={errors.featureAltTag && touched.featureAltTag}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              name={`features[${index}].title`}
                              label='Title'
                              required
                              value={values.features[index].title}
                              type='text'
                              onChange={handleChange}
                            />
                            <TextField
                              fullWidth
                              name={`features[${index}].description`}
                              label='Description'
                              value={values.features[index].description}
                              type='text'
                              onChange={handleChange}
                            />

                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>

                  <br />

                  <Box mt={2} mb={2}>
                    <Grid item xs={12} container spacing={4} direction='row' alignContent='center'>
                      <Grid item>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            addItem(values.galleryImages, {
                              image: { imageUrl: '' },
                              imageName: `GalImage${values.galleryImages.length + 1}`
                            });
                            setFieldValue('galleryImages', values.galleryImages);
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant='h6'>
                          Gallery Images{' '}
                          <span className={classes.headerNote}>(Dimensions 1200px x 600px or larger | 2:1 Aspect Ratio)</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid spacing={4} container justify='space-around' direction='row'>
                    {values.galleryImages.map((image, index) => (
                      <>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              removeItem(values.galleryImages, index);
                              setFieldValue('galleryImages', values.galleryImages);
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                          <ImageUploadInput title={'Gallery Image ' + (index + 1)} fieldName={`galleryImages[${index}].image.imageUrl`} />
                          <br></br>
                          <br></br>
                          <TextField
                            fullWidth
                            id='galleryAltTag'
                            name={`galleryImages[${index}].image.altTag`}
                            label='Alt Tag'
                            required={values.galleryImages[index]?.image?.imageUrl!==""}
                            value={values.galleryImages[index].image?.altTag}
                            type='text'
                            onChange={handleChange}
                            helperText={errors.galleryAltTag && touched.galleryAltTag ? errors.galleryAltTag : 'Alt tag for SEO'}
                            error={errors.galleryAltTag && touched.galleryAltTag}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    ))}
                  </Grid>

                  <br />

                  <Grid spacing={4} container justify='space-around' direction='row'>
                    <Grid item container justify='flex-end' xs={12}>
                      <Box mr={3}>
                        <Button
                          onClick={() => {
                            handleReset();
                            cancel();
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Button type='submit' variant='contained' color='primary' disabled={isSubmitting || isFormSubmit}>
                        {isSubmitting == true ? <CircularProgress /> : 'Save Brochure'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}
