import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, Box, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns/build/index';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { ColorPicker } from 'material-ui-color';

const useStyles = makeStyles({
  root: {
    border: '1px solid red',
  },
  colorPicker: {
    marginBottom: '16px',
  },
});

export default function Menu({ initialValues, onSubmit }) {
  const history = useHistory();
  const [formFields, setFormFields] = useState(initialValues);

  useEffect(() => {
    setFormFields(initialValues);
  }, [initialValues]);

  const classes = useStyles();

  const menWidthOptions = [
    { id: 1, name: 'Full' },
    { id: 2, name: 'Contained' }
  ];

  const dealerLogoOptions = [
    { id: 1, name: 'Left' },
    { id: 2, name: 'Right' },
    { id: 3, name: 'Center' }
  ];

  const menGroupLogoOptions = [
    { id: 1, name: 'Left' },
    { id: 2, name: 'Right' }
  ];

  const stickyOptions = [
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' }
  ];

  const menFontCaseOptions = [
    { id: 1, name: 'Upper' },
    { id: 2, name: 'Camel' },
    { id: 3, name: 'Sentence' }
  ];

  return (
    <Grid container spacing={4} direction="column">
      <Grid item xs={12}>
        <Formik
          initialValues={formFields}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => {
            const { values, touched, errors, handleBlur, handleChange, setFieldValue } = props;

            console.log(values)

            const handleColorChange = (field) => (color) => {
              setFieldValue(field, `#${color.hex}`);
            };

            return (
              <Form noValidate>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={6} direction="row">
                    <Grid item container md={6} xs={12}>
                      <Grid item xs={12}>
                        <TextField
                          id="menWidth"
                          name="menWidth"
                          label="Select Width"
                          value={values.menWidth}
                          select
                          fullWidth
                          helperText={errors.menWidth && touched.menWidth ? errors.menWidth : ''}
                          error={errors.menWidth && touched.menWidth}
                          onBlur={handleBlur}
                          onChange={handleChange} 
                        >
                          {menWidthOptions.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="menLogo"
                          name="menLogo"
                          label="Dealer Logo Orientation"
                          value={values.menLogo}
                          select
                          fullWidth
                          helperText={errors.menLogo && touched.menLogo ? errors.menLogo : ''}
                          error={errors.menLogo && touched.menLogo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {dealerLogoOptions.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="menGroupLogo"
                          name="menGroupLogo"
                          label="Select Group Logo Orientation"
                          value={values.menGroupLogo}
                          select
                          fullWidth
                          helperText={errors.menGroupLogo && touched.menGroupLogo ? errors.menGroupLogo : ''}
                          error={errors.menGroupLogo && touched.menGroupLogo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {menGroupLogoOptions.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="menSticky"
                          name="menSticky"
                          label="Select Sticky Orientation"
                          value={values.menSticky}
                          select
                          fullWidth
                          helperText={errors.menSticky && touched.menSticky ? errors.menSticky : ''}
                          error={errors.menSticky && touched.menSticky}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {stickyOptions.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          fullWidth
                          name="menFontSize"
                          id="menFontSize"
                          required
                          label="Font Size"
                          value={values.menFontSize}
                          type="number"
                          helperText={errors.menFontSize && touched.menFontSize ? errors.menFontSize : ''}
                          error={errors.menFontSize && touched.menFontSize}
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="menFontCase"
                          name="menFontCase"
                          label="Select Font Case"
                          value={values.menFontCase}
                          select
                          fullWidth
                          helperText={errors.menFontCase && touched.menFontCase ? errors.menFontCase : ''}
                          error={errors.menFontCase && touched.menFontCase}
                          onBlur={handleBlur}
                          onChange={handleChange} 
                        >
                          {menFontCaseOptions.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    
                    {/* Right side fields */}
                    <Grid item container md={6} xs={12}>
                      <Grid item xs={12}>
                        <Box className={classes.colorPicker}>
                          <label htmlFor="colorPickerMenBg">Background Color:</label>
                          <ColorPicker
                            id="colorPickerMenBg"
                            value={values.menBgCol}
                            onChange={handleColorChange('menBgCol')}
                          />
                        </Box>

                        <Box className={classes.colorPicker}>
                          <label htmlFor="colorPickerFont">Font Color:</label>
                          <ColorPicker
                            id="colorPickerFont"
                            value={values.secFontCol}
                            onChange={handleColorChange('secFontCol')}
                          />
                        </Box>

                        <Box className={classes.colorPicker}>
                          <label htmlFor="colorPickerFontHover">Font On-Hover Colour:</label>
                          <ColorPicker
                            id="colorPickerFontHover"
                            value={values.menFontOhCol}
                            onChange={handleColorChange('menFontOhCol')}
                          />
                        </Box>

                        <Box className={classes.colorPicker}>
                          <label htmlFor="colorPickerDropdownBg">Dropdown Background Colour:</label>
                          <ColorPicker
                            id="colorPickerDropdownBg"
                            value={values.menDropBgCol}
                            onChange={handleColorChange('menDropBgCol')}
                          />
                        </Box>

                        <Box className={classes.colorPicker}>
                          <label htmlFor="colorPickerDropdownFont">Dropdown Font Colour:</label>
                          <ColorPicker
                            id="colorPickerDropdownFont"
                            value={values.menDropFontCol}
                            onChange={handleColorChange('menDropFontCol')}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider variant="fullWidth" />
                    </Grid>

                    <Grid item container justifyContent="flex-end" xs={12}>
                      <Box mr={3}>
                        <Button onClick={() => history.push('/templates')}>Cancel</Button>
                      </Box>
                      <Button type="submit" variant="contained" color="primary">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
