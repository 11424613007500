import { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
import { DealerContext } from '../../shared/DealerContext';
// import moment from 'moment'

export default function TemplateFilter(searchString, pageNumber, pageSize, order, orderBy) {
  const { dealerId, selectedMotorGroup: selectedDealer  } = useContext(DealerContext);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [templates, setTemplates] = useState([])
  const [total, setTotal] = useState(0)


  useEffect(() => {


    setError(false);

    const params = {
      pageNumber: pageNumber + 1,
      pageSize,
      searchString,
      dealerId,
      motorgroupId: selectedDealer.motorgroupID
    }

    if (order) {
      params.order = order.toUpperCase();
    }

    if (orderBy) {
      params.orderBy = orderBy
    }

    if(dealerId!==""){
      setLoading(true);
    axios({
      method: 'GET',
      url: `${BASE_URI}/WebsiteTemplates`,
      params
    }).then((response) => {

      setLoading(false);
      setTemplates(response?.data?.list || [])
      setTotal(response.data.total || 0)

    }).catch(error => {

      setLoading(false);

      if (axios.isCancel(error))
        return;

      if (error.response) {
        setError(true);
      }
    })
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString,pageNumber, pageSize, order, orderBy, dealerId])

  return { loading, error, templates, total }
}