import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { BASE_URI, BASE_URI_WEPAY4CARS } from "../../../../shared/Constants";
import { useParams } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import { useSnackbar } from "notistack";
import VehicleDetails from "..//../../../shared/VehicleDetails";
import CardHeader from "@material-ui/core/CardHeader";

import LoaderComponent from "../../../../shared/LoaderComponent";

import Divider from "@material-ui/core/Divider";

import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  card: {
    background: "lightblue",
  },
  approveloader: {
    height: "100px",
    position: "inherit",
  },
}));

export default function TradeInTabContainer() {
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState(false);

  const [bids, setBids] = useState([]);
  const [tradins, setTradins] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [makeId, setMakeId] = useState({});
  const [modelId, setModelId] = useState({});
  const [displayOther, setDisplayOther] = useState(false);
  const [tradeInDetails, setTradeInDetails] = useState(null);
  const [tradeInImages, setTradeInImages] = useState(null);
  const [tradeinState, setTradeInState] = useState(null);
  const [lead, setLead] = useState(null);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const GetLead = async () => {
      try {
        const leadResult = await axios.get(`${BASE_URI}/Leads/${id}`, {
          cancelToken: source.token,
        });
        setLead(leadResult?.data);
      } catch (error) {
        console.error(error);
      }
    };

    GetLead();

    const getTradinInfo = async () => {
      try {
        const result = await axios.get(
          `${BASE_URI}/TradeApplication/leadId/${id}`,
          {
            cancelToken: source.token,
          }
        );
        //formData.isAddMode = isAddMode;
        setTradins(result.data.list);
        const tradeindetailsResult = await axios.get(
          `${BASE_URI}/TradeInVehicle/lead/${id}`,
          {
            cancelToken: source.token,
          }
        );
        setTradeInDetails(tradeindetailsResult?.data);
      } catch (error) {
        //enqueueSnackbar("Unable to get slide details", { variant: "error" });
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    getTradinInfo();
  }, []);
  useEffect(() => {
    if (tradeInDetails) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const getTradinImages = async () => {
        try {
          const tradeinimagesResult = await axios.get(
            `${BASE_URI}/TradeInVehicle/images/${tradeInDetails?.id}`,
            {
              cancelToken: source.token,
            }
          );
          setTradeInImages(tradeinimagesResult?.data);
          const data = JSON.parse(localStorage.getItem("tmpUser"));
          const tradeinstateResult = await axios.get(
            `${BASE_URI_WEPAY4CARS}/Vehicle/state`,
            {
              params: {
                vehicleListingId: tradeInDetails?.wePay4CarsListingId,
              },
              cancelToken: source.token,
            }
          );
          setTradeInState(tradeinstateResult?.data);
          const vehicleBidsResult = await axios.get(
            `${BASE_URI_WEPAY4CARS}/Bid/listingBids`,
            {
              params: {
                vehicleListingId: tradeInDetails?.wePay4CarsListingId,
              },
              cancelToken: source.token,
            }
          );
          setBids(vehicleBidsResult?.data);
        } catch (error) {
          //enqueueSnackbar("Unable to get slide details", { variant: "error" });
          // addAlert('Unables to load vehicle categories')
          //history.push('..')
        }
      };

      getTradinImages();
    }
  }, [tradeInDetails]);

  const ApproveTradeIn = () => {
    if (tradeInDetails?.id) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const getTradinInfo = async () => {
        try {
          setConfirmLoading(true);
          const tradeinimagesResult = await axios.post(
            `${BASE_URI_WEPAY4CARS}/Vehicle/approve`,
            null,
            {
              params: {
                vehicleListingId: tradeInDetails?.wePay4CarsListingId,
              },
            }
          );
          setTradeInState(tradeinimagesResult?.data);
          enqueueSnackbar("Trade in vehicle approved", { variant: "success" });
          setConfirmLoading(false);
        } catch (error) {
          if (axios.isCancel(error)) return;
          enqueueSnackbar(
            "Unable to process your request, please try again later",
            { variant: "error" }
          );
          setConfirmLoading(false);
        }
      };
      getTradinInfo();
    } else {
      enqueueSnackbar("Missing Trade In Vehicle Details", { variant: "error" });
    }
  };

  useEffect(() => {
    let cancel;
    axios({
      method: "GET",
      url: `${BASE_URI}/makes`,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        if (response.data?.list) {
          var data = response.data?.list;
          data.push({ id: -1, name: "OTHER" });

          setMakeData(data);
        }

        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        if (error.response) {
          setError(true);
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);
    const params = {};
    params.makeId = makeId;

    axios({
      method: "GET",
      url: `${BASE_URI}/models`,
      params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        console.log(response.data);
        if (response.data?.list) {
          setModelData(response.data?.list);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        if (error.response) {
          setError(true);
        }
        setLoading(false);
      });
  }, [makeId]);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);
    const params = {};
    params.modelId = modelId;

    axios({
      method: "GET",
      url: `${BASE_URI}/variants`,
      params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        console.log(response.data);
        if (response.data?.list) {
          setVariantData(response.data?.list);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        if (error.response) {
          setError(true);
        }
        setLoading(false);
      });
  }, [modelId]);

  const headCells = [
    { id: "dealer", label: "Dealer", sort: true },
    { id: "amount", label: "Bid Amount", sort: true },
    { id: "accept", label: "Accept Bid", sort: true },
  ];

  const values = {
    make: "",
    makeId: "",
    model: "",
    modelId: "",
    variant: "",
    variantId: "",
    price: "",
    other: "",
  };

  const handleMakeChange = (e) => {
    setModelData([]);

    if (e != null) {
      if (e.id == -1) {
        setDisplayOther(true);
      } else {
        setMakeId(e.id);
        setDisplayOther(false);
      }
    }
  };

  const handleModelChange = (e) => {
    setVariantData([]);

    if (e != null) setModelId(e.id);
  };

  const createTradeIn = (values) => {
    let vehicleParam = {};
    vehicleParam.LeadId = parseInt(id);
    vehicleParam.VariantId = values.variantId;
    vehicleParam.ModelId = values.modelId;
    vehicleParam.MakeID = values.makeId;
    vehicleParam.Price = parseInt(values.price);
    vehicleParam.Other = values.other;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: "POST",
      url: `${BASE_URI}/TradeApplication`,
      data: vehicleParam,
      cancelToken: source.token,
    })
      .then((responseSecond) => {
        enqueueSnackbar(`Successfully added a Trade-in`, {
          variant: "success",
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        enqueueSnackbar("Unable to get complete the request", {
          variant: "error",
        });
        //setLoader(false);
      });
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={values}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            createTradeIn({ ...values, leadId: id });
            actions.setSubmitting(true);
          }, 100);
        }}
      >
        {(props) => {
          return (
            <Form>
              <Card className={classes.stepPadding}>
                <CardContent>
                  <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                    Get a valuation for a Trade-in vehicle
                  </Typography>
                  <Grid container direction="row">
                    <Grid item className={classes.stepPadding} xs={12} md={4}>
                      <Autocomplete
                        id="make"
                        name="make"
                        value={{
                          id: props.values.makeId.toString(),
                          name: props.values.make.toString(),
                        }}
                        options={makeData}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => {
                          return option.name === value.name;
                        }}
                        onChange={(event, value) => {
                          props.setFieldValue("make", value ? value.name : "");
                          props.setFieldValue("makeId", value ? value.id : "");
                          props.setFieldTouched("make", true, false);
                          props.setFieldTouched("makeId", true, false);
                          handleMakeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              props.touched.make && Boolean(props.errors.make)
                            }
                            helperText={
                              props.touched.make ? props.errors.make : ""
                            }
                            label="Select A Make"
                          />
                        )}
                      />
                    </Grid>
                    {!displayOther && (
                      <>
                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={3}
                          lg={3}
                        >
                          <Autocomplete
                            id="model"
                            name="model"
                            value={{
                              id: props.values.modelId.toString(),
                              title: props.values.model.toString(),
                            }}
                            options={modelData}
                            disabled={modelData.length <= 0}
                            getOptionLabel={(option) => {
                              return option.title;
                            }}
                            getOptionSelected={(option, value) => {
                              return option.title === value.title;
                            }}
                            onChange={(event, value) => {
                              props.setFieldValue(
                                "model",
                                value ? value.title : ""
                              );
                              props.setFieldValue(
                                "modelId",
                                value ? value.id : ""
                              );
                              props.setFieldTouched("model", true, false);
                              props.setFieldTouched("modelId", true, false);

                              handleModelChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  props.touched.model &&
                                  Boolean(props.errors.model)
                                }
                                helperText={
                                  props.touched.model ? props.errors.model : ""
                                }
                                label="Select A Model"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={3}
                          lg={3}
                        >
                          <Autocomplete
                            id="variant"
                            name="variant"
                            value={{
                              id: props.values.variantId.toString(),
                              variantName: props.values.variant.toString(),
                            }}
                            options={variantData}
                            disabled={variantData.length <= 0}
                            getOptionLabel={(option) => option.variantName}
                            getOptionSelected={(option, value) => {
                              return option.variantName === value.variantName;
                            }}
                            onChange={(event, value) => {
                              props.handleChange(event);
                              props.setFieldValue(
                                "variant",
                                value ? value.variantName : ""
                              );
                              props.setFieldValue(
                                "variantId",
                                value ? value.id : ""
                              );
                              props.setFieldTouched("variant", true, false);
                              props.setFieldTouched("variantId", true, false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  props.touched.variant &&
                                  Boolean(props.errors.variant)
                                }
                                helperText={
                                  props.touched.variant
                                    ? props.errors.variant
                                    : ""
                                }
                                label="Select A Variant"
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {displayOther && (
                      <Grid item className={classes.stepPadding} xs={12} md={2}>
                        <TextField
                          id="other"
                          name="other"
                          label="Make"
                          value={props.values.other}
                          helperText={
                            props.touched.other ? props.errors.other : ""
                          }
                          error={
                            props.touched.other && Boolean(props.errors.other)
                          }
                          onChange={(event) => {
                            props.handleChange(event);
                            props.setFieldTouched("other", true, false);
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="price"
                        name="price"
                        label="Price"
                        value={props.values.price}
                        helperText={
                          props.touched.price ? props.errors.price : ""
                        }
                        error={
                          props.touched.price && Boolean(props.errors.price)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("price", true, false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid item className={classes.stepPadding}>
                <Button
                  variant="contained"
                  style={{ margin: "1rem" }}
                  startIcon={<AddIcon />}
                  color="primary"
                  type="submit"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: "1rem" }}
                  startIcon={<AddIcon />}
                  color="primary"
                  onClick={props.resetForm}
                >
                  Reset
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {/*<TableContainer>
                <Table aria-label="slides table" size="small">
                    <TableHeadersSort
                        headCells={headCells}
                    />
                    <TableBody>
                        {tradins.map((row) => (
                            <TableRow hover>
                                <TableCell>{row.make}</TableCell>
                                <TableCell>{row.model}</TableCell>
                                <TableCell>{row.variant}</TableCell>
                                <TableCell>{'R' + row.price}</TableCell>
                            </TableRow>
                        ))}
                        {(!tradins.length || tradins.length === 0) && (
                            <TableRow>
                                <TableCell colSpan={headCells.length} align="center" style={{ borderBottom: 0 }} className="py-3">
                                    <Typography variant="h6" color="textSecondary">
                                        No Tradeins Available
									</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                        </TableContainer>*/}
      <Card className="d-flex justify-content-between flex-column">
        {/*{loading &&
      <LoaderComponent/>}*/}
        <CardHeader className="p-4" title={"Trade In Vehicle Details"} />

        <React.Fragment>
          <CardContent style={{ display: "flex", width: "100%" }}>
            <VehicleDetails
              lead={lead}
              vehicleDetails={tradeInDetails ? tradeInDetails : {}}
              images={tradeInImages ? tradeInImages : {}}
            />
            <Divider />
          </CardContent>
          {confirmLoading ? (
            <CardActions className={classes.approveloader}>
              <LoaderComponent />
            </CardActions>
          ) : (
            <CardActions className={classes.greyBackground}>
              {tradeinState && tradeinState?.id === 0 && (
                <Grid container justify="flex-end" alignItems="center">
                  <Button
                    variant="contained"
                    onClick={() => ApproveTradeIn()}
                    color="primary"
                  >
                    Approve
                  </Button>
                </Grid>
              )}
            </CardActions>
          )}
        </React.Fragment>
      </Card>
      {tradeinState && tradeinState?.id === 1 && (
        <TableContainer>
          <Table aria-label="slides table" size="small">
            <TableHeadersSort headCells={headCells} />
            <TableBody>
              {bids.map((row) => (
                <TableRow hover>
                  <TableCell>{row?.dealerName}</TableCell>
                  <TableCell>{"R" + row?.amount}</TableCell>
                  <TableCell>
                    <Grid container justify="flex-center" alignItems="center">
                      <Button
                        variant="contained"
                        onClick={null}
                        color="primary"
                      >
                        Accept
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {(!bids.length || bids.length === 0) && (
                <TableRow>
                  <TableCell
                    colSpan={headCells?.length}
                    align="center"
                    style={{ borderBottom: 0 }}
                    className="py-3"
                  >
                    <Typography variant="h6" color="textSecondary">
                      No Bid Available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
