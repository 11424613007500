import React from "react";
import ImageUploader from './ImageUploader'
import ImageService from "./ImageService";
import { Field } from "formik";

const ImageUploadInput = ({ title, fieldName, error, required, disabled, errorMsg, className, setImage }) => (
    <Field name={fieldName} id={fieldName} type="text">
        {({ field: { value }, form: { setFieldValue } }) => (
            <ImageUploader title={title}
                disabled={disabled}
                required={required}
                className={className}
                onDropFile={async (file) => {
                    let uri = await ImageService.getBase64(file);
                    const imageObj = { image: uri, fileName: file.path }
                    setFieldValue(fieldName, imageObj)
                    if (setImage) setImage(fieldName, imageObj)

                }}
                id={fieldName}
                image={value}
                error={error}
                errorMsg={errorMsg}
                clear={() => setFieldValue(fieldName, '')} />
        )}
    </Field>
);

export default ImageUploadInput;