import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import {DEFAULT_PAGE_SIZE} from '../../shared/Constants'
import StateListFilter from './StateListFilter'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function StateAdminTable() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('title');
  const [searchString, setSearchString] = useState('');
  const{
    loading,
    states,
    total
  }= StateListFilter(searchString, page, pageSize, order, orderBy)

  const headCells = [
    { id: 'name', label: 'Name', sort: true },
    { id: 'handledBy', label: 'Handled By', sort: true }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleChange = (event) => {
    setSearchString(event.target.value)
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction="column">
          <Grid item xs={12}>
            <Toolbar disableGutters>
              <Typography style={{flex: '1 1'}} variant="h4" id="tableTitle" component="div">
                States
              </Typography>
              <TextField
                name="stateFilter"
                required
                id="stateFilter"
                label="Search State Names"
                value={searchString}
                type="text"
                InputProps={{
                  endAdornment: searchString ?  (
                    <InputAdornment position="end">
                      <IconButton size="small" disableFocusRipple
                                  disableRipple onClick={()=> setSearchString('')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ): '',
                }}
                onChange={handleChange}
              />
              <Button onClick={() => history.push('/admin/states/add')}
                      variant="contained"
                      style={{marginLeft: '2rem'}}
                      startIcon={<AddIcon />}
                      color="primary">
                State
              </Button>
            </Toolbar>

            <TableContainer>
              <Table aria-label="states table">
                <TableHeadersSort
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    states.map((row) =>(
                      <Tooltip title={`Edit`}key={row.id} placement="left">
                        <TableRow hover
                                  onClick={() => history.push(`/admin/states/edit/${row.id}`)}
                                  className={classes.hoverTableCell}>
                          <TableCell>
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {row.handledBy}
                          </TableCell>
                        </TableRow>
                      </Tooltip>
                    ))
                  }
                  {
                    ((!states.length || states.length === 0) && !loading) &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}
                                 className="py-3">
                        <Typography variant="h6" color="textSecondary">
                          No States created
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    loading &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                page={page}
                rowsPerPageOptions={[5,10,25]}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}