import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Divider,
    Paper,
    CircularProgress
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI } from '../../shared/Constants'
import ImageUploadInput from '../../shared/ImageUploadFormInput'
import { TextField as TextFieldFormik } from "formik-material-ui";
import { useSnackbar } from "notistack";
import MenuItem from '@material-ui/core/MenuItem';
import { TailSpin } from 'react-loader-spinner';
import { height } from '@material-ui/system';


const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
    , td: {
        width: 'calc(100%/4)',

        textAlign: 'left',
    }, th: {
        width: 'calc(100%/4)',
        minWidth: '200px',
        textAlign: 'left',
    },
    btn: {
        borderRadius: '5px',
        background: '#3f51b5',
        padding: '6px 30px',
        border: 'none',
        marginRight: '10px',
        color: 'white',
        "&:hover": {
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }
    },
    PopUp: {
        position: 'absolute',
        top: '0',
        zIndex: '555',
        background: '#030303bd',
        width: '100%',
        left: '0',
        bottom: '0',
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(1px)',
    },
    PopUpCard: {


        background: '#ffffff',
        padding: '40px',
        width: '30%',
        borderRadius: '5px',
        overflow: 'auto',
        height: '70%',
    },
    PopUpCardDlt: {
        background: '#ffffff',
        padding: '20px',
        width: '30%',
        borderRadius: '5px',
    },
    btnGroup: {
        marginTop: "20px",
        display: 'flex',
        justifyContent: 'end',
    },
    btnGroupTable: {
        marginTop: "0px",
        display: 'flex',
        justifyContent: 'end',
    },
    AddStaff: {
        marginBottom: "20px",
        display: 'flex',
        justifyContent: 'end',

    },
    bold: {
        fontWeight: 'bolder'
    },
    btnPopUp: {
        background: '#3f51b5',
        padding: '6px 30px',
        border: 'none',
        marginLeft: '10px',
        color: 'white',
        borderRadius: '5px',
        "&:hover": {
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }
    },
    avatar:{
        width: '60px',
        display: 'flex',
        height: '60px',
        background: (dealerColors)=>dealerColors?.primaryColor? dealerColors?.primaryColor : "#3f51b5",
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textTransform: 'uppercase',
    }

}));

export default function DealerStaff({ history, data }) {

    const initialValues = {
        selectedOption: null,
        photoUrl: '',
        fullName: '',
        email: '',
        contactNumber: '',
        selectedDepartment: '',
        selectedJobTitle: '',
        id: 0
    }
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState(data ? data : initialValues)
    const [previewImg, setPreview] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [addStaff, setAddStaff] = useState(false);
    const [editStaff, setEditStaff] = useState(false);
    const [dltStaff, setDltStaff] = useState(false);
    const [dealerColors, setDealerColors] = useState();
    const [staffList, setStaffList] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [active, setActive] = useState({});

    let staffObj = {
        photoUrl: active ? active?.photoUrl : ' ',
        fullName: active ? active?.fullName : ' ',
        email: active ? active?.email : ' ',
        contactNumber: active ? active?.contactNumber : ' ',
        department: active ? active?.department : ' ',
        jobTitle: active ? active?.jobTitle : ' '
    }

    const classes = useStyles(dealerColors);

    useEffect(() => {
        getData();

    }, []);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getData = () => {
        axios({
            method: 'GET',
            url: `${BASE_URI}/DealerStaff`,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setStaffList(response.data.filter((s)=> s.dealerId == id))

                setLoading(false)
                axios({
                    method: 'GET',
                    url: `${BASE_URI}/DealerStaff/Department`,
                    cancelToken: source.token
                }).then((response) => {
                    if (response.data) {
                        setDepartments(response.data)

                        axios({
                            method: 'GET',
                            url: `${BASE_URI}/DealerStaff/JobTitle`,
                            cancelToken: source.token
                        }).then((response) => {

                            setJobTitles(response.data)
                        })
                    }
                }).catch((err) => {

                    enqueueSnackbar("Error", { variant: 'Error' });
                })
            }
        })
    }

    const getColors = () => {
        axios({
            method: 'GET',
            url: `${BASE_URI}/DealerWebsites/Colors/${id}`,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
            setDealerColors(response.data)

            }
        })
    }

    useEffect(() => {
        getColors()
        let arr = []
        for (let index = 0; index < staffList.length; index++) {
            const element = staffList[index];
            let department = departments.filter((d) => d.id == element.departmentId)
            element.department = department[0]
            arr.push(element)
        }

        setStaffList(arr.flat())

    }, [departments, loading])

    useEffect(() => {
        let arr = []
        for (let index = 0; index < staffList.length; index++) {
            const element = staffList[index];
            let jobTitle = jobTitles.filter((d) => d.id == element.jobTitleId)
            element.jobTitle = jobTitle[0]
            arr.push(element)
        }

        setStaffList(arr.flat())

    }, [jobTitles, loading])

    const handleStaffDelete = (e, data) => {
            setActive(data)
            setDltStaff(true)
    }

    const handleStaffAdd = () => {
        setActive({})
        setAddStaff(true)
    }


    const DltStaff = async (data) => {
        try {
            setLoading(true);
          const response = await axios.delete(`${BASE_URI}/DealerStaff/Staff/${data.id}`).then((res)=>{
            if (res.data) {
                setStaffList(staffList.filter((s) => s.id !== data.id));
                setLoading(false);
              }
          })

        } catch (err) {
          enqueueSnackbar('Error', { variant: 'error' });
        }
      };

    function onSubmit(fields) {

        switch (editStaff) {
            case true:
                let staffObj = {
                    id: active?.id,
                    fullName: fields?.fullName,
                    email: fields?.email,
                    contactNumber: fields?.contactNumber,
                    dealerId: fields?.dealerId,
                    departmentId: fields?.department?.id,
                    jobTitleId: fields?.jobTitle?.id,
                    department: fields?.department,
                    jobTitle: fields?.jobTitle,
                    photoUrl: fields?.photoUrl.image,
                    fileName: fields?.photoUrl.fileName,
                }

                axios({
                    method: 'PUT',
                    url: `${BASE_URI}/DealerStaff/UpdateStaff/${active?.id}`,
                    data: staffObj,
                    cancelToken: source.token
                }).then((response) => {

                    if (response.data) {

                        getData()
                        enqueueSnackbar("Updated" + " " + response?.data?.fullName, { variant: 'success' });

                        setEditStaff(false)
                    }

                }).catch((err) => {

                    enqueueSnackbar("Error", err, { variant: 'error' });
                })
                break;

            case false:

                    setLoading(true)
                    fields.dealerId = parseInt(id);
                    fields.departmentId = fields.department.id;
                    fields.jobTitleId = fields.jobTitle.id;
                    //  temporary variables for the properties
                    const department = fields.department;
                    const jobTitle = fields.jobTitle;
                    const photoUrl = fields.photoUrl;
                    // Assign the modified properties
                    fields.department = department;
                    fields.jobTitle = jobTitle;
                    fields.photoUrl = photoUrl.image;
                    const fileName = photoUrl.fileName;
                    fields.fileName = fileName;

                axios({
                    method: 'POST',
                    url: `${BASE_URI}/DealerStaff/AddStaff`,
                    data: fields,
                    cancelToken: source.token
                }).then((response) => {

                        if (response.data) {

                            let staffObj = {
                                id: response?.data?.id,
                                photoUrl: response?.data?.photoUrl,
                                fullName: response?.data?.fullName,
                                email: response?.data?.email,
                                contactNumber: response?.data?.contactNumber,
                                dealerId: response?.data?.dealerId,
                                departmentId: response?.data?.departmentId,
                                jobTitleId: response?.data?.jobTitleId,
                                department: fields?.department,
                                jobTitle: fields?.jobTitle,
                            }

                            setStaffList([...staffList, staffObj])
                            enqueueSnackbar("Added" + " " + response?.data?.fullName, { variant: 'success' });
                            setLoading(false)
                            setAddStaff(false)
                            setActive(staffObj)
                        }

                }).catch((err) => {
                    enqueueSnackbar("Error", err, { variant: 'error' });
                })

                break;

            default:
                break;
        }

    }

    const onDepartmentChanged = (e, c) => {
        staffObj.department = e?.target?.value;
    };

    const onJobChanged = (e, c) => {
        staffObj.jobTitle = e?.target?.value;

    };

    const handleStaffEdit = (data) => {
        setActive(data)
        setLoading(false)
        staffObj.fullName = data.fullName
        setEditStaff(true)
    }

    const handlePopUpResponse = (answer) => {
        if (answer === 'Yes') {
          DltStaff(active).then(() => {
            enqueueSnackbar(`Deleted ${active.fullName}`, { variant: 'success' });
          });
        }
        setDltStaff(false);
      }

    const handleClose = (e) => {
        setEditStaff(false)
        setAddStaff(false)
        setDltStaff(false)
    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                <Grid item xs={12}>
                    <Grid item xs={12} md={12}>
                        <div className={classes.AddStaff}>
                            <Button
                                className={classes.btn}
                                variant="contained"
                                color="primary"
                                onClick={() => { handleStaffAdd() }}
                            >
                                Add Staff +
                            </Button>

                        </div>
                        {dltStaff &&
                            <div className={classes.PopUp}>
                                <div className={classes.PopUpCardDlt}>
                                    <Typography variant="body1" gutterBottom>
                                        Are you sure you want to delete <span className={classes.bold}>{active.fullName}</span> ?
                                    </Typography>

                                    <div className={classes.btnGroup}>
                                        <Button
                                            className={classes.btnPopUp}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handlePopUpResponse("Yes")}
                                        >
                                        Yes
                                        </Button>
                                        <Button
                                            className={classes.btnPopUp}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { handlePopUpResponse("No") }}
                                        >
                                            No
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        }
                        {(addStaff || editStaff) &&
                            <div className={classes.PopUp}>
                                <div className={classes.PopUpCard}>
                                    <Typography variant="body1" gutterBottom>
                                        {addStaff ? "Add Staff" : "Edit Staff"}
                                    </Typography>

                                    <Formik
                                        initialValues={staffObj}
                                        enableReinitialize={true}
                                        onSubmit={(values, actions) => {
                                            onSubmit(values)
                                            setTimeout(() => {
                                                actions.setSubmitting(false)
                                            }, 500)
                                        }}
                                        validationSchema={Yup.object().shape({

                                        })}
                                    >
                                        {(props) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                handleBlur,
                                                handleChange,
                                                isSubmitting,
                                                handleReset,
                                                setFieldValue
                                            } = props
                                                
                                            let sortedDepartments = departments.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
                                            let sortedJobTitles = jobTitles.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));


                                            return (
                                                <Form>

                                                    <Box mt={2} className={classes.AddDealer}>
                                                        <ImageUploadInput
                                                            title='Photo'
                                                            value={staffObj?.photoUrl}
                                                            fieldName={'photoUrl'}
                                                        />

                                                    </Box>
                                                    <Box mt={2} className={classes.AddDealer}>
                                                        <TextField
                                                            fullWidth
                                                            name="fullName"
                                                            required
                                                            id="fullName"
                                                            label="Full Name"
                                                            value={values.fullName}

                                                            helperText={
                                                                errors.fullName && touched.fullName ? errors.fullName : ''
                                                            }
                                                            error={errors.fullName && touched.fullName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />

                                                    </Box>
                                                    <Box mt={2} className={classes.AddDealer}>

                                                        <TextField
                                                            fullWidth
                                                            name="email"
                                                            id="email"
                                                            label="Email"
                                                            value={values.email}
                                                            type="text"
                                                            helperText={
                                                                errors.email && touched.email ? errors.email : ''
                                                            }
                                                            error={errors.email && touched.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Box>
                                                    <Box mt={2} className={classes.AddDealer}>
                                                        <TextField
                                                            fullWidth
                                                            name="contactNumber"
                                                            required
                                                            id="contactNumber"
                                                            label="Contact Number"
                                                            value={values.contactNumber}
                                                            type="text"
                                                            helperText={
                                                                errors.contactNumber && touched.contactNumber ? errors.contactNumber : ''
                                                            }
                                                            error={errors.contactNumber && touched.contactNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Box>

                                                    <Box mt={2} className={classes.AddDealer}>
                                                        <TextField
                                                            fullWidth
                                                            name="department"
                                                            select
                                                            id="department"
                                                            label="Select A Department"
                                                            value={staffObj.department}
                                                            type="text"
                                                            helperText={
                                                                errors.department && touched.department ? errors.department : ''
                                                            }
                                                            error={errors.department && touched.department}
                                                            onChange={(event, child) => {
                                                                onDepartmentChanged(event, child);
                                                                handleChange(event)
                                                            }}
                                                            onBlur={handleBlur}
                                                        >

                                                            {sortedDepartments.map((department) => (

                                                                <MenuItem value={department}>

                                                                    {department.name}

                                                                </MenuItem>

                                                            ))}

                                                        </TextField>
                                                    </Box>

                                                    <Box mt={2} className={classes.AddDealer}>
                                                        <TextField
                                                            fullWidth
                                                            name="jobTitle"

                                                            select
                                                            id="jobTitle"
                                                            label="Select A Job Title"
                                                            value={staffObj.jobTitle}
                                                            type="text"
                                                            helperText={
                                                                errors.jobTitle && touched.jobTitle ? errors.jobTitle : ''
                                                            }
                                                            error={errors.jobTitle && touched.jobTitle}
                                                            onChange={(event, child) => {
                                                                onJobChanged(event, child);
                                                                handleChange(event)
                                                            }}
                                                            onBlur={handleBlur}
                                                        >

                                                            {sortedJobTitles.map((job) => (

                                                                <MenuItem value={job}>

                                                                    {job.name}

                                                                </MenuItem>
                                                            ))}

                                                        </TextField>
                                                    </Box>

                                                    <div className={classes.btnGroup}>
                                                        {!loading ?

                                                            <Button
                                                                className={classes.btn}
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                value="Add"
                                                            >
                                                                {addStaff ? "Add" : "Edit"}
                                                            </Button>
                                                            : <div style={{ marginRight: '20px' }}>
                                                                <TailSpin ariaLabel="loading-indicator" color='#3f51b5' height="40px" width="40px" />
                                                            </div>
                                                        }

                                                        <Button
                                                            className={classes.btn}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => { handleClose() }} value="Cancel"
                                                        >
                                                            Cancel
                                                        </Button>

                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        }
{loading  &&
     <div style={{ display: 'flex', justifyContent: 'center' }}><TailSpin ariaLabel="loading-indicator" color='#3f51b5' /></div>
}
                        {!loading  && staffList.length > 0 &&
                            <div style={{ overflow: 'auto' }}>
                                <table border='1' cellPadding='5px' width="100%" >
                                    <tbody>
                                        <th >Photo</th>
                                        <th className={classes.th}>Name & Surname</th>
                                        <th className={classes.th}>Email</th>
                                        <th className={classes.th}>Department Name</th>
                                        <th className={classes.th}>Job Title Name</th>
                                        <th className={classes.th}> </th>

                                        {staffList?.map((staff) => {
                                            return (
                                                <>
                                                    <tr>
                                                    <tr>
                                                    <td>
                                                        {staff && (staff.photoUrl ? (
                                                            <img src={staff.photoUrl} width='60px' height='60px' />
                                                        ) : (
                                                            <div className={classes.avatar}>
                                                                {staff?.fullName.split(/\s(.+)/)[0]?.charAt(0)} {staff?.fullName.split(/\s(.+)/)[1]?.charAt(0)}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    </tr>
                                                        <td className={classes.td}>{staff?.fullName}</td>
                                                        <td className={classes.td}>{staff?.email}</td>
                                                        <td className={classes.td}>{staff?.department?.name}</td>
                                                        <td className={classes.td}>{staff?.jobTitle?.name}</td>
                                                        <td className={classes.td}>
                                                            <div className={classes.btnGroupTable}>
                                                                <Button
                                                                    className={classes.btn}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { handleStaffEdit(staff) }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                                <Button
                                                                    className={classes.btn}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(e) => { handleStaffDelete(e, staff) }}
                                                                >
                                                                    Delete
                                                                </Button>

                                                            </div>
                                                        </td></tr>
                                                </>
                                            )
                                        })} </tbody>
                                </table>
                            </div> }
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}