import React, { useContext } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Divider, Typography } from "@material-ui/core";
import { TabsContext } from "../../../../shared/contexts/TabsContext"
import { LeadStatusEnum } from "../../../../shared/Constants"
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "2rem",
        marginLeft: "2rem",
    },
    buttonSendBack:{
        marginTop: "2rem",
        marginLeft: "2rem",
        backgroundColor:"#f8cf01",
        color:"black"
    },
    buttonSent:{
        marginTop: "2rem",
        marginLeft: "2rem",
        backgroundColor:"#04872d",
        color:"white"
    },
    line: {
        marginTop: "3rem",
        marginBottom: "1rem",
        height: 2
    },
    title: {
        marginTop:"2rem"
    }
}));

export default function DocumentsInContainer() {
    const classes = useStyles();
    let { updateState } = useContext(LeadMainContext);
    let { ...tabContext } = useContext(TabsContext);

    const values = {
        sendTo: "",
    };
    return (
        <div className={classes.root}>

            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6">Review Documents</Typography>
                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={3}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => tabContext.setSelectedTab(4)}
                                    >
                                        Review the Documents
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3} >
                                    <Button
                                        className={classes.buttonSendBack}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Application Approved"])}
                                    >
                                        Send it back - incomplete!
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={12}>
                                    <Formik
                                        initialValues={values}
                                        isInitialValid={false}
                                        onSubmit={(values, actions) => {
                                            setTimeout(() => {
                                                console.log(values);
                                                actions.setSubmitting(true);
                                            }, 100);
                                        }}
                                    >
                                        {(props) => {
                                            return (
                                                <Form>
                                                    <Grid container direction="row">
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" className={classes.title}>
                                                                Send To
                                                            </Typography>
                                                            <Grid container direction="row">
                                                                <Grid
                                                                    item
                                                                    className={classes.stepPadding}
                                                                    xs={12}
                                                                    md={8}
                                                                    lg={6}
                                                                >
                                                                    <TextField
                                                                        id="sendTo"
                                                                        name="sendTo"
                                                                        label="Send to"
                                                                        fullWidth
                                                                        multiline
                                                                        minRows={1}
                                                                        rowsMax={5}
                                                                        value={props.values.sendTo}
                                                                        helperText={
                                                                            props.touched.sendTo ? props.errors.sendTo : ""
                                                                        }
                                                                        error={
                                                                            props.touched.sendTo &&
                                                                            Boolean(props.errors.sendTo)
                                                                        }
                                                                        onChange={(event) => {
                                                                            props.handleChange(event);
                                                                            props.setFieldTouched("sendTo", true, false);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={4} lg={4}>
                                                                    <Button
                                                                        className={classes.buttonSent}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        type="submit"
                                                                        disabled={!props.isValid}
                                                                        onClick={() => updateState(LeadStatusEnum["Bank Submitted"])}
                                                                    >
                                                                        Submit to Bank
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            );
                                        }}
                                    </Formik>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6">Not Taken Up</Typography>

                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={5}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Not Taken Up"])}
                                    >
                                        Mark this lead as Not Taken Up
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6">Review application or submit</Typography>

                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => tabContext.setSelectedTab(3)}
                                    >
                                        Review the Application
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.buttonSendBack}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Application Approved"])}
                                    >
                                        Send it back - incomplete!
                                    </Button>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
