import React, { useEffect, useState, useContext } from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import {Field} from "formik";
import SelectInput from "../../AutocompleteContainer/AutoCompleteComponent";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { BASE_URI, LeadTypeEnum } from "../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DealerContext } from "../../../shared/DealerContext";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    card: {
        background: "lightblue",
        width: "135%",
    },
}));

export default function AddLeadForm(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [variants, setVariants] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [sources, setSources] = useState([]);
    const [leadTypes, setLeadTypes] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const { dealerArr } = useContext(DealerContext);

    const {
        values: {
            name,
            phone,
            email,
            notes,
            dealerName,
            dealerId,
            agentName,
            agentId,
            selectedMake,
            selectedMakeId,
            selectedModel,
            selectedModelId,
            selectedVariant,
            selectedVariantId,
            sourceSelector,
            leadSourceId,
            idNumber,
            typeSelector,
            leadTypeId
        },
        touched,
        errors,
        handleChange,
        isValid,
        setFieldTouched,
        resetForm,
    } = props.props;

    const reset = () => {
        resetForm();
    };

    useEffect(() => {
        getCars();
        getSources();
        getLeadTypes();
    }, []);

    useEffect(() => {
        setDealers(dealerArr);
    }, [dealerArr]);

    useEffect(() => {

        if (dealerId == 0) {
            return;
        }

        let cancel;
        let params = {}
        params.dealerId = dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerAgent`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                let res = response.data.map(r => { return { "id": r.userId, "name": r.fullName } })
                setAgentsList(res);
            }
        });

    }, [props.props.values.dealerId]);

    const getCars = () => {

        let cancel;

        axios({
            method: 'GET',
            url: `${BASE_URI}/variants`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            let makesList = [];
            let modelsList = [];
            let variantsList = [];

            if (response?.data?.list && response?.data?.list?.length > 0) {
                response.data.list.forEach(car => {

                    let make = {
                        id: car.makeName,
                        name: car.makeName
                    };

                    let model = {
                        id: car.modelId,
                        name: car.modelName,
                        makeId: make.id
                    };

                    let variant = {
                        id: car.id,
                        name: car.variantName,
                        modelId: model.id
                    };

                    // Add the make if it's not added before
                    if (!makesList.some(m => m.name == make.name)) {
                        makesList.push(make);
                    }

                    // Add the model if it's not added before
                    if (!modelsList.some(m => m.id == model.id)) {
                        modelsList.push(model);
                    }

                    // Add the variant regardless
                    variantsList.push(variant);

                });
            }

            setMakes(makesList);
            setModels(modelsList);
            setVariants(variantsList);

        });

    }

    const getSources = () => {
        let cancel;
        axios({
            method: 'GET',
            url: `${BASE_URI}/leadsources`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setSources(response.data);
                setLoading(false);
                return sources;
            }
        });
    }

    const getLeadTypes = () => {

        let types = [];

        Object.entries(LeadTypeEnum).forEach(([key, value]) => {
            types.push({
                id: value,
                name: key
            });
        });

        setLeadTypes(types);

    };

    return (
        <Grid container className={classes.root} direction="row">
            {(loading === true) && (
                <CircularProgress/>
            )}
            {(loading === false) && (
                <>
                    <Grid item xs={12} md={12} lg={4}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name & Surname"
                            value={name}
                            fullWidth
                            required
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched("name", true, false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <TextField
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            value={email}
                            fullWidth
                            required
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched("email", true, false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <TextField
                            id="phone"
                            name="phone"
                            type="tel"
                            label="Phone"
                            value={phone}
                            fullWidth
                            required
                            helperText={touched.phone ? errors.phone : ""}
                            error={touched.phone && Boolean(errors.phone)}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched("phone", true, false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <TextField
                            id="idNumber"
                            name="idNumber"
                            label="ID Number"
                            value={idNumber}
                            fullWidth
                            helperText={touched.idNumber ? errors.idNumber : ""}
                            error={touched.idNumber && Boolean(errors.idNumber)}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched("idNumber", true, false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Dealer For Lead"}
                            fieldName={"dealerName"}
                            fieldNameID={"dealerId"}
                            data={dealers}
                            fullWidth
                            required
                            Autocomplete
                            helperText={touched.dealerName ? errors.dealerName : "Start Typing"}
                            error={touched.dealerName && Boolean(errors.dealerName)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Agent For Lead"}
                            fieldName={"agentName"}
                            fieldNameID={"agentId"}
                            data={agentsList}
                            fullWidth
                            Autocomplete
                            helperText={touched.agentId ? errors.agentId : "Start Typing"}
                            error={touched.agentId && Boolean(errors.agentId)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Source of Lead"}
                            fieldName={"sourceSelector"}
                            fieldNameID={"leadSourceId"}
                            data={sources}
                            fullWidth
                            required
                            Autocomplete
                            helperText={touched.sourceSelector ? errors.sourceSelector : "Start Typing"}
                            error={touched.sourceSelector && Boolean(errors.sourceSelector)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Type of Lead"}
                            fieldName={"typeSelector"}
                            fieldNameID={"leadTypeId"}
                            data={leadTypes}
                            fullWidth
                            required
                            Autocomplete
                            helperText={touched.typeSelector ? errors.typeSelector : "Start Typing"}
                            error={touched.typeSelector && Boolean(errors.typeSelector)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Select a Make"}
                            fieldName={"selectedMake"}
                            fieldNameID={"selectedMakeId"}
                            data={makes}
                            fullWidth
                            Autocomplete
                            helperText={touched.selectedMake ? errors.selectedMake : "Start Typing"}
                            error={touched.selectedMake && Boolean(errors.selectedMake)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Select a Model"}
                            fieldName={"selectedModel"}
                            fieldNameID={"selectedModelId"}
                            disabled={!props.props.values.selectedMakeId}
                            data={models.filter(m => m.makeId == props.props.values.selectedMakeId)}
                            fullWidth
                            Autocomplete
                            helperText={touched.selectedModel ? errors.selectedModel : "Start Typing"}
                            error={touched.selectedModel && Boolean(errors.selectedModel)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Field
                            component={SelectInput}
                            props={props.props}
                            Label={"Select a Variant"}
                            fieldName={"selectedVariant"}
                            fieldNameID={"selectedVariantId"}
                            disabled={!props.props.values.selectedModelId}
                            data={variants.filter(v => v.modelId == props.props.values.selectedModelId)}
                            fullWidth
                            Autocomplete
                            helperText={touched.selectedVariant ? errors.selectedVariant : "Start Typing"}
                            error={touched.selectedVariant && Boolean(errors.selectedVariant)}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            id="notes"
                            label="Private Notes"
                            name="notes"
                            multiline
                            minRows={5}
                            rowsMax={5}
                            variant="outlined"
                            value={notes}
                            helperText={touched.notes ? errors.notes : ""}
                            error={touched.notes && Boolean(errors.notes)}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched("notes", true, false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Button
                            variant="contained"
                            style={{ margin: "1rem" }}
                            startIcon={<AddIcon />}
                            color="primary"
                            type="submit"
                            disabled={!isValid}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            style={{ margin: "1rem" }}
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={reset}
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    The subject of the lead will be auto generated.
                                </Typography>
                                <Typography variant="h5">
                                    <b>No SMS or mail will be sent</b>
                                </Typography>
                                <Typography variant="h6">
                                    The Lead state will be <b>New</b> and an Agent will process it as
                                    per normal
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>


                </>
            )}
        </Grid>
    );
}
