import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, TextField, Button, Box } from '@material-ui/core';
import { Formik, Form } from 'formik';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { useHistory, useParams } from 'react-router-dom';

export default function Preview({ initialValues, onSubmit }) {
  const history = useHistory();

  const [formFields, setFormFields] = useState(initialValues);

  return (
    <Grid container spacing={4} direction='column'>
      <Grid item xs={12}>
        <Formik initialValues={formFields} onSubmit={onSubmit}>
          {(props) => {
            const { values, touched, errors, handleBlur, handleChange, isSubmitting } = props;
            return (
              <Form>
                <Grid spacing={4} container direction='row'>
                  <Grid item spacing={4} container xs={12}>
                    <Grid item xs={12} md={6}>
                    under construction
                    </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container justify='flex-end' xs={12}>
                    <Box mr={3}>
                      <Button onClick={() => history.push('/templates')}>Cancel</Button>
                    </Box>
                    <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
