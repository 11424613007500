import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
// import moment from 'moment'

export default function MotorgroupFilter(searchString, pageNumber, pageSize, order, orderBy) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [motorgroups, setMotorgroups] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setLoading(true);
    setError(false);

    const params = {
      pageNumber: pageNumber + 1,
      pageSize,
      searchString
    }

    if (order) {
      params.order = order.toUpperCase();
    }

    if (orderBy) {
      params.orderBy = orderBy
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/Motorgroups`,
      params
    }).then((response) => {
      setLoading(false);
      setMotorgroups(response?.data?.list || [])
      setTotal(response.data.total || 0)
    }).catch(error => {
      setLoading(false);
      if (axios.isCancel(error))
        return;
      if (error.response) {
        setError(true);
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, pageNumber, pageSize, order, orderBy])

  return { loading, error, motorgroups, total }
}