import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import {DEFAULT_PAGE_SIZE} from '../../shared/Constants'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import SlidesFilter from './SlidesFilter'
import {ImageContainer} from '../../shared/ImageContainer'
import MenuItem from "@material-ui/core/MenuItem";
import ExportContainer from '../../shared/ExportContainer'
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: "100vh"
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  green: {
    color: '#8bc34a'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function SlidesTable() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchString, setSearchString] = useState('');
  const [slideStatus, setSlideStatus] = useState('');
  const{
    loading,
    slides,
    total
  } = SlidesFilter(searchString, slideStatus, page, pageSize, order, orderBy)

  const headCells = [
    { id: 'dateRange', label: 'Available For', sort: true },
    // { id: 'url', label: 'URL', sort: true },
    { id: 'name', label: 'Name', sort: true },
    { id: 'category', label: 'Display Order', sort: true },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleChange = (event) => {
    setSearchString(event.target.value)
  };

  const filterArr = [
    'All',
    'Active',
    'Inactive'
  ]

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction="column">
          <Grid item xs={12}>
            <Toolbar disableGutters style={{marginBottom: '1rem'}}>
              <Typography style={{flex: '1 1'}} variant="h4" id="tableTitle" component="div">
                Slides
              </Typography>
              <TextField
                style={{width: '150px', marginRight: '2rem'}}
                name="statusFilter"
                id="statusFilter"
                label="Status"
                select
                defaultValue={"All"}
                value={slideStatus}
                onChange={(e) => setSlideStatus(e.target.value)}
              >
                {filterArr.map((option, index) => (
                  <MenuItem key={index} id={`${option}-${index}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="slidesFilter"
                id="slidesFilter"
                label="Search Slides"
                value={searchString}
                type="text"
                InputProps={{
                  endAdornment: searchString ?  (
                    <InputAdornment position="end">
                      <IconButton size="small" disableFocusRipple
                                  disableRipple onClick={()=> setSearchString('')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ): '',
                }}
                onChange={handleChange}
              />
              <Button onClick={() => history.push('/slides/add')}
                      variant="contained"
                      style={{marginLeft: '2rem'}}
                      startIcon={<AddIcon />}
                      color="primary">
                Slide
              </Button>
            </Toolbar>

            <Grid item container xs={12} style={{marginBottom: '2rem'}}>
              <ExportContainer values={slides}
                               useDataHeaders
                               fileName='Slides'
                               headers={headCells}/>
            </Grid>

            <TableContainer>
              <Table aria-label="slides table" size="small">
                <TableHeadersSort
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    slides.map((row) =>(
                      <Tooltip title={`Edit ${row.status} ${row.url}`} key={row.id} placement="left">
                        <TableRow hover className={classes.hoverTableCell}
                                onClick={() => history.push(`/slides/edit/${row.id}`)}>
                        <TableCell size="small">
                            <div>
                              <Box boxShadow={3} mb={2}>
                                <ImageContainer
                                  src={row.slideImageUrl}
                                  alt={row.url}/>
                              </Box>
                              <Typography style={{color: row.status === 'inActive' ? '#ff5722': ''}}
                                          variant="body1">
                                {moment(row.startDate).format('l')} - {moment(row.endDate).format('l')}
                              </Typography>
                            </div>
                        </TableCell>
                        {/*<TableCell>*/}
                          {/*{row.url}*/}
                        {/*</TableCell>*/}
                        <TableCell>
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.category}
                        </TableCell>
                      </TableRow>
                      </Tooltip>
                    ))
                  }
                  {
                    ((!slides.length || slides.length === 0) && !loading) &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}
                                 className="py-3">
                        <Typography variant="h6" color="textSecondary">
                          No Slides Added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    loading &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                page={page}
                rowsPerPageOptions={[5,10,25]}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}