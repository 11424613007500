import React, { useState, useEffect, useContext } from 'react';
import {
  Grid, TextField, Button, Typography, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, MenuItem, Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray } from 'formik';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import MultipleFontUploader from './MultipleFontUploader';
import { LoadingContext } from '../../shared/context/loadingContext';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  editorContainer: {
    paddingRight: theme.spacing(2),
  },
  uploadSection: {
    marginTop: theme.spacing(4),
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: theme.spacing(2),
    backgroundColor: '#f9f9f9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
}));

export default function Fonts({ initialValues, onSubmit }) {
  const [fontSettings, setFontSettings] = useState([]);
  const [fonts, setFonts] = useState([]);
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar(); 
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext); 

  useEffect(() => {
    fetchFontSettings();
    fetchFonts();
  }, [id, enqueueSnackbar]);

  const fetchFontSettings = async () => {
    try {
      showLoading();
      const response = await axios.get(`${BASE_URI}/Font/byTemplate?templateId=${id}`);
      setFontSettings(response.data);
      hideLoading();
    } catch (error) {
      console.error('There was an error fetching the fonts!');
      hideLoading();
    }
  };

  const fetchFonts = async () => {
    try {
      showLoading();
      const response = await axios.get(`${BASE_URI}/Font/allFonts`);
      setFonts(response.data);
      hideLoading();
    } catch (error) {
      enqueueSnackbar("There was an error fetching the fonts list!", { variant: 'error' });
      hideLoading();
    }
  };

  const mapFontSettingsToInitialValues = () => {
    const headings = ['Base Font', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    return {
      styles: initialValues?.styles || '',
      fontFiles: [],
      fontSettings: headings.reduce((acc, heading) => {
        const setting = fontSettings.find(font => font.fontType === heading) || {};
        acc[heading] = {
          style: setting.style || '',
          size: setting.size || '',
          color: setting.color || '',
        };
        return acc;
      }, {})
    };
  };

  const onSubmitFont = async (values) => {
    showLoading();
    const { fontFiles } = values;

    if (!fontFiles.length) {
      console.log('No files selected.');
      return;
    }

    const getMimeType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
        case 'ttf': return 'font/ttf';
        case 'otf': return 'font/otf';
        case 'woff': return 'font/woff';
        case 'woff2': return 'font/woff2';
        default: return 'application/octet-stream';
      }
    };

    const fontsToUpload = [];
    const filePromises = Array.from(fontFiles).map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          const base64EncodedFile = `data:${getMimeType(file.name)};base64,${base64String}`;

          fontsToUpload.push({
            fontFileUrl: base64EncodedFile,
            fontName: file.name,
          });

          resolve();
        };

        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    await Promise.all(filePromises);

    try {
      const res = await axios.put(`${BASE_URI}/Font/uploadFonts`, fontsToUpload);
      setFontSettings(prevFontSettings => [...prevFontSettings, ...res.data]);
      enqueueSnackbar('Fonts uploaded successfully!', { variant: 'success' });
      hideLoading();
      fetchFonts();
    } catch (error) {
      enqueueSnackbar('Error uploading fonts: ' + (error.response ? error.response.data : error.message), { variant: 'error' });
      hideLoading();
    }
  };

  const handleSaveFont = async (heading, values) => {
    const selectedFontStyle = values.fontSettings[heading]?.style;
  
    if (!selectedFontStyle) {
      enqueueSnackbar(`Please select a font style for ${heading} before saving.`, { variant: 'warning' });
      return;
    }
  
    showLoading();
    const url = `${BASE_URI}/Font/FontSetting`;
  
    const selectedFont = fonts.find(font => font.fontName === selectedFontStyle);
  
    const fontSettingToSave = {
      websiteTemplatesId: id,
      size: values.fontSettings[heading]?.size || '',
      color: values.fontSettings[heading]?.color || '',
      style: selectedFontStyle || '',
      fontType: heading || '',
      fontId: selectedFont ? selectedFont.id : '',
    };
  
    try {
      const existingFont = fontSettings.find(f => f.fontType === heading);
  
      let response;
  
      if (existingFont) {
        const fontSettingToPut = {
          ...fontSettingToSave,
          id: existingFont.id,
        };
        response = await axios.put(`${url}/${existingFont.id}`, fontSettingToPut);
        enqueueSnackbar('Font setting updated successfully!', { variant: 'success' });
  
        setFontSettings(prevFontSettings =>
          prevFontSettings.map(f =>
            f.fontType === heading ? response.data : f
          )
        );
      } else {
        response = await axios.post(url, fontSettingToSave);
        enqueueSnackbar('Font setting created successfully!', { variant: 'success' });
  
        setFontSettings(prevFontSettings => [...prevFontSettings, response.data]);
      }
    } catch (error) {
      enqueueSnackbar("There was an error saving the font setting: " + (error.response ? error.response.data : error.message), { variant: 'error' });
    } finally {
      hideLoading();
    }
  };
  
  return (
    <Grid container spacing={4} direction="column">
      <Grid item xs={12}>
        <Formik
          initialValues={mapFontSettingsToInitialValues()}
          enableReinitialize
          onSubmit={(values) => {
            onSubmitFont(values);
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Font Settings
                  </Typography>
                  <div className={classes.uploadSection}>
                    <Typography variant="h6" gutterBottom>
                      Upload Fonts
                    </Typography>
                    <FieldArray name="fontFiles">
                      {() => (
                        <div>
                          <MultipleFontUploader
                            onChange={(files) => setFieldValue('fontFiles', files)}  
                            value={values.fontFiles}
                          />
                          <Button
                            className={classes.uploadButton}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Upload Fonts
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Font Type</TableCell>
                          <TableCell>Style</TableCell>
                          <TableCell>Size</TableCell>
                          <TableCell>Color</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {['Base Font', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((heading, index) => (
                          <TableRow key={index}>
                            <TableCell>{heading}</TableCell>
                            <TableCell>
                              <Select
                                name={`fontSettings.${heading}.style`}
                                value={values.fontSettings[heading]?.style || ''}
                                onChange={handleChange}
                                fullWidth
                              >
                                {fonts.map((font) => (
                                  <MenuItem key={font.id} value={font.fontName}>
                                    {font.fontName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name={`fontSettings.${heading}.size`}
                                value={values.fontSettings[heading]?.size || ''}
                                onChange={handleChange}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                name={`fontSettings.${heading}.color`}
                                value={values.fontSettings[heading]?.color || ''}
                                onChange={handleChange}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSaveFont(heading, values)}
                              >
                                Save
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
