import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
// import moment from 'moment'

export default function WebsiteListFilter(searchString, pageNumber, pageSize, order, orderBy) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [websites, setWebsites] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {

    let cancel;

    setLoading(true);
    setError(false);

    const params = {
      pageNumber: pageNumber + 1,
      pageSize,
      searchString
    }

    if (order) {
      params.order = order.toUpperCase();
    }

    if (orderBy) {
      params.orderBy = orderBy
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {

      setLoading(false);
      setWebsites(response?.data?.list || [])

      setTotal(response.data.total || 0)

    }).catch(error => {

      setLoading(false);

      if (axios.isCancel(error))
        return;

      if (error.response) {
        setError(true);
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString,pageNumber, pageSize, order, orderBy])

  return { loading, error, websites, total }
}