import {useState, useEffect, useContext} from "react";
import axios from 'axios';
import {BASE_URI} from "../../shared/Constants";
import { DealerContext } from "../../shared/DealerContext";

export default function Leads(pageNumber, pageSize, order, orderBy, state, date, source, agent, leadLike, motorgroupId, dealerId, searchString, isDashboard) {
    const [loading, setLoading] = useState(true);
    const { isMasterDealer } = useContext(DealerContext);
    const [error, setError] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        setError(false);
        const params = {
            pageSize,
            searchString
          }

        if (pageNumber) {
            params.pageNumber = pageNumber;
        }

        if(isDashboard){
            params.isDashboard = true;
        }

        if (pageSize) {
            params.pageSize = pageSize
        }

        if (order) {
            params.order = order.toUpperCase();
        }

        if (orderBy) {
            params.orderBy = orderBy;
        }

        if (date) {
            params.since = date
        }

        if (state) {
            params.state = state;
        } else {
            params.state = -1;
        }

        if (source) {
            params.sourceId = source;
        }

        if (leadLike) {
            params.leadName = leadLike;
        }

        if (agent) {
            params.user = agent;
        }

        params.dealerId = isMasterDealer ? null : dealerId;
        params.motorgroupId = isMasterDealer ? motorgroupId : null;

        // Get the range for the current page number
        axios({
            method: 'GET',
            url: `${BASE_URI}/leads`,
            params
        }).then((response) => {
            if (response.data?.list) {
                setRanges(response.data?.list)
            }
            setTotal(response.data?.total != null ? response.data.total : 0);
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })
    }, [ pageNumber, pageSize, order, orderBy, date, state, source, agent, leadLike, motorgroupId, dealerId,searchString,isDashboard]);

    return {loading, error, ranges, total};
}