import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import {
    Grid,
    Divider,
    Box,
    CircularProgress,
    TextField,
    MenuItem,
    Button,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { BASE_URI } from '../../../shared/Constants'
import ConfirmationDialog from '../../../shared/ConfirmationDialog';
import { CurrencyConverter } from '../../../shared/TextMask'
import ColourForm from './ColourForm'

const useStyles = makeStyles((theme) => ({
    importColorContainer: {
        width: "40px",
        height: "40px",
        border: "1px solid black",
        borderRadius: "20px"
    }
}));

export default function ColorListContainer({ isInterior, dealerModelId }) {

    const classes = useStyles();
    const mountedRef = useRef(true);
    const history = useHistory();
    const { dealerVariantId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true)
    const [loadingSourceVariants, setLoadingSourceVariants] = useState(true);
    const [sourceVariants, setSourceVariants] = useState([]);
    const [selectedSourceVariant, setSelectedSourceVariant] = useState(null);
    const [activeColour, setActiveColour] = useState(null)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [colourArr, setColourArr] = useState([])
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {

        axios({
            method: "GET",
            url: `${BASE_URI}/dealerVariants/AllVariantsDetails/${dealerModelId}`,
            cancelToken: axios.CancelToken.source().token
        }).then(response => {

            setLoadingSourceVariants(false);
            setSourceVariants(response?.data?.list?.filter(dv => dv.id != dealerVariantId) || []);

        }).catch(error => {

            enqueueSnackbar("Unable to load the source variants", { variant: 'error' });

        });

    }, []);

    useEffect(() => {

        if (!dealerVariantId) {
            enqueueSnackbar("Please re-select a vehicle model", { variant: 'warning' });
            return history.push(`/DASH/range`)
        }

        setLoading(true)

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getColours = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/colours/${getTabDescription()}s`, {
                    params: {
                        dealerVariantId: dealerVariantId
                    },
                    cancelToken: source.token
                })

                setColourArr(result?.data?.list || []);

            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to load variant colours", { variant: 'error' });
            }
        }

        getColours().then(() => {
            setLoading(false)
            if (!mountedRef.current) return null
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };

    }, [isInterior, dealerVariantId, forceReload])

    const getTabDescription = (keepCase = false) => {

        let desc = isInterior ? "Interior" : "Exterior";

        return keepCase ? desc : desc.toLocaleLowerCase();

    };

    const handleDeleteColour = () => {

        setOpenConfirm((prev) => !prev)
        setLoading(true)

        if (activeColour) {
            deleteColour(activeColour.id).then((res) => {

                setLoading(false)
                setForceReload(prev => !prev)
                enqueueSnackbar("Successfully deleted colour", { variant: 'success' });

            }).catch((err) => {

                setLoading(false)
                enqueueSnackbar("Unable to delete color option", { variant: 'error' });

            })
        }

    }

    const handleFormAction = (data, resetForm) => {

        if (data) {

            setLoading(true);

            if (data.id !== undefined) {
                editColour(data, data.id).then((res) => {

                    setForceReload(prev => !prev);
                    setLoading(false);
                    enqueueSnackbar("Successfully edited variant colour", { variant: 'success' });

                }).catch((err) => {

                    setLoading(false);
                    enqueueSnackbar("Unable to edit variant colour", { variant: 'error' });

                })

            } else {
                createColor(data).then((res) => {

                    setForceReload(prev => !prev);
                    setLoading(false);
                    resetForm();
                    enqueueSnackbar("Successfully added variant colour", { variant: 'success' });

                }).catch((err) => {

                    setLoading(false);
                    enqueueSnackbar("Unable to add variant colour", { variant: 'error' });

                })
            }

        } else {

            enqueueSnackbar("Colour form data not available", { variant: 'error' });

        }

    }

    const createColor = (formData) => {

        let data = {
            ...formData,
            dealerVariantId: parseInt(dealerVariantId, 10),
            interior: isInterior,
            price: CurrencyConverter(formData.price)
        };

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        return axios.post(`${BASE_URI}/colours/${getTabDescription()}s`,
            data, {
            cancelToken: source.token
        });

    }

    const editColour = (formData, id) => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let data = {
            ...formData,
            ownedVariantId: parseInt(dealerVariantId, 10),
            interior: isInterior,
            price: CurrencyConverter(formData.price)
        };

        return axios.put(`${BASE_URI}/colours/${getTabDescription()}s/${id}`, data, {
            cancelToken: source.token
        });

    }

    const deleteColour = (id) => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        return axios.delete(`${BASE_URI}/colours/${getTabDescription()}s/${id}`, {
            cancelToken: source.token
        });
    }

    const selectSourceVariant = (e, c) => {

        let item = c?.props?.value;

        if (!item) return;

        setSelectedSourceVariant(item);

    };

    const hasSourceVariantColors = () => {

        if (!selectedSourceVariant?.id) return false;

        return isInterior
            ? selectedSourceVariant.interiors?.length > 0
            : selectedSourceVariant.exteriors?.length > 0;

    };

    const importVariantColours = () => {

        let endpoint = `${BASE_URI}/colours/${getTabDescription()}s/CopyVariantColours`;
        let bodyData = {
            sourceVariantId: selectedSourceVariant.id,
            targetVariantId: parseInt(dealerVariantId)
        };

        setLoading(true);
        setLoadingSourceVariants(true);

        axios({
            method: "POST",
            url: endpoint,
            data: bodyData,
            cancelToken: axios.CancelToken.source().token
        }).then(response => {

            if (response?.data?.addedColours?.length > 0) {

                let newColourArr = response.data.addedColours;

                if (colourArr?.length > 0) {
                    newColourArr.push(...colourArr);
                }

                setColourArr(newColourArr);

            }

            if (response?.data?.skippedColours?.length > 0) {
                enqueueSnackbar(`Skipped to import ${response.data.skippedColours.length} colours. Some either contain invalid fields or would result in duplicated colours.`, { variant: 'warning' });
            } else {
                enqueueSnackbar(`Successfully imported all the colours`, { variant: 'success' });
            }

        }).catch(error => {

            enqueueSnackbar("Unable to import the variant colours", { variant: 'error' });

        }).finally(() => {

            setLoading(false);
            setLoadingSourceVariants(false);
            setSelectedSourceVariant(null);

        });

    };

    return (
        <React.Fragment>
            <ConfirmationDialog
                secondaryInfo={activeColour ? activeColour.title : ''}
                action='delete'
                open={openConfirm}
                confirm={handleDeleteColour}
                closeDialog={() => setOpenConfirm(false)}
            />

            <Grid container direction="column">

                <Grid item xs={12}>
                    <Typography variant="h5">
                        Import {getTabDescription(true)} Colours
                    </Typography>

                    {
                        loadingSourceVariants === true &&
                        <Box mt={3}>
                            <Grid container justify='center'>
                                <CircularProgress />
                            </Grid>
                        </Box>
                    }
                    {
                        !loadingSourceVariants &&
                        <>
                            <Box mt={3}>
                                <Typography variant="body">
                                    {
                                        sourceVariants?.length > 0
                                            ? `Import the ${getTabDescription()} colours from another variant`
                                            : "There are no colours available to import from existing dealer variants"
                                    }
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                {
                                    sourceVariants?.length > 0 &&
                                    <>
                                        <Grid container spacing={4} justify="space-between">

                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    id="selectedSourceVariant"
                                                    name="selectedSourceVariant"
                                                    variant="outlined"
                                                    label="Source Variant"
                                                    style={{ margin: "0" }}
                                                    value={selectedSourceVariant}
                                                    onChange={selectSourceVariant}
                                                >
                                                    {sourceVariants.map((option) => (
                                                        <MenuItem key={option.id} id={option.id} value={option}>
                                                            {option.variantName} ({isInterior ? (option.interiors?.length || 0) : (option.exteriors?.length || 0)})
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12} container spacing={4} alignItems="center">
                                                {
                                                    selectedSourceVariant?.id > 0 && (
                                                        !hasSourceVariantColors()
                                                            ?
                                                            <Grid item xs={12} container justify="center">
                                                                <Typography variant="body" align="center">
                                                                    This dealer variant has no {getTabDescription()} colours to import
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <>
                                                                {
                                                                    (isInterior ? selectedSourceVariant.interiors : selectedSourceVariant.exteriors).map((colorItem, index) => (
                                                                        <Grid key={index} item xs={6} md={3} container alignItems="center" justify="space-between">
                                                                            <Grid item>
                                                                                <Typography variant="caption" gutterBottom>{colorItem.title}</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Box className={classes.importColorContainer} style={{ backgroundColor: `${colorItem.colour}`, borderColor: `${colorItem.colour}` }}></Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                                <Grid item xs={12} container justify="center">
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        style={{ padding: "10px 50px" }}
                                                                        disabled={!(selectedSourceVariant?.id > 0)}
                                                                        onClick={importVariantColours}
                                                                    >
                                                                        Import
                                                                    </Button>
                                                                </Grid>
                                                            </>
                                                    )
                                                }
                                            </Grid>

                                        </Grid>
                                    </>
                                }
                            </Box>
                        </>
                    }

                    <Box mt={3} mb={3}>
                        <Divider />
                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add New {getTabDescription(true)}
                    </Typography>
                    <Box mb={8} mt={2}>
                        <ColourForm small onSubmit={handleFormAction} />
                    </Box>
                    <Divider />
                </Grid>

                <Box mt={3}>
                    <Typography variant="h5">
                        Added {getTabDescription(true)} Colours
                    </Typography>
                </Box>

                <Grid item container direction="row">
                    {
                        colourArr?.length > 0 && colourArr.map((item) => (
                            <Grid item xs={12} md={6} key={item.id}>
                                <Box py={3}>
                                    <ColourForm
                                        id={item.id}
                                        data={item}
                                        onSubmit={handleFormAction}
                                        deleteColour={() => {
                                            setOpenConfirm((prev) => !prev)
                                            setActiveColour(item)
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ))
                    }
                    {
                        !colourArr?.length && !loading &&
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <Box mt={3}>
                                    <Typography variant="h6" color="textSecondary">
                                        No Colours have been added for this variant
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    {
                        loading &&
                        <Grid container justify="center">
                            <Grid item xs={1}>
                                <Box mt={3}>
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Grid>

            </Grid>
        </React.Fragment>
    );
}