export const DEFAULT_PAGE_SIZE = 25;
export const IMAGE_UPLOADER_CONFIG = {
    maxWidth: 1920,
    maxHeight: 1080,
    compressFormat: 'JPEG',
    quality: 100,
    rotation: 0,
    outputType: 'base64'
};
export const FACEBOOK_BUSINESS_ID = '216155158722694';
export const FACEBOOK_APP_ID = '129374355593018';//'129374355593018';//'563793848142903';
export const FACEBOOK_GRAPH_BASE_URL = 'https://graph.facebook.com';
export const FACEBOOK_API_VERSON = 'v11.0';
export const DEFAULT_COLOR = '#333'
// export const BASE_URI = 'https://c2-marketdealer-leadcentre.azurewebsites.net/api'
export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
// export const BASE_URI = 'https://localhost:5001/api'
export const BASE_URI_WEPAY4CARS = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const DASH_URI = 'dash'
export const AUTH_USER = 'user';
export const AUTH_PERMISSION = 'permissions';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again later.'
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// eslint-disable-next-line no-useless-escape
export const WEBSITE_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
export const LOOKUP_URI = "applicationlookup";

export const LeadStatusEnum = {
    'New Lead': 1,
    'Prequalified Approved': 2,
    'Prequalified Declined': 3,
    'Application Completed': 4,
    'Application Submitted': 5,
    'Application Approved': 6,
    'Application Declined': 7,
    'Prequalified Submitted': 8,
    'Lost': 9,
    'Offer to Purchase': 10,
    'Documents In': 11,
    'Bank Submitted': 12,
    'Bank Validated': 13,
    'Bank Invalidated': 14,
    'Not Taken Up': 15,
    'Invoiced': 16,
    'Contracted': 17,
    'Delivered': 18,
    'Paid Out': 19,
    'Returned': 20,
    'Test Drive': 21
}

export const LeadTypeEnum = {
    'New Cars': 1,
    'Used Cars': 2,
    'Dealer Contact': 3,
    'Staff Contact': 4,
    'Service': 5,
    'Part': 6,
    'Personal Loan': 7,
    'Test Drive': 8,
    'TradeIn': 11,
    'FinanceApplication': 12
}

Object.freeze(LeadStatusEnum)

