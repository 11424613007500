import React from 'react';
import { useSnackbar } from 'notistack';

const NoPermissionSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    enqueueSnackbar('You do not have permission to view this page.', { variant: 'error' });
  }, [enqueueSnackbar]);

  return null;
};

export default NoPermissionSnackbar;
