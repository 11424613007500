import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const SelectInput = (props) => (
    <Autocomplete
        {...props}
        options={props.data}
        getOptionLabel={(option) => option.name ? option.name : ""}
        getOptionSelected={(option, value) => {
            return option.name === value.name;
        }}
        onChange={(event, value) => {
            // props.props.setFieldTouched(props.fieldName, true, false);
            props.props.setFieldValue(props.fieldName, value ? value.name : "");

            if (props.fieldNameID) {
                props.props.setFieldTouched(props.fieldNameID, true, false);
                props.props.setFieldValue(props.fieldNameID, value ? value.id : "");
            }
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                label={props.Label}
                onChange={props.props.handleChange}
                onBlur={props.props.handleBlur}
            />
        )}
    />
);

export default SelectInput;
