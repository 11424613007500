import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DealerContext } from '../../shared/DealerContext'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import {DEFAULT_PAGE_SIZE} from '../../shared/Constants'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import UserListFilter from './UserListFilter'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExportContainer from '../../shared/ExportContainer'
import { AuthContext } from '../../shared/context/AuthContext'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import { BASE_URI } from '../../shared/Constants'
import { useSnackbar } from 'notistack'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  green: {
    color: '#8bc34a'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function UserAdminTable() {

  const classes = useStyles();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { hasAdminRights } = useContext(AuthContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const { dealerArr } = useContext(DealerContext);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('FullName');
  const [searchString, setSearchString] = useState('');
  const{
    loading,
    users,
    total,
    reload
  }= UserListFilter(searchString, page, pageSize, order, orderBy)

  const headCells = [
    { id: 'FullName', label: 'User', sort: true },
    { id: 'sms', label: 'SMS', sort: true },
    { id: 'roles', label: 'Roles', sort: true },
    { id: 'dealers', label: 'Dealers', sort: true },
    { id: 'FINumber', label: 'F&I #', sort: true },
    { id: "actions", label: "Actions", sort: false, align: "center", width: "100px" }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleChange = (event) => {
    setPage(0)
    setSearchString(event.target.value)
  };

  const deleteUser = (e, user) => {

    e.stopPropagation();

    setSelectedUser(user);
    setOpenConfirm(true);

  };

  const handleDeleteUser = () => {

    setIsDeletingUser(true);

    axios({
      method: 'DELETE',
      url: `${BASE_URI}/Admin/Users/${selectedUser?.userId}`
    }).then((response) => {

      if (response?.data) {

        enqueueSnackbar("Successfully deleted the user", { variant: 'success' });
        reload();

      } else {
        enqueueSnackbar("An unexpected error occurred while attempting to delete the user. Please try again.", { variant: 'error' });
      }

    }).catch( error => {

      if(axios.isCancel(error)) return

      enqueueSnackbar(`Failed to delete the user. ${error}`, { variant: 'error' });

    }).finally(() => {

      setOpenConfirm(false);
      setIsDeletingUser(false);

    });

  };

  function getSelectedDealerText(dealers) {
    let outcome = "";
    if (dealers.length === 0) {
      outcome = 'No dealer selected';
    }
    else if (dealers.length === 1) {
      dealers.map((d)=>{
        const selectedDealer = dealerArr.find((dealer) => dealer.name === d);
        outcome = selectedDealer ? selectedDealer.name : 'Dealer Not Found';
      })
    }else{
     outcome = `${dealers.length} dealers selected`;
    }
    return outcome;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ConfirmationDialog
          action='delete'
          secondaryInfo={"the user"}
          open={openConfirm}
          isBusy={isDeletingUser}
          confirm={handleDeleteUser}
          closeDialog={() => setOpenConfirm(false) }
        />
        <Grid container spacing={4} className={classes.stepPadding} direction="column">
          <Grid item xs={12}>
            <Toolbar disableGutters style={{marginBottom: '2rem'}}>
              <Typography style={{flex: '1 1'}} variant="h4" id="tableTitle" component="div">
                Users
              </Typography>
              <TextField
                name="userFilter"
                id="userFilter"
                label="Search Users"
                value={searchString}
                type="text"
                InputProps={{
                  endAdornment: searchString ?  (
                    <InputAdornment position="end">
                      <IconButton size="small" disableFocusRipple
                                  disableRipple onClick={()=> {
                                    setSearchString('')
                                    setPage(0)
                                  }}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ): '',
                }}
                onChange={handleChange}
              />
              <Button onClick={() => history.push('/admin/users/add')}
                      variant="contained"
                      style={{marginLeft: '2rem'}}
                      startIcon={<AddIcon />}
                      color="primary">
                User
              </Button>
              <Button onClick={() => history.push('/admin/users/add')}
                      variant="contained"
                      style={{marginLeft: '2rem'}}
                      startIcon={<GetAppIcon />}
                      color="primary">
                Import
              </Button>
            </Toolbar>

            <Grid item container xs={12}>
              <ExportContainer values={users}
                               useDataHeaders
                               fileName='Users'
                               headers={headCells}/>
            </Grid>

            <TableContainer>
              <Table aria-label="users table" size="small">
                <TableHeadersSort
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    users.map((row) =>(
                      <Tooltip title={`Edit ${row.active ? 'Active': 'Inactive'} ${row.fullName}`}key={row.userId} placement="left">
                      <TableRow hover
                                className={classes.hoverTableCell}
                                onClick={() => history.push(`/admin/users/edit/${row.userId}`)}>
                        <TableCell>
                            <div>
                              <Typography style={{color: !row.active ? '#ff5722': ''}}
                                          variant="subtitle2">
                                {row.fullName}
                              </Typography>
                              <Typography variant="body2">
                                {row.email}
                              </Typography>
                            </div>
                        </TableCell>
                        <TableCell>
                          {row.smsActive && <CheckCircleOutlineIcon className={classes.green}/>}
                        </TableCell>
                        <TableCell>
                          {row.roles && row.roles.map((role, roleIndex) => roleIndex !== (row.roles.length - 1) ? `${role}, `: role)}
                        </TableCell>
                        <TableCell>
                          {row.dealers.length > 0 && getSelectedDealerText(row.dealers)}
                        </TableCell>
                        <TableCell>
                          {row.fiNumber}
                        </TableCell>
                        <TableCell align='center'>
                          <Button
                              variant="contained"
                              style={{ margin: "1rem" }}
                              onClick={(e) => deleteUser(e, row)}
                              disabled={!hasAdminRights}
                          >
                              <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                      </Tooltip>
                    ))
                  }
                  {
                    ((!users.length || users.length === 0) && !loading) &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}
                                 className="py-3">
                        <Typography variant="h6" color="textSecondary">
                          No Users Added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    loading &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                page={page}
                rowsPerPageOptions={[5,10,25]}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}