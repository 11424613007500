import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, TextField, Button, Box, Toolbar, MenuItem, TextareaAutosize } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { BASE_URI } from '../../shared/Constants';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns/build/index';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },

  tr: {
    padding: '5px 30px'
  },
  th: {
    padding: '5px 10px',
    textAlign: 'center'
  },
  td: {
    padding: '2px 10px'
  },
  tdCenter: {
    padding: '2px 10px',
    textAlign: 'center'
  },
  textArea: {
    fontFamily: 'monospace',
    padding: '10px',
    width: '100%',
    height: '200px !important',
    fontSize: '12px',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f4f4f4',
    border: '1px solid #ccc',
    borderRadius: '5px'
  }
}));

export default function CategoryForm() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [isAddMode, setIsAddMode] = useState(!id);
  const theme = useTheme();
  const mountedRef = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);

  const initialValues = {
    name: '',
    type: '',
    description: '',
    altTag: '',
    wideImageAltTag: ''
  };
  const [formFields, setFormFields] = useState(initialValues);
  const [categoryTypes, setCategoryTypes] = useState([
    { id: 1, name: 'Offer' },
    { id: 2, name: 'Make' }
    // Add more types as needed
  ]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/Categories/${id}`);

        let formData = result.data;
        formData.isAddMode = isAddMode;

        setFormFields({
          ...formData
        });
      } catch (error) {
        enqueueSnackbar('Unable to get category details', { variant: 'error' });
        hideLoading()
      }
    };

    if (!isAddMode) {
      getCategory().then(() => {
        hideLoading();
        if (!mountedRef.current) return null;
      });
    }

    return () => {
      mountedRef.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getErrorMessage(axiosError, defaultMsg) {
    let errorTitle = defaultMsg;
    let errorDetails = 'No additional details are available';

    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = 'Failed to send the request';
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.details ?? errorDetails;
    }

    return `${errorTitle}: ${errorDetails}`;
  }

  const editCategory = async (id, fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.put(`${BASE_URI}/Categories/${id}`, fields);

      enqueueSnackbar('Successfully saved the category.', { variant: 'success' });

      history.push('/categories');
      return result;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the category'), { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const createCategory = async (fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.post(
        ` ${BASE_URI}/Categories`,
        {
          ...fields
        }
      );

      enqueueSnackbar('Successfully saved the category.', { variant: 'success' });
      setIsAddMode(false);
      history.push('/categories');
      return result;
    } catch (error) {
      enqueueSnackbar(`Failed to save category: ${error.message}`, { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    showLoading();
    const categoryImage = fields.image?.imageUrl;
    fields.image.imageUrl = categoryImage.image || categoryImage;
    fields.image.altTag = fields.altTag || fields.image.altTag;

    const categoryWideImage = fields.wideImage?.imageUrl;
    fields.wideImage.imageUrl = categoryWideImage.image || categoryWideImage;
    fields.wideImage.altTag = fields.wideImageAltTag || fields.wideImage.altTag;

    if (isAddMode) {
      createCategory(fields, setSubmitting, resetForm);
    } else {
      editCategory(id, fields, setSubmitting, resetForm);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik initialValues={formFields} onSubmit={onSubmit} enableReinitialize={true}>
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

              return (
                <Form noValidate>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Toolbar disableGutters>
                      <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                        {isAddMode ? 'Add' : 'Edit'} {'Category'}
                      </Typography>
                    </Toolbar>
                    <Grid spacing={4} container direction='row'>
                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            name='name'
                            id='name'
                            required
                            label='name'
                            value={values.name}
                            type='text'
                            helperText={errors.name && touched.name ? errors.name : ''}
                            error={errors.name && touched.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <TextField
                            fullWidth
                            id='type'
                            select
                            label={isAddMode ? 'Type' : values.type}
                            name='type'
                            value={isAddMode ? values.type : null}
                            helperText={errors.type && touched.type ? errors.type : ''}
                            error={errors.type && touched.type}
                            disabled={isAddMode ? false : true}
                            onBlur={handleBlur('type')}
                            onChange={(e, child) => setFieldValue('type', e.target.value)}
                          >
                            {categoryTypes.map((option) => (
                              <MenuItem key={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <InputLabel htmlFor="description">Description</InputLabel>
                          <br></br>
                          <TextareaAutosize
                            id="description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.description && touched.description}
                            className={classes.textArea}
                            minRows={4}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ImageUploadInput
                            title='Category Image'
                            required
                            fieldName={'image.imageUrl' || 'image.imageUrl.image'}
                            error={errors.image && touched.image}
                          />
                          <TextField
                            fullWidth
                            name='altTag'
                            id='altTag'
                            label='Alt Tag'
                            value={values.altTag ?? values.image?.altTag}
                            type='text'
                            helperText={errors.altTag && touched.altTag ? errors.altTag : 'Alt tag for SEO'}
                            error={errors.altTag && touched.altTag}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingTop:'0px'}}>
                          <ImageUploadInput
                            title='Wide Image'
                            required
                            fieldName={'wideImage.imageUrl' || 'wideImage.imageUrl.image'}
                            error={errors.wideImage && touched.wideImage}
                          />
                          <TextField
                            fullWidth
                            name='wideImageAltTag'
                            id='wideImageAltTag'
                            label='Alt Tag'
                            value={values.wideImageAltTag ?? values.wideImage?.altTag}
                            type='text'
                            helperText={errors.wideImageAltTag && touched.wideImageAltTag ? errors.wideImageAltTag : 'Alt tag for SEO'}
                            error={errors.wideImageAltTag && touched.wideImageAltTag}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item container justify='flex-end' xs={12}>
                        <Box mr={3}>
                          <Button
                            onClick={() => {
                              handleReset();
                              history.push('/categories');
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                        <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
