import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Toolbar,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { Switch } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { BASE_URI } from '../../shared/Constants';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  chipsHolder: {
    background: '#e0e0e0',
    margin: 2,
    borderRadius: '30px',
    padding: '0px 14px'
  },
  chipsHolderRemove: {
    color: 'red'
  }
}));

export default function UserAdminForm({ history, match }) {
  const classes = useStyles();
  const { id } = match.params;
  const isAddMode = !id;
  const mountedRef = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [selectAllDealers, setSelectAllDealers] = useState(false);
  const [roleArr, setRoleArr] = useState([]);
  const [dealerArr, setDealerArr] = useState([]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    active: false,
    password: '',
    confirmPassword: '',
    roles: [],
    dealers: [],
    fiNumber: '',
    isAddMode: isAddMode
  };

  const [formFields, setFormFields] = useState(initialValues);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/UserConfig/${id}`);
        let formData = result.data;
        formData.isAddMode = isAddMode;
        setFormFields(formData);
      } catch (error) {
        enqueueSnackbar('Unable to get user details', { variant: 'error' });
        //history.push('..')
      }
    };

    const getDealers = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/dealers`, {
          params: {
            pageNumber: -1
          }
        });

        setDealerArr(result.data.list);
        return result.data.list;
      } catch (error) {
        if (axios.isCancel(error)) return;
        enqueueSnackbar('Unable to load dealerships', { variant: 'error' });
      }
    };

    getDealers().then(() => {
      if (!mountedRef.current) return null;
    });

    const getRoles = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Admin/Roles`);
        setRoleArr(result.data);
      } catch (error) {
        enqueueSnackbar('Unable to retrieve user roles', { variant: 'error' });
      }
    };

    getRoles().then(() => {
      if (!mountedRef.current) return null;
    });

    if (!isAddMode) {
      getUser().then(() => {
        hideLoading();
        if (!mountedRef.current) return null;
      });
    }
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editUser = async (id, fields, setSubmitting) => {
    try {
      let tmpFields = { ...fields };
      tmpFields.userId = parseInt(id, 10);
      const result = await axios.put(`${BASE_URI}/UserConfig`, tmpFields);
      enqueueSnackbar(`Successfully updated ${fields.firstName} ${fields.lastName}`, { variant: 'success' });
      return result;
    } catch (error) {
      enqueueSnackbar(`Unable to update ${fields.firstName} ${fields.lastName}`, { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const createUser = async (fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.post(` ${BASE_URI}/UserConfig`, fields, {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*'
        }
      });
      enqueueSnackbar(`Successfully added ${fields.firstName} ${fields.lastName}`, { variant: 'success' });
      return result;
    } catch (error) {
      if (error.response && error.response.data.errors) {
        let errorObj = error.response.data.errors;
        enqueueSnackbar(`${errorObj[Object.keys(errorObj)[0]]}`, { variant: 'error' });
      } else {
        enqueueSnackbar(`Unable to add ${fields.firstName} ${fields.lastName}`, { variant: 'error' });
      }
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const resetPassword = async () => {
    try {
      const result = await axios.get(`${BASE_URI}/user/ResetPassword/${formFields.email}/true`);
      enqueueSnackbar('Reset password link sent', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to reset password', { variant: 'error' });
    }
  };

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    let tmpFields = { ...fields };
    tmpFields.dealers = fields.dealers.map((dealer) => ({ id: dealer.id, name: dealer.name }));
    setStatus();
    showLoading();
    if (isAddMode) {
      createUser(tmpFields, setSubmitting, resetForm).then(() => {
        resetForm();
        history.push('.');
      });
    } else {
      editUser(id, tmpFields, setSubmitting).then(() => {
        history.push('.');
      });
    }
  }

  // Function to handle select all dealers
  const handleSelectAll = () => {
    if (!selectAllDealers) {
      setFormFields({
        ...formFields,
        dealers: [...dealerArr]
      });
    } else {
      setFormFields({
        ...formFields,
        dealers: []
      });
    }
    setSelectAllDealers(!selectAllDealers);
  };

  const handleRoleChange = (event, setFieldValue) => {
    setFieldValue('role', event.target.value);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('First Name is required'),
              lastName: Yup.string().required('Last Name is required'),
              email: Yup.string().email('Email is invalid').required('Email is required'),
              password: Yup.string().when('isAddMode', {
                is: true,
                then: Yup.string()
                  .required('Password is required')
                  .min(8, 'Password must be at least 8 characters')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    'Password requires at least one lowercase, one uppercase letter and one non-alphanumeric letter'
                  )
              }),
              confirmPassword: Yup.string()
                .when('password', (password, schema) => {
                  if (password) return schema.required('Confirm Password is required');
                })
                .oneOf([Yup.ref('password')], 'Passwords must match'),
              dealers: Yup.array().min(1, 'User must belong to at least one dealership'),
              roles: Yup.array().min(1, 'At least one role is required'),
              fiNumber: Yup.string()
                .notRequired()
                .test('oneOfRequired', 'A user with the role Finance MUST have an F&I Number', function (value) {
                  return (
                    (this.parent.fiNumber === undefined && !this.parent.roles.includes('Finance')) || this.parent.fiNumber !== undefined
                  );
                })
            })}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
              return (
                <Form>
                  <Toolbar disableGutters>
                    <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                      {isAddMode ? 'Add' : 'Edit'} User
                    </Typography>
                    <FormControlLabel control={<Field component={Switch} color='primary' type='checkbox' name='active' />} label='Active' />
                  </Toolbar>
                  <Grid spacing={4} container direction='row'>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name='firstName'
                        id='firstName'
                        required
                        label='First Name'
                        value={values.firstName}
                        type='text'
                        helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                        error={errors.firstName && touched.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name='lastName'
                        id='lastName'
                        required
                        label='Last Name'
                        value={values.lastName}
                        type='text'
                        helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                        error={errors.lastName && touched.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name='email'
                        id='email'
                        required
                        label='Email'
                        value={values.email}
                        type='email'
                        helperText={errors.email && touched.email ? errors.email : ''}
                        error={errors.email && touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Hidden xsDown>
                      <Grid item md={6}></Grid>
                    </Hidden>

                    {isAddMode && (
                      <React.Fragment>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            name='password'
                            id='password'
                            required
                            label='Password'
                            value={values.password}
                            type='password'
                            helperText={errors.password && touched.password ? errors.password : ''}
                            error={errors.password && touched.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            name='confirmPassword'
                            id='confirmPassword'
                            required
                            label='Confirm Password'
                            value={values.confirmPassword}
                            type='password'
                            helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ''}
                            error={errors.confirmPassword && touched.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    <Grid item xs={12} md={6}>
                      <InputLabel id='dealers-label'>Dealers</InputLabel>
                      <Select
                        labelId='dealers-label'
                        id='dealers'
                        name='dealers'
                        multiple
                        required
                        fullWidth
                        value={values.dealers.map((dealer) => dealer.id)}
                        onChange={(event) => {
                          const selectedIds = event.target.value;
                          const selectedDealers = dealerArr.filter((dealer) => selectedIds.includes(dealer.id));
                          setFieldValue('dealers', selectedDealers);
                        }}
                        onBlur={handleBlur}
                        input={<OutlinedInput label='Dealers' />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return 'Select Dealers';
                          }
                          if (selected.length === 1) {
                            return dealerArr.find((dealer) => dealer.id === selected[0])?.name;
                          }
                          return `${selected.length} dealers selected`;
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key='all-dealers' value='all-dealers' onClick={handleSelectAll}>
                          <Checkbox checked={selectAllDealers} />
                          <ListItemText primary='All Dealers' />
                        </MenuItem>
                        {dealerArr.map((dealer) => (
                          <MenuItem key={dealer.id} value={dealer.id}>
                            <Checkbox checked={values.dealers.map((dealer) => dealer.id).includes(dealer.id)} />
                            <ListItemText primary={dealer?.name} />
                          </MenuItem>
                        ))}
                      </Select>

                      {values.roles.includes('Finance') && (
                        <TextField
                          fullWidth
                          name='fiNumber'
                          id='fiNumber'
                          label='F&I Number'
                          value={values.fiNumber}
                          type='text'
                          helperText={errors.fiNumber && touched.fiNumber ? errors.fiNumber : ''}
                          error={errors.fiNumber && touched.fiNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel id='roles-label'>Roles</InputLabel>
                      <Select
                        labelId='roles-label'
                        id='roles'
                        name='roles'
                        fullWidth
                        value={values.roles.length > 0 ? values.roles[0].id : ''}
                        onChange={(event) => {
                          const selectedId = event.target.value;
                          const selectedRole = roleArr.find((role) => role.id === selectedId);
                          setFieldValue('roles', selectedRole ? [selectedRole] : []);
                        }}
                        input={<OutlinedInput id='roles' label='Role' />}
                        MenuProps={MenuProps}
                      >
                        {roleArr.map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>


                    <Grid item container justify='flex-end' xs={12}>
                      <Box mr={3}>
                        <Button
                          onClick={() => {
                            handleReset();
                            history.push('/admin/users');
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                        Submit
                      </Button>
                      {!isAddMode && (
                        <Button
                          onClick={() => {
                            resetPassword();
                          }}
                        >
                          Reset Password
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
