import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import NotesController from "./NotesController";
import { useContext, useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import { useParams } from "react-router-dom";
import {DEFAULT_PAGE_SIZE} from "../../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  stepPaddingButton: {
    marginLeft: "2rem",
  },
  card: {
    background: "lightblue",
    marginTop: "1rem",
  },
}));

export default function NotesTabContainer() {
    const classes = useStyles();
    let { lead } = useContext(LeadMainContext);
    const { save } = NotesController();
    const [values, setValues] = useState({
        name: "",
        emailAddress: "",
        phone: "",
        tradeIn: false,
        creditScore: "",
        dealnetReference: "",
        notes: "",
    });
    const [page, setPage] = useState(0);
    const { state, tab, id } = useParams();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("title");
    const [elementRef, setElementRef] = useState("");
    const { loading, error, data, total } = NotesController(id);

    useEffect(() => {

        setValues({ ...values, ...lead })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lead]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headCells = [
        {id: "event", numeric: false, label: "Event", sort: true},
        {id: "actioned_by", numeric: false, label: "Actioned By", sort: true},
        {id: "actioned_on", numeric: false, label: "Actioned On", sort: true},
        {id: "section", numeric: false, label: "Section", sort: true},
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={values}
        enableReinitialize={true}
        isInitialValid={false}
        onSubmit={(values, actions) => {

          save(values);

          setTimeout(() => {
            console.log(values);
            actions.setSubmitting(true);
          }, 100);
        }}
      >
        {(props) => {
          return (
            <Form>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Card className={classes.stepPadding}>
                    <CardContent>
                      <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                        Details
                      </Typography>
                      <Grid container direction="row">
                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={3}
                        >
                          <TextField
                            id="name"
                            name="name"
                            label="Name"
                            fullWidth
                            value={props.values.name}
                            helperText={
                                props.touched.name
                                    ? props.errors.name
                                : ""
                            }
                            error={
                                props.touched.name &&
                                Boolean(props.errors.name)
                            }
                            onChange={(event) => {
                              props.handleChange(event);
                                props.setFieldTouched("name", true, false);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={3}
                        >
                          <TextField
                            id="emailAddress"
                            name="emailAddress"
                            type="email"
                            label="Email"
                            fullWidth
                            value={props.values.emailAddress}
                            helperText={
                            props.touched.emailAddress ? props.errors.emailAddress : ""
                            }
                            error={
                              props.touched.emailAddress && Boolean(props.errors.emailAddress)
                            }
                            onChange={(event) => {
                              props.handleChange(event);
                              props.setFieldTouched("emailAddress", true, false);
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={3}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<MoneyOffIcon />}
                                checkedIcon={<AttachMoneyIcon />}
                                checked={props.values.tradeIn}
                                onChange={(event) => {
                                  props.handleChange(event);
                                  props.setFieldTouched("tradeIn", true, false);
                                }}
                              />
                            }
                            id="tradeIn"
                            label="Tradein / Cash Deposit?"
                            name="tradeIn"
                          />
                        </Grid>

                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={4}
                          lg={4}
                        >
                          <TextField
                            id="notes"
                            label="Private Notes"
                            name="notes"
                            multiline
                            fullWidth
                            minRows={3}
                            rowsMax={5}
                            variant="outlined"
                            value={props.values.notes}
                            helperText={
                                props.touched.notes ? props.errors.notes: ""
                            }
                            error={
                                props.touched.notes &&
                                Boolean(props.errors.notes)
                            }
                            onChange={(event) => {
                              props.handleChange(event);
                              props.setFieldTouched(
                                "notes",
                                true,
                                false
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={5} lg={5}>
                          <Card className={classes.card}>
                            <CardContent>
                              <Typography variant="body1" gutterBottom>
                                Pre-Qualification Score:{" "}
                                <b>{props.values.creditScore}</b>
                              </Typography>
                              <Typography variant="body1">
                                Dealnet Reference:{" "}
                                <b>{props.values.dealnetReference}</b>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Grid item justify="flex-end" className={classes.stepPaddingButton}>
                      <Button
                          variant="contained"
                          style={{ margin: "1rem" }}
                          color="primary"
                          type="submit"
                          disabled={!props.isValid}
                      >
                        Save
                      </Button>
                      <Button
                          variant="contained"
                          style={{ margin: "1rem" }}
                          color="primary"
                          onClick={props.resetForm}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
