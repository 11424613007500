import React from 'react';
import { Input } from '@material-ui/core';

const MultipleFontUploader = ({ onChange }) => {
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    onChange(files);
  };

  return (
    <Input
      type="file"
      inputProps={{
        accept: '.ttf,.woff,.woff2,.otf',
        multiple: true, // Allows multiple file selection
      }}
      onChange={handleFileChange}
      fullWidth
    />
  );
};

export default MultipleFontUploader;
