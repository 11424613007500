import React, { useEffect, useState, useRef } from 'react';
import { Grid, TextField, Button, Box, Divider, Paper, FormControlLabel, Checkbox, InputLabel } from '@material-ui/core';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    background: '#bdbfc1',
    color: 'white',
    padding: '5px 30px',

    width: 'fit-content',
    borderBottom: '2px red solid'
  }
}));

export default function DealerSettingForm({ history }) {
  const initialValues = {
    defaultDealerEmails: '',
    selectedLeadSource: 0,
    dealerOverrideEmails: [],
    cmsSettings: [],
    ixSettings: [],
    carShopHubSettings: [],
    autoHubSettings: [],
    autoSlmSettings: [],
    dealersOnlineSettings: [],
    vmgSettings: [],
    overrideEmails: '',
    failedEmails: '',
    floor: '',
    source: '',
    autohubSource: '',
    enquiryType: '',
    dealershipID: '',
    ixDealerId: 0,
    ref: '',
    values: ''
  };

  const initialValueLeadFailed = {
    email: ''
  };

  const [formFields, setFormFields] = useState(initialValueLeadFailed);

  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(true);
  const [crmSettings, setCrmSettings] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const loadCrmSettings = async () => {
    axios
      .get(`${BASE_URI}/Dealers/settings/${id}`)
      .then((result) => {
        setCrmSettings(result.data);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar('Failed to load the dealer settings. ' + error, { variant: 'error' });
      });
  };

  const loadNotificationEmail = async () => {
    axios
      .get(`${BASE_URI}/NotificationRecipient/${id}`)
      .then((result) => {
        let formData = result.data;
        setFormFields({
          ...formData
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message)
      });
  };

  const loadSources = async () => {
    axios
      .get(`${BASE_URI}/LeadSources`)
      .then((result) => {
        setLeadSources([
          {
            id: -1,
            name: 'Please select a lead source'
          },
          {
            id: 0,
            name: 'Default'
          },
          ...result.data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        ]);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar('Failed to load the lead sources. ' + error, { variant: 'error' });
      });
  };

  useEffect(() => {
    setLoading(true);
    loadNotificationEmail();
    loadCrmSettings();
    loadSources();
  }, []);

  useEffect(() => {
    if (leadSources && leadSources.length > 0) {
      setLoading(false);
    }
  }, [crmSettings]);

  useEffect(() => {
    if (crmSettings && crmSettings.length > 0) {
      setLoading(false);
    }
  }, [leadSources]);

  const findByLeadType = (leadTypeId) => {
    if (!crmSettings) {
      return {
        ...initialValues,
        leadTypeId: leadTypeId
      };
    }

    var result = crmSettings.find((itm) => itm.leadTypeId == leadTypeId);

    if (!result) {
      return {
        ...initialValues,
        leadTypeId: leadTypeId
      };
    } else {
      return result;
    }
  };

  function onSubmitNotificationRecipient(fields, { setStatus, setSubmitting}) {
    updateNotificationRecipient(fields);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  }

  function onSubmit(values) {
    const getItemForLeadSource = (items) => {
      var selectedLeadSourceId = values.selectedLeadSource?.id ?? -1;
      return items.find((item) => item.leadSourceId == selectedLeadSourceId);
    };

    // Reconstruct the CRM settings from the form values
    let changedCrmSettings = {
      dealerId: parseInt(id),
      leadTypeId: values.leadTypeId,
      defaultDealerEmails: values.defaultDealerEmails,
      dealerOverrideEmails: [getItemForLeadSource(values.dealerOverrideEmails)],
      cmsSettings: [getItemForLeadSource(values.cmsSettings)],
      ixSettings: [getItemForLeadSource(values.ixSettings)],
      carShopHubSettings: [getItemForLeadSource(values.carShopHubSettings)],
      autoHubSettings: [getItemForLeadSource(values.autoHubSettings)],
      autoSlmSettings: [getItemForLeadSource(values.autoSlmSettings)],
      dealersOnlineSettings: [getItemForLeadSource(values.dealersOnlineSettings)],
      vmgSettings: [getItemForLeadSource(values.vmgSettings)]
    };

    if ((values.selectedLeadSource?.id >= 0 ? values.selectedLeadSource?.id : -1) >= 0) {
      changedCrmSettings.dealerOverrideEmails[0].emails = values.overrideEmails;
      changedCrmSettings.cmsSettings[0].floor = values.floor;
      changedCrmSettings.cmsSettings[0].source = values.source;
      changedCrmSettings.cmsSettings[0].dealershipID = values.dealershipID;
      changedCrmSettings.cmsSettings[0].isMotusSSP = values.isMotusSSP;
      changedCrmSettings.carShopHubSettings[0].carShopHubDealerId = values.carShopHubDealerId;
      changedCrmSettings.autoHubSettings[0].autoHubDealerId = values.autoHubDealerId;
      changedCrmSettings.autoHubSettings[0].source = values.autohubSource;
      changedCrmSettings.autoHubSettings[0].enquiryType = values.enquiryType;
      changedCrmSettings.autoSlmSettings[0].dealerCode = values.dealerCode;
      changedCrmSettings.dealersOnlineSettings[0].dealership_id = values.dealership_id;
      changedCrmSettings.vmgSettings[0].vmgDealerId = values.vmgDealerId;
      changedCrmSettings.ixSettings[0].ixDealerId = values.ixDealerId;
      changedCrmSettings.ixSettings[0].ref = values.ref;
      changedCrmSettings.ixSettings[0].value = values.value;
    }

    updateDealerSetting(changedCrmSettings);
  }

  const updateDealerSetting = async (fields) => {
    axios
      .put(`${BASE_URI}/dealers/settings`, fields)
      .then((response) => {
        loadCrmSettings();
        enqueueSnackbar('Successfully updated dealer settings', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Failed to save the dealer settings. ' + error, { variant: 'error' });
      });
    try {
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' });
    } finally {
      //setSubmitting(false);
    }
  };

  const updateNotificationRecipient = async (fields) => {
    axios
      .put(`${BASE_URI}/NotificationRecipient/${fields.id}`, fields)
      .then((response) => {
        if(response){
          enqueueSnackbar(response.data, { variant: 'success' });
        }
      })
      .catch((error) => {
        enqueueSnackbar('Failed to save the Notification Recipient Emails. ' + error, { variant: 'error' });
      });
    try {
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' });
    } finally {
      //setSubmitting(false);
    }
  };

  const onLeadSourceChanged = (values, e, c) => {
    // When the lead source changed the CRM settings for that lead source need to be assigned to the form's "values" object
    let sourceId = c.props.id;
    if (sourceId == null || sourceId == undefined || sourceId < 0) return;
    values.overrideEmails = values.dealerOverrideEmails.find((itm) => itm.leadSourceId == sourceId).emails;
    values.floor = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).floor;
    values.source = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).source;
    values.isMotusSSP = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).isMotusSSP;
    values.dealershipID = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).dealershipID;
    values.vmgDealerId = values.vmgSettings.find((itm) => itm.leadSourceId == sourceId).vmgDealerId;
    values.carShopHubDealerId = values.carShopHubSettings.find((itm) => itm.leadSourceId == sourceId).carShopHubDealerId;
    values.autoHubDealerId = values.autoHubSettings.find((itm) => itm.leadSourceId == sourceId).autoHubDealerId;
    values.enquiryType = values.autoHubSettings.find((itm) => itm.leadSourceId == sourceId).enquiryType;
    values.autohubSource = values.autoHubSettings.find((itm) => itm.leadSourceId == sourceId).source;
    values.dealerCode = values.autoSlmSettings.find((itm) => itm.leadSourceId == sourceId).dealerCode;
    values.dealership_id = values.dealersOnlineSettings.find((itm) => itm.leadSourceId == sourceId).dealership_id;
    values.ixDealerId = values.ixSettings.find((itm) => itm.leadSourceId == sourceId).ixDealerId;
    values.ref = values.ixSettings.find((itm) => itm.leadSourceId == sourceId).ref;
    values.value = values.ixSettings.find((itm) => itm.leadSourceId == sourceId).value;
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            enableReinitialize={true}
            onSubmit={onSubmitNotificationRecipient}
            validationSchema={Yup.object().shape({})}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
              return (
                <Form>
                  <Grid spacing={4} container direction='row'>
                    <Grid item xs={12} md={6}>
                      <Grid item xs={12}>
                        <br />
                        <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                          Failed Notification Emails
                        </Typography>
                        <br />
                      </Grid>
                      <TextField
                        fullWidth
                        name='email'
                        //required
                        id='email'
                        label='Emails'
                        helperText={errors.email && touched.email ? errors.email : 'Failed notification comma-separated emails'}
                        value={values.email}
                        type='text'
                        error={errors.email && touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item container justify='flex-end' xs={12}>
                      <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                        Save
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        {!loading &&
          Object.entries(LeadTypeEnum).map(([key, value], index) => (
            <Grid key={index} item xs={12}>
              <Formik
                initialValues={findByLeadType(value)}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  onSubmit(values);
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 500);
                }}
                validationSchema={Yup.object().shape({
                  // make: Yup.string().required('Select a Make for the vehicle'),
                  // model: Yup.string().required('Select a Model for the vehicle'),
                  // title: Yup.string().required('Enter a title for the Range option'),
                  // image: Yup.string().required('Vehicle image is required')
                })}
              >
                {(props) => {
                  const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

                  return (
                    <Form>
                      <Grid spacing={4} container direction='row'>
                        <Grid item xs={12} md={6}>
                          <Typography variant='h6' gutterBottom>
                            {key}
                          </Typography>

                          <TextField
                            fullWidth
                            name='defaultDealerEmails'
                            //required
                            id='defaultDealerEmails'
                            label='Emails '
                            value={values.defaultDealerEmails}
                            type='text'
                            helperText={errors.defaultDealerEmails && touched.defaultDealerEmails ? errors.defaultDealerEmails : ''}
                            error={errors.defaultDealerEmails && touched.defaultDealerEmails}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <TextField
                            select
                            fullWidth
                            name='selectedLeadSource'
                            //required
                            id='selectedLeadSource'
                            label='Source'
                            value={values.selectedLeadSource}
                            type='text'
                            helperText={errors.selectedLeadSource && touched.selectedLeadSource ? errors.selectedLeadSource : ''}
                            error={errors.selectedLeadSource && touched.selectedLeadSource}
                            onChange={(event, child) => {
                              onLeadSourceChanged(values, event, child);
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                          >
                            {leadSources.map((option) => (
                              <MenuItem key={option.id} id={option.id} value={option}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          {values.selectedLeadSource && values.selectedLeadSource.id >= 0 && (
                            <>
                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  OVERRIDE EMAILS
                                </Typography>
                                <br />
                              </Grid>

                              <TextField
                                fullWidth
                                name='overrideEmails'
                                //required
                                id='overrideEmails'
                                label='Emails '
                                value={values.overrideEmails}
                                type='text'
                                helperText={errors.overrideEmails && touched.overrideEmails ? errors.overrideEmails : ''}
                                error={errors.overrideEmails && touched.overrideEmails}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  CMS
                                </Typography>
                                <br />
                              </Grid>

                              <TextField
                                fullWidth
                                name='floor'
                                //required
                                id='floor'
                                label='Floor'
                                value={values.floor}
                                type='text'
                                helperText={errors.floor && touched.floor ? errors.floor : ''}
                                error={errors.floor && touched.floor}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <TextField
                                fullWidth
                                name='source'
                                //required
                                id='source'
                                label='Source'
                                value={values.source}
                                type='text'
                                helperText={errors.source && touched.source ? errors.source : ''}
                                error={errors.source && touched.source}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <TextField
                                fullWidth
                                name='dealershipID'
                                //required
                                id='dealershipID'
                                label='Dealership Id'
                                value={values.dealershipID}
                                type='text'
                                helperText={errors.dealershipID && touched.dealershipID ? errors.dealershipID : ''}
                                error={errors.dealershipID && touched.dealershipID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <FormControlLabel
                                id='isMotusSSP'
                                name='isMotusSSP'
                                checked={values.isMotusSSP}
                                control={<Checkbox name={values.isMotusSSP} color='primary' onChange={handleChange('isMotusSSP')} />}
                                label={'Motus SSP ?'}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  IX
                                </Typography>
                                <br />
                              </Grid>

                              <TextField
                                fullWidth
                                name='ixDealerId'
                                //required
                                id='ixDealerId'
                                label='Dealer Id'
                                value={values.ixDealerId}
                                type='number'
                                helperText={errors.ixDealerId && touched.ixDealerId ? errors.ixDealerId : ''}
                                error={errors.ixDealerId && touched.ixDealerId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <TextField
                                fullWidth
                                name='ref'
                                //required
                                id='ref'
                                label='Ref'
                                value={values.ref}
                                type='text'
                                helperText={errors.ref && touched.ref ? errors.ref : ''}
                                error={errors.ref && touched.ref}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <TextField
                                fullWidth
                                name='value'
                                //required
                                id='value'
                                label='Value'
                                value={values.value}
                                type='text'
                                helperText={errors.value && touched.value ? errors.value : ''}
                                error={errors.value && touched.value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  Car Shop Hub
                                </Typography>
                                <br />
                              </Grid>

                              <TextField
                                fullWidth
                                name='carShopHubDealerId'
                                //required
                                id='carShopHubDealerId'
                                label='Dealer Id'
                                value={values.carShopHubDealerId}
                                type='number'
                                helperText={errors.carShopHubDealerId && touched.carShopHubDealerId ? errors.carShopHubDealerId : ''}
                                error={errors.carShopHubDealerId && touched.carShopHubDealerId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  Auto Hub
                                </Typography>
                                <br />
                              </Grid>
                              <TextField
                                fullWidth
                                name='autoHubDealerId'
                                //required
                                id='autoHubDealerId'
                                label='Auto hub dealer Id'
                                value={values.autoHubDealerId}
                                type='number'
                                helperText={errors.autoHubDealerId && touched.autoHubDealerId ? errors.autoHubDealerId : ''}
                                error={errors.autoHubDealerId && touched.autoHubDealerId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <TextField
                                fullWidth
                                name='autohubSource'
                                id='autohubSource'
                                label='Source'
                                value={values.autohubSource}
                                type='text'
                                helperText={errors.autohubSource && touched.autohubSource ? errors.autohubSource : ''}
                                error={errors.autohubSource && touched.autohubSource}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <TextField
                                fullWidth
                                name='enquiryType'
                                id='enquiryType'
                                label='Enquiry Type'
                                value={values.enquiryType}
                                type='text'
                                helperText={errors.enquiryType && touched.enquiryType ? errors.enquiryType : ''}
                                error={errors.enquiryType && touched.enquiryType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  Auto SLM
                                </Typography>
                                <br />
                              </Grid>
                              <TextField
                                fullWidth
                                name='dealerCode'
                                //required
                                id='dealerCode'
                                label='Auto SLM dealer code'
                                value={values.dealerCode}
                                type='number'
                                helperText={errors.dealerCode && touched.dealerCode ? errors.dealerCode : ''}
                                error={errors.dealerCode && touched.dealerCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  Dealers Online
                                </Typography>
                                <br />
                              </Grid>
                              <TextField
                                fullWidth
                                name='dealership_id'
                                //required
                                id='dealership_id'
                                label='Dealership id'
                                value={values.dealership_id}
                                type='number'
                                helperText={errors.dealership_id && touched.dealership_id ? errors.dealership_id : ''}
                                error={errors.dealership_id && touched.dealership_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Grid item xs={12}>
                                <br />
                                <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div' className={classes.title}>
                                  VMG
                                </Typography>
                                <br />
                              </Grid>
                              <TextField
                                fullWidth
                                name='vmgDealerId'
                                //required
                                id='vmgDealerId'
                                label='VMG Dealer id'
                                value={values.vmgDealerId}
                                type='number'
                                helperText={errors.vmgDealerId && touched.vmgDealerId ? errors.vmgDealerId : ''}
                                error={errors.vmgDealerId && touched.vmgDealerId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </>
                          )}
                        </Grid>

                        <Grid item container justify='flex-end' xs={12}>
                          <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                            Save
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
}
