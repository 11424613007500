import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import jwt from 'jwt-decode'
import jwtDecode from 'jwt-decode'
import { AUTH_USER, BASE_URI, AUTH_PERMISSION } from '../Constants'

export const AuthContext = createContext({ role: [], permissions: [] });
export const AuthContextProvider = (props) => {
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [role, setRole] = useState([])
    const [permissions, setPermissions] = useState([])
    const [userDealerIDs, setUserDealerIDs] = useState([])
    const [email, setEmail] = useState('')
    const [hasAdminRights,setHasAdminRights] = useState(false);
    const permission = JSON.parse(localStorage.getItem(AUTH_PERMISSION));
    const [userSession, setUserSession] = useState({})

    useEffect(()=>{
        if(role?.includes('Admin')){
            setHasAdminRights(true);
        }
    },[role])

    useEffect(() => {
        handleAuthentication();
        handlePermissions();
    }, [])

    const handleAuthentication = () => {
        const session = JSON.parse(localStorage.getItem(AUTH_USER));
        if (session && !isAuthenticated) {
            setUserDealerIDs(session.userDealerIDs);
            const roles = Array.isArray(session.role) ? [...session.role] : [session.role];
            setRole(roles);
            setEmail(session.email);
            checkTokenExpiration();
        } else if (!isAuthenticated) {
            logout();
        }
    };

    const handlePermissions = () => {
        if (permission) {
            setPermissions([...permission]);
        }
    };

    const checkTokenExpiration = () => {
        const session = JSON.parse(localStorage.getItem(AUTH_USER));

        if (session || userSession) {
            const { token } = session ?? userSession.token;
            const decodedToken = jwtDecode(token);
            const expirationTime = decodedToken.exp * 1000;
            Date.now() >= expirationTime ? logout() : setAuthenticated(true);
          } else {
            // session has no value
            console.error('Session does not exist');
          }
    }

    //interceptors to add request headers for api requests authorization
    axios.interceptors.request.use(
        async config => {
            const session = JSON.parse(localStorage.getItem(AUTH_USER));
            if (session && session.token) {
                let token = createJWTToken(session.token)
                config.headers.authorization = token
                config.maxContentLength = 100000000
                config.maxBodyLength = 100000000
            }
            return config
        },
        error => {
            Promise.reject(error)
        });

    //interceptor that get response
    axios.interceptors.response.use(
        async (response) => response,
        async (error) => {
            if (axios.isAxiosError(error)) {
            checkTokenExpiration();
            }
            throw error;
    });

    const setSession = (username, token, refreshToken, dealerIDs, permissions, name) => {
        const jwtHeader = jwt(token);
        const roles = jwtHeader['roles']
        const user = {
            email: username ? username : '',
            token: token,
            refreshToken: refreshToken,
            role: roles,
            userDealerIDs: dealerIDs,
            name : name
        }

        setUserDealerIDs(user.userDealerIDs)

        if (Array.isArray(roles)) {
            setRole([...roles]);
        }
        else {
            setRole([roles]);
        }

        setEmail(user.email);
        setPermissions([...permissions]);
        setAuthenticated(true);
        localStorage.setItem(AUTH_USER, JSON.stringify(user))
        localStorage.setItem(AUTH_PERMISSION, JSON.stringify(permissions))

        setUserSession(user);
    }

    const createJWTToken = (token) => {
        return 'Bearer ' + token;
    }

    const logout = () => {
        localStorage.removeItem(AUTH_USER);
        localStorage.removeItem(AUTH_PERMISSION);
        setRole([]);
        setPermissions([]);
        setAuthenticated(false);
    }

    const login = (username, password) => {
        return axios.post(`${BASE_URI}/User/Login`, {
            email: username, password
        })
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, setSession, role, userDealerIDs, permissions, email, hasAdminRights }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
