import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
import moment from 'moment'
import { DealerContext } from '../../shared/DealerContext'

export default function OfferFilter(searchString, offerStatus, pageNumber, pageSize, order, orderBy) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [offers, setOffers] = useState([])
    const [total, setTotal] = useState(0)
    const { dealerId } = useContext(DealerContext);

    useEffect(() => {
        setOffers([])
    }, [searchString, offerStatus, pageNumber, pageSize, order, orderBy, dealerId])

    useEffect(() => {
        let cancel
        setError(false)
        const params = {
            pageNumber: pageNumber + 1,
            pageSize,
            dealerId: dealerId
        }
        if (searchString) { params.searchHeadline = searchString }
        if (offerStatus) { params.status = offerStatus }
        // if (order) {params.order = order.toUpperCase()}
        // if (orderBy) {params.orderBy = orderBy}
        if(dealerId!==""){
            setLoading(true)
            axios({
                method: 'GET',
                url: `${BASE_URI}/Offers`,
                params,
                cancelToken: new axios.CancelToken(c => cancel = c)
            }).then((response) => {
                if (response.data?.list) {
                    setOffers(response.data?.list)
                    setTotal(response.data?.total)
                }
            }).catch(error => {
                if (axios.isCancel(error)) return
                if (error.response) {
                    setError(true)
                }

            }).then(()=>{
                setLoading(false)
            })
            return () => cancel()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, offerStatus, pageNumber, pageSize, order, orderBy, dealerId])

    return { loading, error, offers, offerStatus, total }
}