import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import { DealerContext } from '../../shared/DealerContext';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import TemplateFilter from './TemplateFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  green: {
    color: '#8bc34a'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function TemplateTable() {
  const classes = useStyles();
  let history = useHistory();
  const { selectedMotorGroup, updateSelectedMotorGroup, dealerId } = useContext(DealerContext);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [orderBy, setOrderBy] = useState('templatename');
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const { loading, templates, total } = TemplateFilter(searchString, page, pageSize, order, orderBy);

  const [headCells, setHeadCells] = useState([{ id: 'name', label: 'Name', sort: true }]);

  useEffect(() => {
    if (!selectedMotorGroup || loading) {
      return;
    }
    setFilteredTemplates(templates || []);
    // Set isLoading to false after processing.
  }, [templates, selectedMotorGroup, loading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    setPage(0); // Set the page to 0 when Enter is pressed
    setSearchString(searchInput);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleDealerSelection(dealer, motorgroupId) {
    if (!motorgroupId) return;
    updateSelectedMotorGroup(dealer, motorgroupId);
  }

  let filterSearchTemplate = filteredTemplates.filter(
    (a) => searchString === '' || a.name?.toLowerCase().includes(searchString.toLowerCase())
  );

  const handleButtonClick = (row) => {
    history.push(`/template/edit/${row?.id}`);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
              <Typography style={{ flex: '1 1' }} variant='h4' id='tableTitle' component='div'>
                Templates
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  name='templateFilter'
                  id='templateFilter'
                  label='Search Templates'
                  value={searchInput}
                  type='text'
                  InputProps={{
                    endAdornment: searchInput ? (
                      <InputAdornment position='end'>
                        <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    )
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </form>
              <Button
                onClick={() => history.push('/template/add')}
                variant='contained'
                style={{ marginLeft: '2rem' }}
                startIcon={<AddIcon />}
                color='primary'
              >
                Template
              </Button>
            </Toolbar>

            <TableContainer>
              <Table aria-label='users table' size='small'>
                <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                <TableBody>
                  {filterSearchTemplate.map((row) => (
                    <Tooltip title={`Edit ${row.id}`} key={row.id} placement='left'>
                      <TableRow hover className={classes.hoverTableCell}>
                        <TableCell onClick={() => handleButtonClick(row)}>
                          <Typography style={{ color: row.status === 'inActive' ? '#ff5722' : '' }} variant='subtitle2'>
                            {row.name}
                          </Typography>
                        </TableCell>

                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>

                        <TableCell>
                          <Button
                            className={classes.removedealerbtn}
                            variant='contained'
                            color='primary'
                            onClick={() => handleButtonClick(row)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
                  {(!templates.length || templates.length === 0) && !loading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                        <Typography variant='h6' color='textSecondary'>
                          {dealerId !== '' ? 'No Templates Added' : 'Select a dealer!'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {loading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                count={total}
                page={page}
                rowsPerPage={pageSize}
                component='div'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
