import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Divider,
    Paper,
    Fab,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants'
import { useSnackbar } from "notistack";
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        background: '#bdbfc1',
        color: 'white',
        padding: "5px 30px",
        width: "fit-content",
        borderBottom: '2px red solid',
    },
    tr: {
        padding: "5px 30px",
    },
    th: {
        padding: "5px 10px",
        textAlign: "center"
    },
    td: {
        padding: "2px 10px",
    },
    btn: {
        color: "#fff",
        background: '#3f51b5',
        padding: "8px 20px",
        border: "none",
        margin: '5px',
        borderRadius: '3px'
    }
}));

export default function DealerActivatedServiceForm({ history, props }) {

    const initialValues = {
        id: '',
        service: '',
        status: '',
        triggeredByAgent: false,
        webhookUrl: ''
    }

    const { id } = useParams();
    const [formFields, setFormFields] = useState(initialValues);
    const [isWebhookServiceSelected, setIsWebhookServiceSelected] = useState(false);
    const [services, setServices] = useState([])
    const [activeServices, setActiveServices] = useState([])
    const [states, setStates] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const deleteService = (item) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            axios.delete(
                `${BASE_URI}/LeadServices/${item.id}`,
                {
                    cancelToken: source.token,
                }
            ).then(result => {
                enqueueSnackbar("Deleted item", { variant: 'success' });
                getActivatedServices();
            });

        } catch (error) {
            enqueueSnackbar("Delete failed", { variant: 'error' });
        }
    }

    function onSubmit(fields) {

        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            axios.post(
                `${BASE_URI}/LeadServices`,
                {
                    dealerId: parseInt(id),
                    leadServiceId: fields.service,
                    leadStatusId: fields.state,
                    triggeredByAgent: fields.triggeredByAgent
                },
                {
                    cancelToken: source.token,
                }
            ).then(result => {
                enqueueSnackbar("Added Lead Service", { variant: 'success' });
                getActivatedServices();

                if (isWebhookServiceSelected === true) {
                    saveWebhookSetting(fields.webhookUrl);
                }
            });

        } catch (error) {
            enqueueSnackbar("Updated failed", { variant: 'error' });
        }
    }

    const saveWebhookSetting = (url) => {

        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            axios.post(
                `${BASE_URI}/CustomWebhookSetting`,
                {
                    dealerId: parseInt(id),
                    webhookUrl: url
                },
                {
                    cancelToken: source.token,
                }
            ).then(result => {
                console.log("result = ", result);
                console.dir(result);
                enqueueSnackbar("Successfully saved webhook URL", { variant: 'success' });

            }).catch(error => {

                console.log("error = ", error);
                console.dir(error);

                enqueueSnackbar(`Failed to save webhook URL: ${error}`, { variant: 'error' });

            });

        } catch (error) {

            enqueueSnackbar(`Failed to save webhook URL: ${error}`, { variant: 'error' });

        }

    };

    const getServices = async () => {
        try {
            const result = await axios.get(`${BASE_URI}/LeadServices`, {
                cancelToken: source.token,
            });

            setServices(result.data);
        } catch (error) {
            // addAlert('Unables to load vehicle categories')
            //history.push('..')
        }
    };

    const getActivatedServices = async () => {
        try {
            const result = await axios.get(`${BASE_URI}/LeadServices/${id}`, {
                cancelToken: source.token,
            });

            setActiveServices(result.data);
        } catch (error) {
            // addAlert('Unables to load vehicle categories')
            //history.push('..')
        }
    };

    const getStatuses = async () => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const params = {
            pageNumber: 1,
            pageSize: 100
        }

        axios({
            method: 'GET',
            url: `${BASE_URI}/LeadStatus`,
            params,
            cancelToken: source.token
        }).then((response) => {

            if (response.data) {
                setStates(response.data)
            }

        }).catch(error => {
            if (axios.isCancel(error)) return

        })
    }

    useEffect(() => {

        getStatuses();
        getServices();
        getActivatedServices();

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Paper className={classes.paper}>
            <Formik
                initialValues={formFields}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    service: Yup.number().required("Service is required"),
                    state: Yup.number().required("State is required")
                })}>
                {(props) => {
                    const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;

                    //showErrors(touched)

                    return (
                        <Form>
                            <Grid container spacing={4} className={classes.stepPadding}>

                                <Grid item xs={12}>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        ACTIVATED SERVICES
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="service"
                                        id="service"
                                        required
                                        label="Service"
                                        select
                                        value={values.service}
                                        helperText={errors.service && touched.service ? errors.service : ""}
                                        error={errors.service && touched.service}
                                        onBlur={handleBlur("service")}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setIsWebhookServiceSelected(e?.target?.value == 16);
                                        }}
                                    >
                                        {services.map((option, index) => (
                                            <MenuItem key={index} id={`${option.id}`} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="state"
                                        id="state"
                                        required
                                        label="State"
                                        select
                                        value={values.state}
                                        helperText={errors.state && touched.state ? errors.state : ""}
                                        error={errors.state && touched.state}
                                        onBlur={handleBlur("state")}
                                        onChange={handleChange("state")}
                                    >
                                        {states.map((option, index) => (
                                            <MenuItem key={index} id={`${option.id}`} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {
                                    isWebhookServiceSelected === true &&
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            name="webhookUrl"
                                            id="webhookUrl"
                                            required={isWebhookServiceSelected}
                                            label="Custom Webhook URL"
                                            value={values.webhookUrl}
                                            helperText={errors.webhookUrl && touched.webhookUrl ? errors.webhookUrl : ""}
                                            error={errors.webhookUrl && touched.webhookUrl}
                                            onBlur={handleBlur("webhookUrl")}
                                            onChange={handleChange("webhookUrl")} />
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={<Checkbox name={values.triggeredByAgent} color="primary" onChange={handleChange("triggeredByAgent")} />}
                                        label={"Triggered by agent"}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} container justify='flex-end'>
                                    <Button type="submit" variant="contained" color="primary">
                                        Save
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <table width="100%" border="1">
                                        <thead>
                                            <tr className={classes.tr}>
                                                <th className={classes.th}>
                                                    Service
                                                </th>
                                                <th className={classes.th}>
                                                    Status
                                                </th>
                                                <th className={classes.th}>
                                                    Is Triggered By Agent
                                                </th>
                                                <th className={classes.th}>
                                                    Is Default
                                                </th>
                                                <th className={classes.th}>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activeServices.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className={classes.td}>
                                                            <div key={index}>
                                                                {item.name}
                                                            </div>
                                                        </td>
                                                        <td className={classes.td}>
                                                            <div key={index}>
                                                                {item.status}
                                                            </div>
                                                        </td>
                                                        <td className={classes.td}>
                                                            <div key={index}>
                                                                {item.triggeredByAgent ? "True" : "False"}
                                                            </div>
                                                        </td>
                                                        <td className={classes.td}>
                                                            <div key={index}>
                                                                {item.default ? "True" : "False"}
                                                            </div>
                                                        </td>
                                                        <td className={classes.td} style={{ textAlign: "center" }}>
                                                            {!item.default &&
                                                                <div key={index}>
                                                                    <a onClick={(e) => { deleteService(item) }}>Delete</a>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                            </Grid>
                        </Form>
                    )
                }
                }
            </Formik>
        </Paper>
    )
}